import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import axios from "axios";
import { eraseCookie, PRAVESH_API } from "../Constants/constants";

export const getUserDetails = createAsyncThunk(
  "userDetails/getUserDetails",
  async (_, { rejectWithValue }) => {
    try {
      const url = `${PRAVESH_API}company_saas/dashboard/user_details/`;
      const res = await axios.get(url, {
        withCredentials: true,
      });

      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userDetailSlice = createSlice({
  name: "userDetails",
  initialState: {
    userDetails: {},
    loading: false,
    LOGGEDIN: null,
  },
  reducers: {
    logout(state, action) {
      state.userDetails = {};
      state.loading = "false";
      state.LOGGEDIN = "false";
    },
    addUserDetails(state, action) {
      state.userDetails = action.payload.userDetailData;
      state.loading = false;
      state.LOGGEDIN = "true";
    },
  },
  extraReducers: {
    [getUserDetails.pending]: (state, action) => {
      state.loading = true;
      state.LOGGEDIN = "false";
    },
    [getUserDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.userDetails = action?.payload;
      state.LOGGEDIN = "true";
    },
    [getUserDetails.rejected]: (state, action) => {
      state.loading = false;
      state.LOGGEDIN = "false";

      if (window.location.pathname !== "/") {
        eraseCookie("user_id");
        eraseCookie("user_token_test");
        eraseCookie("user_token");
        localStorage.removeItem("loggedIn");
        //window.location.href = "/";
      }
    },
  },
});

export const { logout, addUserDetails } = userDetailSlice.actions;
export default userDetailSlice.reducer;
