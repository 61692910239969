import Close from "@mui/icons-material/Close";
import {
  Box,
  Chip,
  IconButton,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React from "react";

function NewSmallQualifications({
  qualification,

  deleteQualification,
}) {
  const theme = useTheme();
  return (
    <>
      <Chip
        variant="outlined"
        color="primary"
        size="small"
        onDelete={() => {
          deleteQualification(qualification);
        }}
        deleteIcon={<Close color="primary" sx={{ fontSize: "14px" }} />}
        sx={{
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
        }}
        label={
          <Typography sx={{ fontSize: "12px" }}>
            {`${qualification?.education_type_name}` +
              ` with ${qualification?.percentage}%`}
          </Typography>
        }
        onClick={() => {
          deleteQualification(qualification);
        }}
      />
    </>
  );
}

export default NewSmallQualifications;
