import React from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";
import Vactor from "../../../../Assets/Images/svg/Approval.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Request() {
  const theme = useTheme();
  const history = useHistory()
  const adminEmail = window.localStorage.getItem('adminEmail');  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <img
        src={Vactor}
        style={{ width: "90%", aspectRatio: "3/2", objectFit: "contain" }}
      />

      <Typography
        variant="h2"
        sx={{
          mt: 4,
          mb: 2,
          fontWeight: "600",
          textAlign: "center",
          [theme.breakpoints.only("xs")]: {
            fontSize: "22px",
          },
        }}
      >
        Joining Request
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: "#6C757D", textAlign: "center", mb: 3 }}
      >
        Your request for approval has been sent to the admin at
        <span style={{ color: "#00203f" }}>{adminEmail}</span> Request
        for approval
      </Typography>

      <Button variant="contained" color="primary" sx={{ boxShadow: "none" }}
      onClick={()=>{ history.push("/")}}
      >
        Back to Home
      </Button>
    </Box>
  );
}

export default Request;
