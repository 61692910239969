import { Autocomplete, CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import fetchApi from "../../../Apis/fetchApi";
import { NIYUKTI_API } from "../../../Constants/constants";
import {
  CustomAutoCompletePaper,
  CustomTextField,
} from "../../Common/InputText/InputField";

export default function DescriptionTemplate({
  open,
  handleClose,
  handleClickOpen,
  jobRoleList,
  jobDes,
  setJobDes,
}) {
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("xs");
  const [jobRole, setJobRole] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [desLoader, setDesLoader] = useState(false);

  useEffect(() => {
    if (jobRole?.id) {
      getJobRoleDescription();
    }
  }, [confirm]);

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const getJobRoleDescription = () => {
    const helper = (data) => {
      if (data?.data && Object.keys(data.data).length > 0) {
        var new_data =
          "<h4>" +
          String(data?.data?.job_title ? data?.data?.job_title : "") +
          "</h4>" +
          "<h4>" +
          String(data?.data?.job_role_name ? data?.data?.job_role_name : "") +
          "</h4>" +
          "<p>" +
          String(data?.data?.brief ? data?.data?.brief : "") +
          String(data?.data?.description ? data?.data?.description : "") +
          String(data?.data?.requirements) +
          "</p>";
        setJobDes(new_data);
      }
      handleClose();
    };
    fetchApi({
      url: `${NIYUKTI_API}job/search/job_description/?job_role_id=${jobRole?.id}`,
      setResponse: helper,
      setLoading: setDesLoader,
    });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "8px",
            backgroundColor: "#ffff",
            overflowY: "unset",
          },
        }}
      >
        <DialogContent>
          <Box>
            <Typography variant="h5">
              Select Job Description Template
            </Typography>

            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid xs={12} sm={12} md={12} sx={{ display: "grid" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={jobRoleList}
                  getOptionLabel={(option) => option?.job_role_name}
                  value={jobRole}
                  onChange={(e, newValue) => {
                    if (newValue) setJobRole(newValue);
                  }}
                  PaperComponent={CustomAutoCompletePaper}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      size="small"
                      label="Select Job Role"
                    />
                  )}
                />
              </Grid>
              <Grid xs={6} sm={6} md={6} sx={{ display: "grid" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    borderRadius: "8px",
                    boxShadow: "none",
                    height: "40px",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid xs={6} sm={6} md={6} sx={{ display: "grid" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setConfirm(!confirm);
                  }}
                  sx={{
                    borderRadius: "8px",
                    boxShadow: "none",
                    height: "40px",
                  }}
                >
                  {desLoader ? <CircularProgress size={18} /> : "Confirm"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
