import React from "react";
import clsx from "clsx";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function ShowMore({
  heading,
  data,
  handleChange,
  isCheckboxSelected,
  paramName,
  isSingle = false,
}) {
  const [state, setState] = React.useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [menuName, setMenuName] = React.useState(null);

  const list = (anchor, dataList) => {
    const clickListener = (text) => {
      if (!menuName) {
        return setMenuName(text);
      } else {
        return alert(`${text} clicked`);
      }
    };

    return (
      <Box
        sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 330 }}
        role="presentation"
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <>
          <AppBar
            position="fixed"
            sx={{
              backgroundColor: "#fff",
              boxShadow: "none",
              borderBottom: "1px solid #6C757D",
              color: "#000",
              width: "330px",
            }}
          >
            <Toolbar
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography variant="h6" style={{ marginLeft: "2px" }}>
                {heading}
              </Typography>
              <IconButton
                onKeyDown={toggleDrawer(anchor, false)}
                onClick={toggleDrawer(anchor, false)}
                edge="start"
                color="inherit"
                aria-label="open drawer"
              >
                <ArrowForwardIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div
            style={{
              paddingLeft: 25,
              marginTop: 80,
            }}
          >
            <FormControl component="fieldset">
              <FormGroup>
                {data.map((item) => {
                  return (
                    <FormControlLabel
                      key={item?.id}
                      control={
                        <Checkbox
                          color="primary"
                          size="small"
                          checked={isCheckboxSelected.some(
                            (obj) => obj?.id === item?.id
                          )}
                          onChange={(e) => {
                            handleChange(
                              heading,
                              paramName,
                              item,
                              isSingle ? "single" : "multiple"
                            );
                          }}
                        />
                      }
                      label={item?.name}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </div>
        </>
      </Box>
    );
  };

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            color="primary"
            variant="text"
          >
            Show All {`(${data?.length})`}
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {menuName && (
              <ListItem button onClick={() => setMenuName(null)}>
                <ListItemText primary="Back to main menu" />
                <ChevronLeftIcon />
              </ListItem>
            )}
            {list(anchor, data)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
