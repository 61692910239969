import {
  Box,
  Grid,
  Typography,
  IconButton,
  useTheme,
  Hidden,
} from "@mui/material";
import React from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";

function NewRound({ round, index, deleteRound }) {
  const theme = useTheme();
  return (
    <div>
      <Box
        sx={{
          borderColor: theme.palette.primary.main,
          border: "1px solid",
          borderRadius: 2,
          height: 50,
          display: "flex",
          alignItems: "center",
          mb: 2,
          [theme.breakpoints.only("sm")]: {
            height: 200,
            flexDirection: "column",
            alignItems: "stretch",
          },
          [theme.breakpoints.only("xs")]: {
            height: 200,
            flexDirection: "column",
            alignItems: "stretch",
          },
        }}
      >
        <Box
          sx={{
            cursor: "grab",
            backgroundColor: theme.palette.primary.main,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            height: 50,
            width: 150,
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.only("sm")]: {
              width: "auto",
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
              borderBottomLeftRadius: 0,
              justifyContent: "space-between",
            },
            [theme.breakpoints.only("xs")]: {
              width: "auto",
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
              borderBottomLeftRadius: 0,
              justifyContent: "space-between",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <DragIndicatorIcon sx={{ color: "#fff", paddingLeft: 1.5 }} />
            <Typography variant="body2" sx={{ color: "#fff", paddingLeft: 1 }}>
              Round {index + 1}
            </Typography>
          </Box>
          <Hidden only={["md", "lg", "xl"]}>
            <Box>
              <IconButton
                sx={{ color: "#fff" }}
                onClick={() => {
                  deleteRound(round);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Hidden>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ width: "100%", margin: 0, display: "contents" }}
        >
          <Grid
            xs={12}
            sm={12}
            md={5}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography
              variant="body2"
              noWrap
              sx={{
                textAlign: "left",
                pl: 1.5,
                [theme.breakpoints.only("sm")]: {
                  pl: 2,
                },
                [theme.breakpoints.only("xs")]: {
                  pl: 2,
                },
              }}
            >
              {" "}
              {`${round?.r_name}`}
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={3}
            sx={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              pl: 2,
              [theme.breakpoints.only("sm")]: {
                textAlign: "left",
                pl: 2,
              },
              [theme.breakpoints.only("xs")]: {
                textAlign: "left",
                pl: 2,
              },
            }}
          >
            <Typography variant="body2">
              {" "}
              {round?.duration ? ` ${round?.duration} minutes` : "Duration: NA"}
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={3}
            sx={{
              display: "flex",
              alignItems: "center",
              [theme.breakpoints.only("sm")]: {
                textAlign: "left",
                pl: 2,
              },
              [theme.breakpoints.only("xs")]: {
                textAlign: "left",
                pl: 2,
              },
            }}
          >
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              {" "}
              {round?.venue ? ` ${round?.venue}` : "Venue: NA"}
            </Typography>
          </Grid>
          <Grid
            md={1}
            sx={{
              pt: 0,
              pl: 0,
              [theme.breakpoints.only("sm")]: {
                display: "none",
              },
              [theme.breakpoints.only("xs")]: {
                display: "none",
              },
            }}
          >
            <IconButton
              color="primary"
              onClick={() => {
                deleteRound(round);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default NewRound;
