import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { AccessTime, Close, PersonOutlined } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import InfoIcon from "@mui/icons-material/Info";

import {
  alpha,
  AppBar,
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Hidden,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Slide,
  styled,
  Tooltip,
  Typography,
  useTheme,
  userData,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import fetchApi from "../../../../Apis/fetchApi";
import collegeLogo from "../../../../Assets/Images/png/collegeLogo.png";
import { NIYUKTI_API, PRAVESH_API } from "../../../../Constants/constants";
import Institutes from "../../../Cards/Post/NewInstitutes";
import NewRound from "../../../Cards/Post/NewRound";
import SmallQualifications from "../../../Cards/Post/NewSmallQualifications";
import PreviewJob from "../../../Cards/Post/PreviewJob";
import Editor from "../../../Common/InputText/Editor/PostJobEditor";
import { useDispatch } from "react-redux";

import {
  CustomActionChipTextField,
  CustomAutoCompletePaper,
  CustomTextField,
} from "../../../Common/InputText/InputField";
import { NumericFormatCustom } from "../../../Common/NumericFormatCustom";
import DescriptionTemplate from "../../../Dialog/Job/DescriptionTemplate";
import SelectCourseDialog from "../../../Dialog/Job/NewSelectCourseDialog";
import Complete from "../../PostJobSection/Complete";
import OpenToAll from "../../PostJobSection/NewOpenToAll";
import { getUserDetails } from "../../../../Redux/userDetailSlice";
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const Chiplist = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#000" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#fff",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  border: "none",
  [theme.breakpoints.only("xs")]: {
    padding: "6px",
  },
}));

function MergedThirdForm({
  step,
  setStep,
  postJobData,
  setPostJobData,
  allCities,
  setAllCities,
  jobRoleList,
  specializationLoader,
  location,
  findCompanyLocation,
  postPlan,
  setPostPlaError,
  jd_jk_id,
  flagCheck,
  job_id,
  planDropdownList,
  initialState,
  postPlaError,
  setPostPlan,
  postJobLoginFlow = false,
  userData={}
}) {
  const [roundDuration, setRoundDuration] = useState(null);
  const [roundVenue, setRoundVenue] = useState(null);
  const [jobRounds, setJobRounds] = useState([]);
  const [currentRound, setCurrentRound] = useState(null);
  const [minWorkEx, setMinWorkEx] = useState(null);
  const [maxWorkEx, setMaxWorkEx] = useState(null);
  const [skillData, setSkillData] = useState([]);
  const [searchedCity, setSearchedCity] = useState();
  const [selectedSkill, setSelectedSkill] = useState({});
  const [cityLoading, setCityLoading] = useState(false);
  const [chipData, setChipData] = useState();
  const [formOne, setFormOne] = useState(false);
  const [formTwo, setFormTwo] = useState(false);
  const [formThree, setFormThree] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [jobDataAfterPost, setJobDataAfterPost] = useState(null);
  const history = useHistory();

  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setpreviewData] = useState(null);
  const [draftJobLoader, setDraftJobLoader] = useState(false);

  const theme = useTheme();
  const alert = useAlert();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const initialCheckState = {
    aboutJob: false,
    eligiblityCriteria: false,
    selectionProcedure: false,
  };
  const initialCheckState2 = {
    aboutJob: true,
    eligiblityCriteria: true,
    selectionProcedure: true,
  };
  const [formCheckBox, setFormCheckBox] = useState(
    flagCheck === "edit" || flagCheck === "clone"
      ? initialCheckState2
      : initialCheckState
  );
  const [jobDes, setJobDes] = useState(
    postJobData?.Details?.job_description || ""
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!state) {
      dispatch(getUserDetails());
    }
  }, []);

  const state = useSelector(
    (state) => state?.userDetails?.userDetails?.access_control
  );


  const userDetail = useSelector((state) => {
    return state?.userDetails?.userDetails?.user_details;
  });

  useEffect(() => {
    setJobDes(postJobData?.Details?.job_description || "");
  }, [postJobData?.Details?.job_description]);

  useEffect(() => {
    setPostJobData((prevPostJobData) => ({
      ...prevPostJobData,
      Details: {
        ...prevPostJobData.Details,
        job_description: jobDes,
      },
    }));
  }, [jobDes]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [allSkills, setAllSkills] = useState([]);

  const helper = (data) => {
    setAllSkills(data?.data?.skills);
  };

  const getSkillsList = () => {
    fetchApi({
      url: `${PRAVESH_API}api/education/skills`,
      setResponse: helper,
    });
  };
  useEffect(() => {
    // getSkillsList();
    handleSkillChange("");
  }, []);

  const isdebounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleSkillChange = (value) => {
    if (value || value === "")
      fetchApi({
        url: `${PRAVESH_API}api/education/skills?&skill_name=${value}&result_count=50`,
        setResponse: helper,
      });
  };

  const debounceSkillList = useCallback(isdebounce(handleSkillChange), []);

  const updateChipData = (value) => {
    const ind = chipData?.findIndex((x) => x.skill_id === value?.skill_id);
    if (ind === -1) {
      setChipData([...chipData, value]);
    }
  };

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips?.filter((chip) => chip?.skill_id !== chipToDelete?.skill_id)
    );
  };

  const getJobRounds = () => {
    const endPoint = "job/round/";
    axios
      .get(`${NIYUKTI_API}${endPoint}`)
      .then((res) => {
        setJobRounds(res?.data?.data);
      })
      .catch((error) => {
        throw error;
      });
  };
  useEffect(() => {
    getJobRounds();
    setPostJobData({
      ...postJobData,

      Basics: {
        ...postJobData?.Basics,
        is_ipo_job: false,
      },
    });
  }, []);

  const addRound = () => {
    if (!currentRound /* && !roundDuration && !roundVenue */) {
      return;
    }
    if (
      !postJobData?.Basics?.rounds
        ?.map((item) => item?.round_id)
        ?.includes(currentRound?.id)
    ) {
      const selectRound = {
        round_id: currentRound.id,
        r_name: currentRound.name,
        round_no: postJobData?.Basics?.rounds?.length + 1,
        venue: roundVenue?.val ? roundVenue?.val : null,
        duration: roundDuration?.val ? roundDuration?.val : null,
        is_interviewed: true,
        /* is_interviewed: true, */
      };

      let arr =
        postJobData?.Basics?.rounds?.length > 0
          ? [...postJobData?.Basics?.rounds]
          : [];
      arr.push(selectRound);
      setPostJobData({
        ...postJobData,
        Basics: {
          ...postJobData.Basics,
          rounds: arr,
        },
      });
      setErrorValues({ ...errorValues, jobRounds: "" });
      setCurrentRound(null);
      setRoundVenue(null);
      setRoundDuration(null);
    }
  };

  const deleteRound = (round) => {
    if (
      postJobData?.Basics?.rounds?.length > 0 &&
      postJobData?.Basics?.rounds?.includes(round)
    ) {
      let temp = postJobData?.Basics?.rounds?.filter(
        (rd) => rd.round_id !== round.round_id
      );
      setPostJobData({
        ...postJobData,
        Basics: {
          ...postJobData.Basics,
          rounds: temp,
        },
      });
    }
  };
  const documents = [
    "Resume",
    "Cover Letter",
    "Portfolio",
    "Transcript",
    "ID Proof (e.g. Aadhar Card, PAN Card, etc.)",
  ];

  const roundtime = [
    // { label: "5 min", val: 5 },
    // { label: "10 min", val: 10 },
    { label: "15 min", val: 15 },
    { label: "20 min", val: 20 },
    { label: "25 min", val: 25 },
    { label: "30 min", val: 30 },
    { label: "35 min", val: 35 },
    { label: "40 min", val: 40 },
    { label: "45 min", val: 45 },
    { label: "50 min", val: 50 },
    { label: "55 min", val: 55 },
    { label: "60 min", val: 60 },
    { label: "1 hour 15 min", val: 75 },
    { label: "1 hour 30 min", val: 90 },
    { label: "1 hour 45 min", val: 105 },
    { label: "2 hour", val: 120 },
  ];

  const year = [
    { name: "2011", value: "2011" },
    { name: "2012", value: "2012" },
    { name: "2013", value: "2013" },
    { name: "2014", value: "2014" },
    { name: "2015", value: "2015" },
    { name: "2016", value: "2016" },
    { name: "2017", value: "2017" },
    { name: "2018", value: "2018" },
    { name: "2019", value: "2019" },
    { name: "2020", value: "2020" },
    { name: "2021", value: "2021" },
    { name: "2022", value: "2022" },
    { name: "2023", value: "2023" },
    { name: "2024", value: "2024" },
    { name: "2025", value: "2025" },
    { name: "2026", value: "2026" },
    { name: "2027", value: "2027" },
  ];
  const venue = [
    { label: "Online", val: "Online" },
    { label: "Offline", val: "Offline" },
  ];

  const uniqueArray = (arr) => {
    return [...new Set(arr)];
  };

  const [renderChips, setRenderChips] = useState(true);

  const [degree, setDegree] = React.useState([]);
  const [specialization, setSpecialization] = useState([]);

  const handleSelectAllCourses = (e) => {
    setRenderChips(false);

    var len =
      postJobData?.OffCampus?.allData &&
      Object.keys(postJobData?.OffCampus?.allData);

    len?.forEach((item) => {
      postJobData.OffCampus.allData[item][0].isValue =
        e.target.checked || false;

      postJobData?.OffCampus?.allData[item]?.forEach((n) => {
        n.isActive = e.target.checked || false;

        setPostJobData({
          ...postJobData,
          OffCampus: {
            ...postJobData?.OffCampus,
            allData: {
              ...postJobData?.OffCampus?.allData,
            },
          },
        });

        if (n?.isActive === true) {
          var temp2 = [];

          postJobData?.OffCampus?.degree?.map((a, o) => {
            temp2.push(a.id);
          });

          if (temp2.includes(n.degree.id) === false) {
            postJobData?.OffCampus?.degree?.push(n?.degree);
          }
        } else
          while (postJobData?.OffCampus?.degree?.length > 0) {
            postJobData?.OffCampus?.degree?.pop();
          }

        if (n.isActive === true)
          postJobData?.OffCampus?.specialization.push(n?.specialization);
        else
          while (postJobData?.OffCampus?.specialization?.length > 0) {
            postJobData?.OffCampus?.specialization?.pop();
          }

        setDegree(postJobData?.OffCampus?.degree);
        setSpecialization(postJobData?.OffCampus?.specialization);
      });
    });
  };
  const handleSelectAllSpecialization1 = (e, item, index) => {
    // var arr = allData;
    var arr = postJobData?.OffCampus?.allData;

    postJobData.OffCampus.allData[item].forEach((it) => {
      it.isActive = e.target.checked || false;
      //   setAllData({ ...allData });
      setPostJobData({
        ...postJobData,
        OffCampus: {
          ...postJobData?.OffCampus,
          allData: { ...postJobData?.OffCampus?.allData },
        },
      });

      if (it.isActive === true) {
        var temp2 = [];

        postJobData.OffCampus.degree.map((a, o) => {
          temp2.push(a.id);
        });

        if (temp2.includes(it.degree.id) === false) {
          postJobData?.OffCampus?.degree?.push(it.degree);
        }
      } else {
        postJobData.OffCampus.degree = postJobData?.OffCampus?.degree.filter(
          (item) => item.id !== it.degree.id
        );

        // state.OffCampus.degree.splice(index, 1);
      }

      if (it.isActive === true) {
        let checkIfAlreadyExit = postJobData?.OffCampus?.specialization?.find(
          (sp) => sp?.id === it?.specialization?.id
        );
        if (!checkIfAlreadyExit)
          postJobData?.OffCampus?.specialization?.push(it.specialization);
      } else {
        postJobData.OffCampus.specialization =
          postJobData?.OffCampus?.specialization?.filter(
            (item) => item.degree_id !== it.degree.id
          );
      }

      setDegree(postJobData?.OffCampus?.degree);
      setSpecialization(postJobData?.OffCampus?.specialization);

      arr[item][0].isChecked = e.target.checked || false;

      // setAllData({ ...arr });
      setPostJobData({
        ...postJobData,
        OffCampus: {
          ...postJobData?.OffCampus,
          allData: { ...arr },
        },
      });
    });
  };

  const handleSelectSingleSpecialization1 = (e, item, index) => {
    // var arr = allData;
    var arr = postJobData?.OffCampus?.allData;
    arr[item][index].isActive = e.target.checked || false;
    arr[item][index].parent_key = item;

    // setAllData({ ...arr });
    setPostJobData({
      ...postJobData,
      OffCampus: {
        ...postJobData?.OffCampus,
        allData: { ...arr },
      },
    });
    var array1 = [];
    postJobData?.OffCampus?.specialization?.map((item) => {
      array1.push(item.degree_id);
    });

    if (arr[item][index].isActive === true) {
      var temp2 = [];

      postJobData?.OffCampus?.degree?.map((a, o) => {
        temp2.push(a.id);
      });

      if (temp2.includes(arr[item][index].degree.id) === false) {
        let sp = [];
        //sp.push(arr[item][index]?.specialization);
        let deg = { ...arr[item][index].degree, spec: sp };
        //postJobData?.OffCampus?.degree?.push(arr[item][index].degree);
        postJobData?.OffCampus?.degree?.push(deg);
      } /* else {
        for (let i = 0; i < postJobData?.OffCampus?.degree?.length; i++) {
          if (
            postJobData?.OffCampus?.degree[i]?.id ===
            arr[item][index]?.specialization?.degree_id
          ) {
            postJobData?.OffCampus?.degree[i]?.spec?.push(
              arr[item][index]?.specialization
            );
            return;
          }
        }
      } */
    } else {
      if (!checkForDuplicates(array1)) {
        var indexing;
        postJobData?.OffCampus?.degree.map((u, iin) => {
          if (u.id === arr[item][index].degree.id) {
            indexing = iin;
          }
        });
        postJobData?.OffCampus?.degree?.splice(indexing, 1);
      }
    }
    var indexx;
    if (arr[item][index].isActive === true) {
      postJobData?.OffCampus?.specialization?.push({
        ...arr[item][index].specialization,
        index_in_all_data: index,
      });
      for (let i = 0; i < postJobData?.OffCampus?.degree?.length; i++) {
        if (
          postJobData?.OffCampus?.degree[i]?.id ===
          arr[item][index]?.specialization?.degree_id
        ) {
          postJobData?.OffCampus?.degree[i]?.spec?.push({
            ...arr[item][index].specialization,
            index_in_all_data: index,
          });
          return;
        }
      }
    } else {
      postJobData?.OffCampus?.specialization.map((u, iin) => {
        if (u.id === arr[item][index].specialization.id) {
          indexx = iin;
        }
      });

      for (let i = 0; i < postJobData?.OffCampus?.degree?.length; i++) {
        if (
          postJobData?.OffCampus?.degree[i]?.id ===
          arr[item][index]?.specialization?.degree_id
        ) {
          postJobData?.OffCampus?.degree[i]?.spec?.map((sp, specIndex) => {
            if (sp.id === arr[item][index].specialization.id) {
              postJobData?.OffCampus?.degree[i]?.spec?.splice(specIndex, 1);
            }
            return;
          });
        }
      }

      postJobData?.OffCampus?.specialization?.splice(indexx, 1);
    }

    setDegree(postJobData?.OffCampus?.degree);
    setSpecialization(postJobData?.OffCampus?.specialization);

    if (postJobData?.OffCampus?.specialization?.length)
      arr[item][0].isValue = true;
    else arr[item][0].isValue = false;
  };
  function checkForDuplicates(array) {
    let valuesAlreadySeen = [];

    for (let i = 0; i < array.length; i++) {
      let value = array[i];
      if (valuesAlreadySeen?.indexOf(value) !== -1) {
        return true;
      }
      valuesAlreadySeen?.push(value);
    }
    return false;
  }
  useEffect(() => {
    if (degree?.length > 0 && specialization?.length > 0) {
      var booleArrayAllDegree = [];
      var booleArrayAllSpecialization = [];

      booleArrayAllDegree =
        postJobData.Colleges.hiring_type == "OFF CAMPUS" &&
        Object.keys(postJobData?.OffCampus?.allData).map((item) =>
          postJobData.OffCampus.allData[item].every((e) => e.isActive === true)
        );
      var allDegreeCheck =
        postJobData.Colleges.hiring_type == "OFF CAMPUS" &&
        booleArrayAllDegree?.every((e) => e === true);

      booleArrayAllSpecialization =
        postJobData.Colleges.hiring_type == "OFF CAMPUS" &&
        degree?.map((item) =>
          postJobData?.OffCampus?.allData[item?.name]?.forEach((e) => {
            if (e.isActive === true) {
              let degSpecs = [];
              degSpecs = specialization?.filter(
                (sp) => sp?.degree_id === item?.id
              );
              item["spec"] = degSpecs;
            }
          })
        );

      degree?.forEach((item) => {
        item["all_spec_selected"] = postJobData?.OffCampus?.allData[
          item?.name
        ]?.every((e) => e.isActive === true);
      });
      degree?.forEach((item) => {
        item["all_degree_selected"] = allDegreeCheck;
      });

      setPostJobData({
        ...postJobData,
        Preferences: {
          ...postJobData?.Preferences,
          eligibility_criteria: {
            ...postJobData?.Preferences?.eligibility_criteria,
            degrees: degree,
            courses: specialization,
          },
        },
      });
      setRenderChips(true);
    }
  }, [degree?.length, specialization?.length]);

  useEffect(() => {
    let e = { target: { checked: false } };
    if (postJobData?.Colleges?.hiring_type === "ON CAMPUS") {
      handleSelectAllCourses(e);
    }
  }, [postJobData?.Colleges?.hiring_type]);

  useEffect(() => {
    if (minWorkEx && maxWorkEx && minWorkEx?.val > maxWorkEx?.val) {
      // alert?.error(
      //   "Maximum Experience should be equal/greater than Minimum Experience"
      // );
      return;
    }
    if (minWorkEx && maxWorkEx) {
      let exp1 = `${minWorkEx?.val === 11 ? "10+" : minWorkEx?.val}-${
        maxWorkEx?.val === 11 ? "10+" : maxWorkEx?.val
      } years`;
      setPostJobData({
        ...postJobData,
        Preferences: {
          ...postJobData?.Preferences,
          eligibility_criteria: {
            ...postJobData?.Preferences?.eligibility_criteria,
            experience: {
              min: minWorkEx?.val === 11 ? 10 : parseInt(minWorkEx?.val),
              max: maxWorkEx?.val === 11 ? 100 : parseInt(maxWorkEx?.val),
              exp: exp1,
            } /* [exp], */,
          },
        },
      });
    }
  }, [minWorkEx, maxWorkEx]);

  const [edulevel, setEdulevel] = useState(null);
  const [percentage, setPercentage] = useState("");
  const educationLevelTypes = [
    { name: "Graduation (UG)", id: 3 },
    { name: "Post Graduation (PG)", id: 4 },
    { name: "PhD", id: 5 },
    { name: "Diploma", id: 6 },
  ];

  const handleAddQualification = () => {
    if (!edulevel || !percentage) {
      setErrorValues({
        ...errorValues,
        qualifications: "please fill Education and percentage details first",
      });
      // alert?.error("please fill Education and percentage details first");
      return;
    }
    if (percentage < 33) {
      setErrorValues({
        ...errorValues,
        qualifications: "Minimum percentage should be atleast 33 percent",
      });
      return;
    }

    if (
      !postJobData?.Preferences?.eligibility_criteria?.qualifications
        ?.map((item) => item?.education_type)
        ?.includes(edulevel?.id)
    ) {
      const selectedQualification = {
        education_type: edulevel?.id,
        education_type_name: edulevel?.name,
        percentage: parseFloat(percentage),
      };

      let arr =
        postJobData?.Preferences?.eligibility_criteria?.qualifications?.length >
        0
          ? [...postJobData?.Preferences?.eligibility_criteria?.qualifications]
          : [];

      arr.push(selectedQualification);

      setPostJobData({
        ...postJobData,
        Preferences: {
          ...postJobData.Preferences,
          eligibility_criteria: {
            ...postJobData?.Preferences?.eligibility_criteria,
            qualifications: arr,
          },
        },
      });
      setErrorValues({ ...errorValues, qualifications: "" });
      setEdulevel(null);
      setPercentage("");
    } else {
      alert?.error("Percentage Criteria alredy added");
      return;
    }
  };

  const deleteQualification = (qual) => {
    if (
      postJobData?.Preferences?.eligibility_criteria?.qualifications
        ?.map((item) => item?.education_type)
        ?.includes(qual?.education_type)
    ) {
      let temp =
        postJobData?.Preferences?.eligibility_criteria?.qualifications?.filter(
          (q) => q.education_type !== qual.education_type
        );

      setPostJobData({
        ...postJobData,
        Preferences: {
          ...postJobData.Preferences,
          eligibility_criteria: {
            ...postJobData?.Preferences?.eligibility_criteria,
            qualifications: temp,
          },
        },
      });
    }
  };

  const CollegeID = () => {
    if (postJobData?.college_id_list?.checkAll) {
      return `&is_select_all=true`;
    } else return "";
  };

  const helperDashboard = (data) => {
    // if (data.success) {
    //   history.push("/manage-jobs/");
    // }
    setJobDataAfterPost({
      id: data?.data?.job_id,
      name: data?.data?.job_title,
    });
  };

  const [errorValues, setErrorValues] = useState();

  const validateForm = (from) => {
    let joiningDate = postJobData?.Basics?.job_duration_start;
    let start = postJobData?.Colleges?.apply_start_date;
    let end = postJobData?.Colleges?.apply_end_date;

    let isError = false;
    let jobTypeError = "";
    let jobTitleError = "";
    let jobRoleError = "";
    let startDateError = "";
    let endDateError = "";
    let joiningDateError = "";
    let skillsError = "";
    let descriptionError = "";
    let durationError = "";
    let minWorkExError = "";
    let maxWorkExError = "";
    let minStipendError = "";
    let minCtcError = "";
    let maxCtcError = "";
    let locationError = "";
    let vacancyError = "";
    let hiringTypeError = "";
    let coursesCollgesError = "";
    let passingYearsError = "";
    let jobRoundsError = "";
    let qualificationsError = "";
    let notice_period = "";

    if (from === 1) {
      if (!postJobData?.Basics?.job_title) {
        jobTitleError = "Job Title Required";
        isError = true;
      }

      if (!postJobData?.Details?.job_role) {
        jobRoleError = "Job Role Required";
        isError = true;
      }

      if (!postJobData?.Basics?.job_type) {
        jobTypeError = "Job Type Required";
        isError = true;
      }

      if (
        postJobData?.Basics?.job_type === 1 &&
        !postJobData?.Basics?.duration
      ) {
        durationError = "Internship Duration Required";
        isError = true;
      }

      if (!jobDes) {
        descriptionError = "Job Description Required";
        isError = true;
      }

      if (
        (postJobData?.Details?.work_from_home === "WORK FROM OFFICE" ||
          postJobData?.Details?.work_from_home === "HYBRID") &&
        !postJobData?.Details?.job_location?.length > 0
      ) {
        locationError = "Atleast One Location Required";
        isError = true;
      }
      if (!postJobData?.Details?.vacancy) {
        vacancyError = "Please Enter No. Of Vacancies";
        isError = true;
      }

      if (
        postJobData?.Basics?.job_type === 1 &&
        postJobData?.Basics?.internship_unpaid === true &&
        postJobData?.Basics?.stipend
      ) {
        minStipendError = "Please Enter Stipend Amount";
        isError = true;
      }

      if (
        (postJobData?.Basics?.job_type === 7 ||
          (postJobData?.Basics?.job_type === 1 &&
            postJobData?.Basics?.internship_ppo)) &&
        postJobData?.Details?.salary_type === "PAID" &&
        (!postJobData?.Details?.ctc_min ||
          !postJobData?.Details?.ctc_max ||
          postJobData?.Details?.ctc_min === 0 ||
          postJobData?.Details?.ctc_max === 0)
      ) {
        minCtcError = "Min Ctc Required";
        maxCtcError = "Max Ctc Required";
        isError = true;
      }

      if (
        (postJobData?.Basics?.job_type === 7 ||
          (postJobData?.Basics?.job_type === 1 &&
            postJobData?.Basics?.internship_ppo)) &&
        postJobData?.Details?.salary_type === "PAID" &&
        postJobData?.Details?.ctc_min > postJobData?.Details?.ctc_max
      ) {
        minCtcError = "Min ctc should be less than max ctc";
        isError = true;
      }
    } else if (from === 2) {
      if (
        !(postJobData?.Preferences?.eligibility_criteria?.skills?.length > 0)
      ) {
        skillsError = "Please Add Skills";
        isError = true;
      }

      if (
        !(
          postJobData?.Preferences?.eligibility_criteria?.qualifications
            ?.length > 0
        )
      ) {
        qualificationsError = "Please Add Qualification Details";
        isError = true;
      }
      if (
        !(
          postJobData?.Preferences?.eligibility_criteria?.graduation_years
            ?.length > 0
        )
      ) {
        passingYearsError = "Passing Years Required";
        isError = true;
      }

      if (!minWorkEx) {
        minWorkExError = "Min Work Experience Required";
        isError = true;
      }
      if (!maxWorkEx) {
        maxWorkExError = "Max Work Experience Required";
        isError = true;
      }
      if (minWorkEx?.val > maxWorkEx?.val) {
        minWorkExError =
          "Min Work Experience should be greater than Max Work Experience";
        isError = true;
      }
      if (!postJobData?.notice_period) {
        notice_period = "Notice period Required";
        isError = true;
      }
    } else if (from === 3) {
      if (!postJobData?.Basics?.job_duration_start) {
        joiningDateError = "Joining Date Required";
        isError = true;
      }

      if (!postJobData?.Colleges?.apply_start_date) {
        startDateError = "Apply Start Date Required";
        isError = true;
      }

      if (!postJobData?.Colleges?.apply_end_date) {
        endDateError = "Apply End Date Required";
        isError = true;
      }

      if (end > joiningDate) {
        endDateError =
          " Application End Date should be less than Joining Date ";
        isError = true;
      }
      if (start > end) {
        startDateError =
          "Application Start Date  should be less than Application End Date";
        isError = true;
      }

      if (!postJobData?.Preferences?.eligibility_criteria?.skills?.length > 0) {
        skillsError = "Atleast One Skill Required";
        isError = true;
      }

      if (!postJobData?.Colleges?.hiring_type) {
        hiringTypeError = "Please Select Hiring Type";
        isError = true;
      }
      if (
        postJobData?.Colleges?.hiring_type === "OFF CAMPUS" &&
        !postJobData?.OffCampus?.degree?.length > 0
      ) {
        coursesCollgesError = "Please Select Courses";
        isError = true;
      }
      if (
        !postJobData?.college_id_list?.checkAll &&
        postJobData?.Colleges?.hiring_type &&
        postJobData?.Colleges?.hiring_type === "ON CAMPUS" &&
        /* !state.Basics.posted_on_job_lake && */
        !postJobData?.Colleges?.college?.length > 0
      ) {
        coursesCollgesError = "Please Select College";
        isError = true;
      }
      if (postJobData?.Basics?.rounds.length === 0) {
        jobRoundsError = "Please Add Rounds";
        isError = true;
      }
    } else {
      if (!postJobLoginFlow && (!postPlan || postPlan == -1)) {
        setPostPlaError(true);
        isError = true;
      }
      if (!postJobData?.Basics?.job_title) {
        jobTitleError = "Job Title Required";
        isError = true;
      }

      if (!postJobData?.Basics?.job_type) {
        jobTypeError = "Job Type Required";
        isError = true;
      }

      if (
        postJobData?.Basics?.job_type === 1 &&
        !postJobData?.Basics?.duration
      ) {
        durationError = "Internship Duration Required";
        isError = true;
      }

      if (!jobDes) {
        descriptionError = "Job Description Required";
        isError = true;
      }

      if (
        (postJobData?.Details?.work_from_home === "WORK FROM OFFICE" ||
          postJobData?.Details?.work_from_home === "HYBRID") &&
        !postJobData?.Details?.job_location?.length > 0
      ) {
        locationError = "Atleast One Location Required";
        isError = true;
      }
      if (!postJobData?.Details?.vacancy) {
        vacancyError = "Please Enter No. Of Vacancies";
        isError = true;
      }

      // if (
      //   postJobData?.Basics?.job_type === 1 &&
      //   !postJobData?.Basics?.internship_unpaid &&
      //   postJobData?.Basics?.stipend
      // ) {
      //   minStipendError = "Please Enter Stipend Amount";
      //   isError = true;
      // }

      if (
        (postJobData?.Basics?.job_type === 7 ||
          (postJobData?.Basics?.job_type === 1 &&
            postJobData?.Basics?.internship_ppo)) &&
        postJobData?.Details?.salary_type === "PAID" &&
        (!postJobData?.Details?.ctc_min ||
          !postJobData?.Details?.ctc_max ||
          postJobData?.Details?.ctc_min === 0 ||
          postJobData?.Details?.ctc_max === 0)
      ) {
        minCtcError = "Min Ctc Required";
        maxCtcError = "Max Ctc Required";
        isError = true;
      }

      if (
        (postJobData?.Basics?.job_type === 7 ||
          (postJobData?.Basics?.job_type === 1 &&
            postJobData?.Basics?.internship_ppo)) &&
        postJobData?.Details?.salary_type === "PAID" &&
        postJobData?.Details?.ctc_min > postJobData?.Details?.ctc_max
      ) {
        minCtcError = "Min ctc should be less than max ctc";
        isError = true;
      }

      if (
        !(postJobData?.Preferences?.eligibility_criteria?.skills?.length > 0)
      ) {
        skillsError = "Please Add Skills";
        isError = true;
      }

      if (
        !(
          postJobData?.Preferences?.eligibility_criteria?.qualifications
            ?.length > 0
        )
      ) {
        qualificationsError = "Please Add Qualification Details";
        isError = true;
      }
      if (
        !(
          postJobData?.Preferences?.eligibility_criteria?.graduation_years
            ?.length > 0
        )
      ) {
        passingYearsError = "Passing Years Required";
        isError = true;
      }

      if (!minWorkEx) {
        minWorkExError = "Min Work Experience Required";
        isError = true;
      }
      if (!maxWorkEx) {
        maxWorkExError = "Max Work Experience Required";
        isError = true;
      }
      if (minWorkEx?.val > maxWorkEx?.val) {
        minWorkExError =
          "Min Work Experience should be greater than Max Work Experience";
        isError = true;
      }

      if (!postJobData?.notice_period) {
        notice_period = "Notice period Required";
        isError = true;
      }

      if (!postJobData?.Basics?.job_duration_start) {
        joiningDateError = "Joining Date Required";
        isError = true;
      }

      if (!postJobData?.Colleges?.apply_start_date) {
        startDateError = "Apply Start Date Required";
        isError = true;
      }

      if (!postJobData?.Colleges?.apply_end_date) {
        endDateError = "Apply End Date Required";
        isError = true;
      }

      if (end > joiningDate) {
        endDateError =
          " Application End Date should be less than Joining Date ";
        isError = true;
      }
      if (start > end) {
        startDateError =
          "Application Start Date  should be less than Application End Date";
        isError = true;
      }

      if (!postJobData?.Preferences?.eligibility_criteria?.skills?.length > 0) {
        skillsError = "Atleast One Skill Required";
        isError = true;
      }

      if (!postJobData?.Colleges?.hiring_type) {
        hiringTypeError = "Please Select Hiring Type";
        isError = true;
      }
      if (
        postJobData?.Colleges?.hiring_type === "OFF CAMPUS" &&
        !postJobData?.OffCampus?.degree?.length > 0
      ) {
        coursesCollgesError = "Please Select Courses";
        isError = true;
      }
      if (
        !postJobData?.college_id_list?.checkAll &&
        postJobData?.Colleges?.hiring_type &&
        postJobData?.Colleges?.hiring_type === "ON CAMPUS" &&
        /* !state.Basics.posted_on_job_lake && */
        !postJobData?.Colleges?.college?.length > 0
      ) {
        coursesCollgesError = "Please Select College";
        isError = true;
      }
      if (postJobData?.Basics?.rounds.length === 0) {
        jobRoundsError = "Please Add Rounds";
        isError = true;
      }
    }

    setErrorValues({
      skills: skillsError,
      jobType: jobTypeError,
      jobTitle: jobTitleError,
      jobRole: jobRoleError,
      startDate: startDateError,
      endDate: endDateError,
      joiningDate: joiningDateError,
      skills: skillsError,
      description: descriptionError,
      duration: durationError,
      minWorkEx: minWorkExError,
      maxWorkEx: maxWorkExError,
      minStipend: minStipendError,
      minCtc: minCtcError,
      maxCtc: maxCtcError,
      location: locationError,
      vacancy: vacancyError,
      hiringType: hiringTypeError,
      qualifications: qualificationsError,
      coursesCollges: coursesCollgesError,
      passingYears: passingYearsError,
      jobRounds: jobRoundsError,
      notice_period: notice_period,
    });

    return isError ? false : true;
  };

  const checkJobID = () => {
    if (job_id) return `job_id=${btoa(job_id.toString())}`;
    else return ``;
  };

  const [showCompleted, setShowCompleted] = useState(userData?.data?.user_details.is_job_posted|| false);
  const postJobFunction = (data, status) => {
    const helperPost = (data) => {
      if (flagCheck === "draft" || flagCheck === "edit" || status === "DRAFT") {
        history.push("/my-jobs");
      } else {
        setJobDataAfterPost({
          id: data?.data?.job_id,
          name: data?.data?.job_title,
        });
        setShowCompleted(true);
      }
    };

    fetchApi({
      url: `${NIYUKTI_API}job/saas/company/job_post/?${CollegeID()}`, //job/company/job_post/?${CollegeID()}
      method: "post",
      alert: alert,
      body: JSON.stringify(data),
      setLoading: status === "DRAFT" ? setDraftJobLoader : setLoading,
      callBackFn: helperPost,
      alert: alert,
      errorCallback: errorCallback,
      setError: errorCallback,
    });
  };

  const errorCallback = () => {
    setLoading(false);
    setDraftJobLoader(false);
  };

  const patchJobFunction = (data, status) => {
    const helperPatch = (data) => {
      if (flagCheck === "draft" || flagCheck === "edit") {
        history.push("/my-jobs");
      } else {
        setJobDataAfterPost({
          id: data?.data?.job_id,
          name: data?.data?.job_title,
        });
        setShowCompleted(true);
      }
    };
    fetchApi({
      url: `${NIYUKTI_API}job/saas/company/job_post/?${checkJobID()}${CollegeID()}`, //job/company/job_post/?${CollegeID()}
      method: "patch",
      alert: alert,
      body: JSON.stringify(data),
      setLoading: status === "DRAFT" ? setDraftJobLoader : setLoading,
      callBackFn: helperPatch,
      alert: alert,
      errorCallback: errorCallback,
    });
  };

  const validateSelectionProcedure = () => {
    if (!postJobData?.Colleges?.hiring_type) {
      alert.error("Please Select Hiring Type");
      return false;
    }
    if (
      postJobData?.Colleges?.hiring_type === "OFF CAMPUS" &&
      !postJobData?.OffCampus?.degree?.length > 0
    ) {
      alert.error("Please Select Courses");
      return false;
    }
    if (
      !postJobData?.college_id_list?.checkAll &&
      postJobData?.Colleges?.hiring_type &&
      postJobData?.Colleges?.hiring_type === "ON CAMPUS" &&
      /* !state.Basics.posted_on_job_lake && */
      !postJobData?.Colleges?.college?.length > 0
    ) {
      alert.error("Please Select College");
      return;
    }
    if (
      postJobData?.Colleges?.hiring_type === "OFF CAMPUS" &&
      !postJobData?.Basics?.rounds?.length > 0
    ) {
      alert.error("Atleast One Job Round Required");
      return false;
    }
    // if (
    //   postJobData?.Colleges?.hiring_type === "OFF CAMPUS" &&
    //   !postJobData?.Details?.documents?.length > 0
    // ) {
    //   alert.error("Please Select The Documents Required");
    //   return false;
    // }
    // if (
    //   postPlan &&
    //   postPlan?.type === "SLOT" &&
    //   postJobData?.Details?.vacancy > postPlan?.available
    // ) {
    //   alert.error("Vacancy cannot be more than the slots available");
    //   return false;
    // }
    return true;
  };

  const createJob = (status = "") => {
    const graduationYearsArr =
      postJobData?.Preferences?.eligibility_criteria?.graduation_years?.map(
        (obj) => parseInt(obj?.value)
      );
    let percent =
      postJobData?.Preferences?.eligibility_criteria?.qualifications[0]
        ?.percentage;
    const data = {
      ...postJobData?.Basics,
      ...postJobData?.Details,
      ...postJobData?.Preferences,
      ...postJobData?.Colleges,
      work_from_home:
        postJobData?.Details?.work_from_home === "WORK FROM HOME" ||
        postJobData?.Details?.work_from_home === "HYBRID"
          ? true
          : false,
      job_location:
        postJobData?.Details?.work_from_home === "WORK FROM HOME"
          ? []
          : postJobData?.Details?.job_location,
      ppo: postJobData?.Basics?.ppo ? true : false,
      /*     hiring_type: postJobData?.Colleges?.hiring_type
        ? postJobData?.Colleges?.hiring_type
        : "OFF CAMPUS", */
      bond_condition: postJobData?.Details?.service_bond_description
        ? postJobData?.Details?.service_bond_description
        : null,
      job_status: status,
      college_list: postJobData?.college_id_list?.list,
      user_type_id: userDetail?.type_id,
      user_id:
        userDetail?.id ||
        JSON.parse(sessionStorage.getItem("user_details")).user_id,
      company_id:
        userDetail?.company ||
        JSON.parse(sessionStorage.getItem("user_details")).company_id,
      service_type: "POST_JOB",

      // amount: creditInfo?.user_service?.find(
      //   (item) => item?.service_type === "POST_JOB"
      // )?.credits,
      amount: 1500,
      transaction_type: "DEBIT",
      plan_id: postPlan?.plan_id ? postPlan?.plan_id : -1,
      model_id: postPlan?.model_id ? postPlan?.model_id : -1,
      is_ai_job: jd_jk_id ? true : false,
      jd_ik_id: jd_jk_id,
      notice_period: postJobData?.notice_period,
    };

    let data2 = {
      ...data,
      eligibility_criteria: {
        ...data?.eligibility_criteria,
        graduation_years: graduationYearsArr,
        percentage: percent,
        degrees:
          postJobData?.Colleges?.hiring_type === "OFF CAMPUS"
            ? postJobData?.OffCampus?.degree
            : [],
        courses:
          postJobData?.Colleges?.hiring_type === "OFF CAMPUS"
            ? postJobData?.OffCampus?.specialization
            : [],
      },
    };

    if (status === "preview") {
      if (!postJobData?.Basics?.job_title) {
        alert.error("Job Title Required");
        return;
      }
      data2 = {
        ...data2,
        work_from_home: postJobData?.Details?.work_from_home,
      };

      setpreviewData(data2);
      setShowPreview(true);
      return;
    }

    if (validateSelectionProcedure()) {
      if (
        postPlan &&
        postPlan?.type === "SLOT" &&
        flagCheck !== "draft" &&
        flagCheck !== "edit" &&
        postJobData?.Details?.vacancy > postPlan?.available
      ) {
        alert.error("Vacancy cannot be more than the slots available");
        return;
      }
      if (planDropdownList?.length > 0 && !postPlan) {
        alert.error("Please Select a Plan to post the job");
        return;
      }

      if (job_id) {
        if (flagCheck === "edit") {
          patchJobFunction(data2, status);
        } else if (flagCheck === "clone") {
          //cloning job
          postJobFunction(data2, status);
        } else {
          if (status === "OPEN") {
            patchJobFunction(data2, status);
            //PublishJob();
          }
          if (status === "DRAFT") {
            patchJobFunction(data2, status);
          }
        }
      } else {
        postJobFunction(data2, status);
      }
    }
  };

  const companyLocation = findCompanyLocation(
    userDetail?.company_details?.city_id
  );

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const filterOptions = (options, state) => {
    let newOptions = [];
    options.forEach((element) => {
      if (
        element.skill_name
          .replace(",", "")
          .toLowerCase()
          .includes(state.inputValue.toLowerCase())
      )
        newOptions.push(element);
    });
    return newOptions;
  };

  const removeDegree = (deg) => {
    if (
      postJobData?.OffCampus?.degree?.length > 0 &&
      postJobData?.OffCampus?.degree?.includes(deg)
    ) {
      let temp = postJobData?.OffCampus?.degree?.filter((d) => d.id !== deg.id);
      setPostJobData({
        ...postJobData,
        OffCampus: {
          ...postJobData.OffCampus,
          degree: temp,
        },
      });
    }
  };

  const removeSep = (deg) => {
    if (
      postJobData?.OffCampus?.degree?.spec?.length > 0 &&
      postJobData?.OffCampus?.degree?.spec?.includes(deg)
    ) {
      let temp = postJobData?.OffCampus?.degree?.spec?.filter(
        (d) => d.id !== deg.id
      );
      setPostJobData({
        ...postJobData,
        OffCampus: {
          ...postJobData.OffCampus,
          degree: temp,
        },
      });
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      postJobData?.Basics?.rounds,
      result.source.index,
      result.destination.index
    );

    setPostJobData({
      ...postJobData,
      Basics: {
        ...postJobData.Basics,
        rounds: reorderedItems,
      },
    });
  };

  const [editLoader, setEditLoader] = useState(false);
  const getJobData = () => {
    setEditLoader(true);

    let jobUrl =
      NIYUKTI_API +
      `job/saas/company/job_post/?job_id=${btoa(job_id.toString())}&prefill=1`; //

    axios
      .get(jobUrl, {
        //headers: { Authorization: `Token ${getCookie("user_token_test")}` },
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success) {
          var jobData = res?.data?.data;

          var arr = [];
          var college_arr = [];
          jobData &&
            jobData?.job_rounds &&
            jobData?.job_rounds?.length > 0 &&
            jobData?.job_rounds?.map((item) => {
              arr.push({
                round_id: item?.round,
                r_name: item?.round_name,
                round_no: item?.round_no,
                venue: item?.venue,
                duration: item?.duration,
                is_interviewed: true,
              });
            });

          jobData &&
            jobData?.college &&
            jobData?.college?.length > 0 &&
            jobData?.college?.map((item) => {
              college_arr.push({
                apply_end_date: item?.apply_end_date,
                apply_start_date: item?.apply_start_date,
                college_id: item?.id,
                preferences: item?.preferences,
                is_approved: item?.is_approved,
                is_rejected: item?.is_rejected,
                college_location: item?.location,
                college_name: item?.name,
              });
            });

          let yearsArr = [];
          jobData &&
            jobData?.eligibility_criteria?.graduation_years?.map((yr) => {
              if (yr) yearsArr.push({ name: yr, value: yr });
            });
          const exp_dict = jobData?.eligibility_criteria?.experience_dict;

          setMinWorkEx(exp.find((item) => item.val === exp_dict?.min));
          setMaxWorkEx(exp.find((item) => item.val === exp_dict?.max));

          setPostJobData({
            ...postJobData,
            Basics: {
              ...postJobData?.Basics,
              job_title: jobData?.job_title,
              job_type: jobData?.job_type,
              ppo: jobData?.ppo ? 1 : 0,
              employment_type: jobData?.employment_type,
              job_duration_start: jobData?.job_duration_start,
              job_duration_end: jobData?.job_duration_end,
              duration: jobData?.job_duration,
              rounds: arr,
              //posted_on_job_lake: jobData.posted_on_job_lake,
              job_segment: jobData?.job_segment ? jobData.job_segment : null,
              stipend: jobData?.stipend ? jobData.stipend : 0,
              is_ipo_job: jobData?.is_ipo_job ? jobData.is_ipo_job : false,
              internship_salary_type: jobData?.internship_salary_type
                ? jobData?.internship_salary_type
                : "UNPAID",
              //plan_id: jobData?.plan_id ? jobData?.plan_id : -1,
            },
            Details: {
              ...postJobData?.Details,
              job_description: jobData?.job_description,
              // raw_job_description: RichTextEditor.createValueFromString(
              //   jobData.job_description,
              //   "html"
              // ),
              job_role: jobData?.job_role,
              job_role_name: jobData?.job_role_name,
              vacancy: parseInt(jobData?.vacancy),
              salary_type: jobData?.salary_type,
              salary_payment_type: jobData?.salary_payment_type,
              ctc_not_disclosed:
                jobData?.ctc_value === "Not Disclosed" ? true : false,
              ctc_min: jobData?.min_ctc,
              ctc_max: jobData?.max_ctc,
              is_equity: jobData?.is_equity,
              equity_min: jobData?.equity_min,
              equity_max: jobData?.equity_max,
              documents: jobData?.documents,
              job_location: jobData?.job_location,
              allow_remote: jobData?.job_location?.length == 0 ? true : false,
              is_service_bond: jobData?.service_bond === 0 ? false : true,
              service_bond: jobData?.service_bond,
              service_bond_description: jobData?.bond_condition,
              work_from_home:
                jobData?.work_from_home && jobData?.job_location?.length > 0
                  ? "HYBRID"
                  : jobData?.work_from_home &&
                    jobData?.job_location?.length === 0
                  ? "WORK FROM HOME"
                  : "WORK FROM OFFICE",
            },
            Preferences: {
              ...postJobData.Preferences,
              eligibility_criteria: {
                graduation_years: yearsArr,
                /*  jobData?.eligibility_criteria?.graduation_years */
                education_levels:
                  jobData?.eligibility_criteria?.education_levels,
                college_preferences:
                  jobData?.eligibility_criteria?.college_preferences,
                degrees: jobData?.eligibility_criteria?.degrees,
                courses: jobData?.eligibility_criteria?.courses,
                courses_rank: jobData?.eligibility_criteria?.courses_rank,
                degrees_rank: jobData?.eligibility_criteria?.degrees_rank,
                skills: jobData?.eligibility_criteria?.skills,
                experience: jobData?.eligibility_criteria?.experience_dict,
                percentage: jobData?.eligibility_criteria?.percentage,
                qualifications: jobData?.eligibility_criteria?.qualifications,
              },
              backlog: false,
            },
            Colleges: {
              ...postJobData?.Colleges,
              hiring_type: jobData?.hiring_type,
              college: college_arr?.length > 0 ? college_arr : [],
              apply_start_date: jobData?.apply_start_date,
              apply_end_date: jobData?.apply_end_date,
            },
            extra1: {
              ...postJobData?.extra1,
              job_type_name: jobData?.job_type_name,
              emp_type_name: jobData?.employment_type_name,
            },
            OffCampus: {
              ...postJobData?.OffCampus,

              specialization: jobData?.eligibility_criteria?.courses,
              degree: jobData?.eligibility_criteria?.degrees,
              flag: null,
            },
            notice_period: jobData?.notice_period,
          });
          setEditLoader(false);
        } else {
          alert.error(res.data.error);
          setEditLoader(false);
        }
      })
      .catch((err) => {
        alert.error("Error");

        setEditLoader(false);
      });
  };
  console.log("cheking here",postPlan);
  useEffect(() => {
    if (job_id) getJobData();
  }, [job_id]);

  return (
    <>
      {showCompleted ? (
        <Complete
          postJobData={postJobData}
          setPostJobData={setPostJobData}
          // handleReset={handleReset}
          initialState={initialState}
          // handleClearForm={handleClearForm}
          // setActiveStep={setActiveStep}
          jobDataAfterPost={jobDataAfterPost}
          setShowCompleted={setShowCompleted}
          setFormCheckBox={setFormCheckBox}
          initialCheckState={initialCheckState}
          setExpanded={setExpanded}
          disablePopUp={postJobLoginFlow}
        />
      ) : (
        <>
          {!postJobLoginFlow && (
            <Box
              sx={{
                [theme.breakpoints.down("sm")]: {},
                padding: "12px",

                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: 0,
              }}
            >
              <Autocomplete
                options={planDropdownList?.length > 0 ? planDropdownList : []}
                getOptionLabel={(option) =>
                  option?.type === "SLOT"
                    ? `${option?.name} (${option?.available})`
                    : option?.type === "PLAN"
                    ? option?.name
                    : ""
                }
                groupBy={(option) => option.type}
                value={postPlan ? postPlan : null}
                onChange={(e, newValue) => {
                  setPostPlan(newValue);
                  setPostPlaError(false);
                }}
                style={{
                  borderRadius: "8px",
                  width: "250px",
                  //backgroundColor: "#D9D9D9",
                }}
                PaperComponent={CustomAutoCompletePaper}
                // renderInput={(params) => (
                //   <CustomTextField
                //     {...params}
                //     variant="outlined"
                //     label="Select a Plan"
                //     size="small"
                //     // sx={{
                //     //     borderRadius: "8px",
                //     //   // backgroundColor: "#D9D9D9",
                //     //   color: "#000",
                //     // }}
                //   />
                // )}
                renderInput={(params) => (
                  <CustomActionChipTextField
                    {...params}
                    size="small"
                    label="Select a Plan *"
                    error={postPlaError}
                    helperText={postPlaError ? "Plan is Required" : ""}
                  />
                )}
              />
            </Box>
          )}
          <Box
            sx={{
              [theme.breakpoints.down("sm")]: {
                pb: 1.5,
              },
            }}
          >
            <Grid
              container
              spacing={3}
              sx={{
                width: "100%",
                margin: 0,
                [theme.breakpoints.only("xs")]: {
                  p: 0,
                },
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  [theme.breakpoints.only("xs")]: {
                    p: 0,
                    mb: 2,
                  },
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 11px 0px #0000001C",
                    p: 0,
                    pt: 2,
                    pb: 2,
                    borderRadius: "8px",
                    [theme.breakpoints.down("sm")]: {
                      boxShadow: "none",
                      borderBottom: "2px solid #D8D8D8",
                      p: 0,
                    },
                    [theme.breakpoints.only("xs")]: {
                      boxShadow: "none",
                      borderBottom: "2px solid #D8D8D8",
                      p: 0,
                      paddingBottom: "12px",
                      borderRadius: "unset",
                    },
                  }}
                >
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: "500",
                              fontFamily: "gilRoy-semibold",
                              color: "#000",
                              [theme.breakpoints.only("xs")]: {
                                fontSize: "18px",
                              },
                            }}
                          >
                            About Job
                          </Typography>
                        </div>
                        {/* <div>
                      <FormControl>
                        <RadioGroup>
                          <FormControlLabel
                            control={
                              <Radio
                                checkedIcon={<CheckCircleRoundedIcon />}
                                sx={{ p: 0 }}
                              />
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </div> */}
                      </div>
                      {formCheckBox.aboutJob && (
                        <IconButton
                          color="primary"
                          edge="end"
                          style={{ marginRight: "10px" }}
                        >
                          <CheckCircleIcon />
                        </IconButton>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        spacing={3}
                        sx={{
                          margin: 0,
                          width: "100%",
                          [theme.breakpoints.only("xs")]: {
                            p: 0,
                          },
                        }}
                      >
                        <Grid item xs={12} sx={{ display: "grid" }}>
                          <CustomTextField
                            size="small"
                            label="Job Title"
                            required
                            value={postJobData?.Basics?.job_title}
                            error={errorValues?.jobTitle ? true : false}
                            helperText={
                              errorValues?.jobTitle ? errorValues?.jobTitle : ""
                            }
                            onChange={(e) => {
                              setPostJobData({
                                ...postJobData,
                                Basics: {
                                  ...postJobData.Basics,
                                  job_title: e.target.value,
                                },
                              });
                              setErrorValues({ ...errorValues, jobTitle: "" });
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "grid", paddingBottom: 0 }}
                        >
                          <Autocomplete
                            //multiple
                            //limitTags={1}
                            id="multiple-limit-tags"
                            options={jobRoleList}
                            value={{
                              id: postJobData?.Details?.job_role,
                              job_segment: postJobData?.Details?.job_segment,
                              job_role_group: null,
                              job_role_group_name: null,
                              job_role_name: postJobData?.Details?.job_role_name
                                ? postJobData?.Details?.job_role_name
                                : "",
                            }}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setPostJobData({
                                  ...postJobData,
                                  Details: {
                                    ...postJobData?.Details,
                                    job_role: newValue?.id,
                                    job_role_name: newValue?.job_role_name,
                                    job_segment: newValue?.job_segment,
                                  },
                                });
                                setErrorValues({ ...errorValues, jobRole: "" });
                              } else {
                                setPostJobData({
                                  ...postJobData,
                                  Details: {
                                    ...postJobData.Details,
                                    job_role: null,
                                    job_role_name: "",
                                  },
                                });
                              }
                            }}
                            getOptionLabel={(option) => option?.job_role_name}
                            //defaultValue={[top100Films[1], top100Films[2], top100Films[1]]}
                            // renderTags={(value, getTagProps) =>
                            //   value?.map((option, index) => (
                            //     <Chip
                            //       variant="outlined"
                            //       color="primary"
                            //       sx={{
                            //         backgroundColor: alpha(theme.palette.primary.main, 0.1),
                            //       }}
                            //       label={option?.job_role_name}
                            //       deleteIcon={<CloseIcon />}
                            //       size="small"
                            //       {...getTagProps({ index })}
                            //     />
                            //   ))
                            // }

                            // renderInput={(params) => (
                            //   <CustomActionChipTextField
                            //     {...params}
                            //     size="small"
                            //     label="Job Role"
                            //   />
                            // )}
                            renderInput={(params) => (
                              <CustomActionChipTextField
                                {...params}
                                size="small"
                                label="Job Role"
                                required
                                error={errorValues?.jobRole ? true : false}
                                helperText={
                                  errorValues?.jobRole
                                    ? errorValues?.jobRole
                                    : ""
                                }
                              />
                            )}
                          />
                        </Grid>
                        {/* {errorValues?.jobRole && (
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "grid", paddingTop: 0 }}
                      >
                        <span
                          style={{
                            color: "#DC3545",
                            fontSize: "12px",
                            marginTop: "4px",
                            marginRight: "14px",
                            marginBottom: 0,
                            marginLeft: "14px",
                          }}
                        >
                          {errorValues?.jobRole}
                        </span>
                      </Grid>
                    )} */}
                        {/* <Grid item xs={2} sx={{ pt: 0, pb: 0.5 }}>
                      <Typography
                        variant="body2"
                        style={{ marginTop: "8.5px" }}
                      >
                        Job Type:
                      </Typography>
                    </Grid> */}
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              [theme.breakpoints.only("xs")]: {
                                flexDirection: "column",
                                alignItems: "flex-start",
                              },
                            }}
                          >
                            <Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  pr: 3,
                                  fontWeight: "500",
                                  fontFamily: "gilRoy-semibold",
                                  [theme.breakpoints.only("xs")]: {
                                    fontSize: "14px",
                                    // mt: 0,
                                    marginBottom: "5px",
                                  },
                                }}
                                required
                              >
                                Job Type *:
                              </Typography>
                            </Box>
                            <Box>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  sx={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                  //value={}
                                  value={postJobData?.Basics?.job_type}
                                  onChange={(e) => {
                                    setPostJobData({
                                      ...postJobData,
                                      Basics: {
                                        ...postJobData.Basics,
                                        job_type: parseInt(e?.target?.value),
                                      },
                                      //extra1: { ...state.extra1, job_type_name: e.job_type },
                                    });
                                  }}
                                >
                                  <FormControlLabel
                                    value={7}
                                    defaultChecked
                                    control={<Radio size="small" />}
                                    label={
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          [theme.breakpoints.only("xs")]: {
                                            fontSize: "14px",
                                          },
                                        }}
                                      >
                                        Full-time
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    value={1}
                                    control={<Radio size="small" />}
                                    label={
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          [theme.breakpoints.only("xs")]: {
                                            fontSize: "14px",
                                          },
                                        }}
                                      >
                                        Internship
                                      </Typography>
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Box>
                        </Grid>
                        {(postJobData?.Basics?.job_type === 7 ||
                          postJobData?.Basics?.job_type === 3 ||
                          (postJobData?.Basics?.job_type === 1 &&
                            postJobData?.Basics?.ppo === 1)) && (
                          <Grid item xs={12} sx={{ pt: 0 }}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  paddingRight: "10px",
                                  fontWeight: "500",
                                  fontFamily: "gilRoy-semibold",
                                  [theme.breakpoints.only("xs")]: {
                                    fontSize: "14px",
                                  },
                                }}
                              >
                                CTC *:
                              </Typography>
                              <FormControlLabel
                                checked={
                                  postJobData.Details.salary_type ===
                                  "NOT DISCLOSED"
                                    ? true
                                    : false
                                }
                                control={<Checkbox />}
                                onChange={(e) => {
                                  setPostJobData({
                                    ...postJobData,
                                    Details: {
                                      ...postJobData.Details,
                                      ctc_not_disclosed: e.target.checked,

                                      salary_type: e.target.checked
                                        ? "NOT DISCLOSED"
                                        : "PAID",
                                    },
                                  });
                                }}
                                label={
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      [theme.breakpoints.only("xs")]: {
                                        fontSize: "14px",
                                      },
                                    }}
                                  >
                                    Not Disclosed{" "}
                                  </Typography>
                                }
                                labelPlacement="end"
                              />
                              <InfoIcon
                                color="primary"
                                sx={{ fontSize: "20px", marginLeft: "-10px" }}
                              />
                            </div>
                          </Grid>
                        )}

                        {postJobData?.Basics?.job_type === 1 && (
                          <>
                            <Grid item xs={12} sm={4} sx={{ display: "grid" }}>
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={data}
                                getOptionLabel={(option) => option?.label}
                                value={postJobData?.Basics?.duration}
                                error={errorValues?.duration ? true : false}
                                helperText={
                                  errorValues?.duration
                                    ? errorValues?.duration
                                    : ""
                                }
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    setPostJobData({
                                      ...postJobData,
                                      Basics: {
                                        ...postJobData.Basics,
                                        duration: newValue,
                                      },
                                    });
                                    setErrorValues({
                                      ...errorValues,
                                      duration: "",
                                    });
                                  }
                                }}
                                popupIcon={<AccessTime />}
                                renderInput={(params) => (
                                  <CustomTextField
                                    {...params}
                                    size="small"
                                    label="Duration *"
                                    error={errorValues?.duration ? true : false}
                                    helperText={
                                      errorValues?.duration
                                        ? errorValues?.duration
                                        : ""
                                    }
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              style={{ display: "grid" }}
                            >
                              <CustomTextField
                                name="numberformat"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                label="Min. Stipend"
                                size="small"
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                }}
                                disabled={
                                  postJobData?.Basics?.internship_unpaid
                                }
                                value={postJobData?.Basics?.stipend}
                                error={errorValues?.minStipend ? true : false}
                                helperText={
                                  errorValues?.minStipend
                                    ? errorValues?.minStipend
                                    : ""
                                }
                                onChange={(e) => {
                                  let val = e.target.value;
                                  setPostJobData({
                                    ...postJobData,
                                    Basics: {
                                      ...postJobData.Basics,
                                      stipend: parseInt(val),
                                      salary_type: val ? "PAID" : "UNPAID",
                                    },
                                  });
                                  setErrorValues({
                                    ...errorValues,
                                    minStipend: "",
                                  });
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                [theme.breakpoints.only("xs")]: {
                                  pt: 0,
                                },
                              }}
                            >
                              <FormControlLabel
                                value="UNPAID"
                                control={
                                  <Checkbox
                                    sx={{
                                      [theme.breakpoints.only("xs")]: {
                                        pt: 0,
                                        pb: 0,
                                      },
                                    }}
                                  />
                                }
                                onChange={(e) => {
                                  setPostJobData({
                                    ...postJobData,
                                    Basics: {
                                      ...postJobData.Basics,
                                      internship_unpaid: e.target.checked,
                                    },
                                  });
                                }}
                                label={
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      [theme.breakpoints.only("xs")]: {
                                        fontSize: "14px",
                                      },
                                    }}
                                  >
                                    Unpaid Internship
                                  </Typography>
                                }
                                labelPlacement="end"
                              />
                            </Grid>
                          </>
                        )}

                        {postJobData?.Basics?.job_type === 1 && (
                          <Grid xs={12} sm={12} md={12}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                [theme.breakpoints.only("xs")]: {
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                },
                              }}
                            >
                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    pr: 3,
                                    fontWeight: "500",
                                    fontFamily: "gilRoy-semibold",
                                    [theme.breakpoints.only("xs")]: {
                                      fontSize: "14px",
                                      // mt: 0,
                                      marginBottom: "5px",
                                    },
                                  }}
                                >
                                  Pre-Placement Offer (PPO):
                                </Typography>
                              </Box>
                              <Box>
                                <FormControl>
                                  <RadioGroup
                                    name="radio-buttons-group"
                                    sx={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FormControlLabel
                                      control={<Radio size="small" />}
                                      onChange={(e) => {
                                        setPostJobData({
                                          ...postJobData,
                                          Basics: {
                                            ...postJobData.Basics,
                                            internship_ppo: true,
                                          },
                                        });
                                      }}
                                      label={
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            [theme.breakpoints.only("xs")]: {
                                              fontSize: "14px",
                                            },
                                          }}
                                        >
                                          Yes{" "}
                                        </Typography>
                                      }
                                      value="yes"
                                    />

                                    <FormControlLabel
                                      control={<Radio size="small" />}
                                      onChange={(e) => {
                                        setPostJobData({
                                          ...postJobData,
                                          Basics: {
                                            ...postJobData.Basics,
                                            internship_ppo: false,
                                          },
                                        });
                                      }}
                                      label={
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            [theme.breakpoints.only("xs")]: {
                                              fontSize: "14px",
                                            },
                                          }}
                                        >
                                          No{" "}
                                        </Typography>
                                      }
                                      value="no"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Box>
                            </Box>
                          </Grid>
                        )}
                        {(postJobData?.Basics?.job_type === 7 ||
                          (postJobData?.Basics?.job_type === 1 &&
                            postJobData?.Basics?.internship_ppo)) && (
                          <>
                            <Grid
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{ pt: 0, pb: 0.8 }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: "500",
                                  fontFamily: "gilRoy-semibold",
                                  [theme.breakpoints.only("xs")]: {
                                    fontSize: "14px",
                                  },
                                }}
                              >
                                {" "}
                                {postJobData?.Basics?.job_type === 1 &&
                                  postJobData?.Basics?.internship_ppo &&
                                  "Offered CTC After PPO"}
                              </Typography>
                            </Grid>
                            <Grid xs={12} sm={4} style={{ display: "grid" }}>
                              <CustomTextField
                                name="numberformat"
                                id="formatted-numberformat-input"
                                size="small"
                                label="Minimum CTC (INR) *"
                                disabled={
                                  postJobData?.Details?.ctc_not_disclosed
                                }
                                variant="outlined"
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                }}
                                // type="number"
                                // InputProps={{
                                //   inputComponent: NumberFormatCustom,
                                //   inputProps: { min: 0 },
                                //   style: { fontSize: 13, height: "40px" },
                                // }}
                                //onWheel={() => document.activeElement.blur()}
                                error={errorValues?.minCtc ? true : false}
                                helperText={
                                  errorValues?.minCtc ? errorValues?.minCtc : ""
                                }
                                value={
                                  postJobData?.Details?.ctc_not_disclosed
                                    ? 0
                                    : postJobData?.Details?.ctc_min /* === 0 */
                                  /*     ? null
                    : postJobData?.Details?.ctc_min */
                                }
                                onChange={(e) => {
                                  setPostJobData({
                                    ...postJobData,
                                    Details: {
                                      ...postJobData?.Details,
                                      ctc_min: parseInt(e?.target?.value),
                                    },
                                  });
                                  setErrorValues({
                                    ...errorValues,
                                    minCtc: "",
                                  });
                                }}
                              />
                            </Grid>
                            <Grid
                              xs={12}
                              sm={4}
                              sx={{
                                display: "grid",
                                [theme.breakpoints.only("xs")]: {
                                  pt: 0,
                                },
                              }}
                            >
                              <CustomTextField
                                size="small"
                                label="Maximum CTC (INR) *"
                                variant="outlined"
                                //type="number"

                                //onWheel={() => document.activeElement.blur()}
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                }}
                                value={
                                  postJobData?.Details?.ctc_not_disclosed
                                    ? 0
                                    : postJobData?.Details?.ctc_max /* === 0 */
                                  /*   ? null
                    : postJobData?.Details?.ctc_max */
                                }
                                onChange={(e) => {
                                  setPostJobData({
                                    ...postJobData,
                                    Details: {
                                      ...postJobData?.Details,
                                      ctc_max: parseFloat(e?.target?.value),
                                    },
                                  });
                                  setErrorValues({
                                    ...errorValues,
                                    maxCtc: "",
                                  });
                                }}
                                disabled={
                                  postJobData?.Details?.ctc_not_disclosed
                                }
                                error={errorValues?.maxCtc ? true : false}
                                helperText={
                                  errorValues?.maxCtc ? errorValues?.maxCtc : ""
                                }
                              />
                            </Grid>

                            <Grid
                              xs={3}
                              sm={2}
                              sx={{
                                display: "grid",
                                [theme.breakpoints.only("xs")]: {
                                  pt: 0,
                                  marginTop: "-5px",
                                },
                              }}
                            >
                              {/* <Autocomplete
                              id="combo-box-demo"
                              options={frequency}
                              value={{
                                title:
                                  postJobData?.Details?.salary_payment_type ===
                                  "PER ANNUM"
                                    ? "Yearly"
                                    : postJobData?.Details
                                        ?.salary_payment_type === "PER MONTH"
                                    ? "Monthly"
                                    : postJobData?.Details
                                        ?.salary_payment_type === "PER HOUR"
                                    ? "Hourly"
                                    : "",
                                val: postJobData?.Details?.salary_payment_type,
                              }}
                              getOptionLabel={(option) => option?.title}
                              //defaultValue={frequency[0]}
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setPostJobData({
                                    ...postJobData,
                                    Details: {
                                      ...postJobData?.Details,
                                      salary_payment_type: newValue?.val,
                                    },
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <CustomTextField
                                  {...params}
                                  size="small"
                                  label="Payment Frequency"
                                />
                              )}
                            /> */}
                              <Box
                                sx={{
                                  backgroundColor: "#F6F6F6",
                                  display: "grid",
                                  boxShadow: "none",
                                  width: "100%",
                                  height: "40px",
                                  borderRadius: "4px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  "&:hover": {
                                    backgroundColor: "#F6F6F6",
                                  },
                                  [theme.breakpoints.only("xs")]: {
                                    height: "35px",
                                  },
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    fontFamily: "gilRoy-semibold",
                                    [theme.breakpoints.only("xs")]: {
                                      fontSize: "12px",
                                    },
                                  }}
                                >
                                  Yearly
                                </Typography>
                              </Box>
                            </Grid>
                          </>
                        )}

                        <Grid item xs={12} sm={4} style={{ display: "grid" }}>
                          <Typography
                            variant="body2"
                            mb={2}
                            sx={{
                              fontWeight: "500",
                              fontFamily: "gilRoy-semibold",
                              [theme.breakpoints.only("xs")]: {
                                fontSize: "14px",
                              },
                            }}
                          >
                            Opening *
                          </Typography>
                          <CustomTextField
                            variant="outlined"
                            label="Vacancy"
                            size="small"
                            type="number"
                            // onWheel={() => document.activeElement.blur()}
                            value={
                              postJobData.Details?.vacancy
                                ? postJobData.Details?.vacancy
                                : ""
                            }
                            error={errorValues?.vacancy ? true : false}
                            helperText={
                              errorValues?.vacancy ? errorValues?.vacancy : ""
                            }
                            onChange={(e) => {
                              setPostJobData({
                                ...postJobData,
                                Details: {
                                  ...postJobData.Details,
                                  vacancy: parseFloat(e.target.value),
                                },
                              });
                              setErrorValues({ ...errorValues, vacancy: "" });
                            }}
                            InputProps={{
                              inputProps: { min: 1 },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <PersonOutlined />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            [theme.breakpoints.only("xs")]: {
                              pt: 0,
                            },
                          }}
                        >
                          <Hidden only={["xs"]}>
                            <Box sx={{ marginTop: "5px" }}>
                              <Editor
                                className="test-1"
                                state={jobDes}
                                setState={setJobDes}
                                handleClickOpen={handleClickOpen}
                                postJobData={postJobData}
                              />
                              {errorValues?.description && (
                                <Typography
                                  variant="body2"
                                  style={{
                                    color: "#DC3545",
                                    fontSize: "12px",
                                    // fontStyle: "italic",
                                    fontWeight: 400,
                                    marginTop: "10px",
                                  }}
                                >
                                  {errorValues?.description}
                                </Typography>
                              )}
                              {/* <Typography
                            variant="body2"
                            style={{
                              color: "gray",
                              fontSize: "12px",
                              fontStyle: "italic",
                              marginTop: "10px",
                            }}
                          >
                            to choose from GetWork's Job description templates
                            available.
                          </Typography> */}
                            </Box>
                          </Hidden>
                          <Hidden only={["sm", "md", "lg", "xl"]}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily: "gilRoy-semibold",
                                  fontWeight: "500",
                                  [theme.breakpoints.only("xs")]: {
                                    fontSize: "14px",
                                  },
                                }}
                              >
                                Job Description
                              </Typography>
                              <MobileJobDes
                                state={jobDes}
                                setState={setJobDes}
                                handleClickOpen={handleClickOpen}
                              />
                            </div>
                          </Hidden>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sx={{
                            [theme.breakpoints.only("xs")]: {
                              pt: 0,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              [theme.breakpoints.only("xs")]: {
                                flexDirection: "column",
                                alignItems: "flex-start",
                              },
                            }}
                          >
                            <Typography
                              varient="body2"
                              sx={{
                                paddingRight: 3,
                                fontWeight: "500",
                                fontFamily: "gilRoy-semibold",
                                [theme.breakpoints.only("xs")]: {
                                  fontSize: "14px",
                                  marginBottom: "5px",
                                },
                              }}
                            >
                              Location type *:
                            </Typography>
                            <FormGroup
                              sx={{
                                [theme.breakpoints.only("xs")]: {
                                  flexWrap: "nowrap",
                                },
                              }}
                            >
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                sx={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                                value={
                                  postJobData?.Details?.work_from_home ?? ""
                                  // postJobData?.Details?.work_from_home === "WORK FROM OFFICE"
                                  //   ? "WORK FROM OFFICE"
                                  //   : postJobData?.Details?.work_from_home === "WORK FROM HOME"
                                  //   ? "WORK FROM HOME"
                                  //   : postJobData?.Details?.work_from_home === "HYBRID"
                                  //   ? "HYBRID"
                                  //   : ""
                                }
                                onChange={(e) => {
                                  setPostJobData({
                                    ...postJobData,
                                    Details: {
                                      ...postJobData.Details,
                                      work_from_home: e.target.value,
                                    },
                                  });
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        [theme.breakpoints.only("xs")]: {
                                          paddingLeft: "8px",
                                        },
                                      }}
                                    />
                                  }
                                  value="WORK FROM OFFICE"
                                  label={
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        [theme.breakpoints.only("xs")]: {
                                          fontSize: "14px",
                                          mr: 0,
                                        },
                                      }}
                                    >
                                      Work From Office{" "}
                                    </Typography>
                                  }
                                />

                                <FormControlLabel
                                  control={<Radio size="small" />}
                                  value="WORK FROM HOME"
                                  label={
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        [theme.breakpoints.only("xs")]: {
                                          fontSize: "14px",
                                          mr: 0,
                                        },
                                      }}
                                    >
                                      Work From Home
                                    </Typography>
                                  }
                                />

                                <FormControlLabel
                                  control={<Radio size="small" />}
                                  value="HYBRID"
                                  label={
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        [theme.breakpoints.only("xs")]: {
                                          fontSize: "14px",
                                          mr: 0,
                                        },
                                      }}
                                    >
                                      Hybrid
                                    </Typography>
                                  }
                                />
                                <Hidden only={["xs"]}>
                                  <Tooltip title="Hybrid: Candidate will have flexibility between work from office and work from home">
                                    <InfoIcon
                                      color="primary"
                                      sx={{
                                        fontSize: "20px",
                                        marginLeft: "-5px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Tooltip>
                                </Hidden>
                              </RadioGroup>
                            </FormGroup>
                          </Box>
                        </Grid>

                        {postJobData?.Details?.work_from_home &&
                          postJobData?.Details?.work_from_home !==
                            "WORK FROM HOME" && (
                            <Grid item xs={12} style={{ display: "grid" }}>
                              <Autocomplete
                                multiple
                                limitTags={2}
                                id="tags-outlined"
                                value={
                                  postJobData?.Details?.job_location
                                    ? postJobData?.Details?.job_location
                                    : []
                                }
                                onChange={(e, i) => {
                                  let uniqueObjArray = [
                                    ...new Map(
                                      i?.map((item) => [item["city_id"], item])
                                    ).values(),
                                  ];

                                  setPostJobData({
                                    ...postJobData,
                                    Details: {
                                      ...postJobData?.Details,
                                      job_location: uniqueObjArray,
                                    },
                                  });
                                  setErrorValues({
                                    ...errorValues,
                                    location: "",
                                  });
                                  // }
                                }}
                                options={location?.length > 0 ? location : []}
                                getOptionLabel={(option) =>
                                  `${option?.city_id}-${option.city}, ${option.state}`
                                }
                                renderTags={(tagValue, getTagProps) =>
                                  tagValue.map((option, index) => (
                                    <Chip
                                      color="primary"
                                      variant="outlined"
                                      deleteIcon={<CloseIcon />}
                                      size="small"
                                      sx={{
                                        backgroundColor: alpha(
                                          theme.palette.primary.main,
                                          0.1
                                        ),
                                      }}
                                      label={`${option.city}, ${option.state}`}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                filterSelectedOptions={true}
                                renderInput={(params) => (
                                  <CustomActionChipTextField
                                    {...params}
                                    size="small"
                                    label="Location *"
                                    error={errorValues?.location ? true : false}
                                    helperText={
                                      errorValues?.location
                                        ? errorValues?.location
                                        : ""
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          )}

                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              mt: 3,
                            }}
                          >
                            <Button
                              onClick={() => {
                                setPostJobData({
                                  ...postJobData,
                                  Basics: {
                                    ...postJobData?.Basics,
                                    job_title: "",
                                    job_type: 7,
                                    duration: null,
                                    stipend: 0,
                                    internship_ppo: false,
                                    internship_unpaid: false,
                                  },
                                  Details: {
                                    ...postJobData?.Details,
                                    ctc_not_disclosed: false,
                                    vacancy: null,
                                    ctc_min: 0,
                                    ctc_max: 0,
                                    job_location: [],
                                    work_from_home: "WORK FROM OFFICE",
                                    job_description: "",
                                    job_role_name: "",
                                    job_role: null,
                                    job_segment: null,
                                  },
                                });
                                setFormCheckBox({
                                  ...formCheckBox,
                                  aboutJob: false,
                                });
                                setJobDes("");
                              }}
                              variant="outlined"
                              color="primary"
                              sx={{
                                boxShadow: "none",
                                height: "40px",
                                width: "150px",
                                borderWidth: "1.33px",
                                [theme.breakpoints.only("xs")]: {
                                  //height: "35px",
                                  width: "auto",
                                  fontSize: "12px",
                                },
                              }}
                            >
                              Reset
                            </Button>
                            <Button
                              onClick={() => {
                                if (validateForm(1)) {
                                  setFormOne(true);
                                  setExpanded("panel2");
                                  setFormCheckBox({
                                    ...formCheckBox,
                                    aboutJob: true,
                                  });
                                }
                              }}
                              variant="contained"
                              sx={{
                                boxShadow: "none",
                                height: "40px",
                                width: "150px",
                                backgroundColor: "#33AA28",
                                color: "#fff",
                                marginLeft: "10px",
                                [theme.breakpoints.only("xs")]: {
                                  //height: "35px",
                                  width: "auto",
                                  fontSize: "12px",
                                },
                              }}
                            >
                              Save & Update
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  [theme.breakpoints.only("xs")]: {
                    p: 0,
                    mb: 2,
                  },
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 11px 0px #0000001C",
                    p: 0,
                    pt: 2,
                    pb: 2,
                    borderRadius: "8px",
                    [theme.breakpoints.down("sm")]: {
                      boxShadow: "none",
                      borderBottom: "2px solid #D8D8D8",
                      // boxShadow: "none",
                      p: 0,
                    },
                    [theme.breakpoints.only("xs")]: {
                      boxShadow: "none",
                      borderBottom: "2px solid #D8D8D8",
                      p: 0,
                      paddingBottom: "12px",
                      borderRadius: "unset",
                    },
                  }}
                >
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: "500",
                              fontFamily: "gilRoy-semibold",
                              color: "#000",
                              [theme.breakpoints.only("xs")]: {
                                fontSize: "18px",
                              },
                            }}
                          >
                            Eligibility Criteria
                          </Typography>
                        </div>

                        {formCheckBox.eligiblityCriteria && (
                          <IconButton
                            color="primary"
                            edge="end"
                            style={{ marginRight: "10px" }}
                          >
                            <CheckCircleIcon />
                          </IconButton>
                        )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        spacing={3}
                        sx={{
                          margin: 0,
                          width: "100%",
                          [theme.breakpoints.only("xs")]: {
                            p: 0,
                          },
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            varient="body2"
                            sx={{
                              fontFamily: "gilRoy-semibold",
                              fontWeight: "500",
                              [theme.breakpoints.only("xs")]: {
                                fontSize: "14px",
                              },
                            }}
                          >
                            Add Skills
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "grid" }}>
                          <Autocomplete
                            freeSolo
                            multiple
                            limitTags={5}
                            id="multiple-limit-tags"
                            options={allSkills}
                            getOptionLabel={(option) =>
                              `${option?.skill_id}-${option?.skill_name}`
                            }
                            //defaultValue={[top100Films[1], top100Films[2], top100Films[1]]}
                            value={
                              postJobData?.Preferences?.eligibility_criteria
                                ?.skills
                            }
                            onInputChange={(e, newInputValue) => {
                              debounceSkillList(newInputValue);
                            }}
                            onChange={(e, item, reason) => {
                              if (
                                reason === "selectOption" ||
                                reason === "createOption"
                              ) {
                                const lastObject = item[item.length - 1];
                                if (
                                  item
                                    .slice(0, -1)
                                    .some(
                                      (item) =>
                                        item.skill_name ==
                                        (lastObject?.skill_name || lastObject)
                                    )
                                ) {
                                  alert.error("Added Skill already present.");
                                  return;
                                }
                              }
                              const skills = [];
                              item.map((skill) => {
                                if (typeof skill === "string") {
                                  const customSkill = {
                                    skill_id: -1,
                                    skill_name: skill,
                                    skill_type: null,
                                    type: null,
                                    user_created: true,
                                  };
                                  skills.push(customSkill);
                                } else {
                                  skills.push(skill);
                                }
                              });

                              setPostJobData({
                                ...postJobData,
                                Preferences: {
                                  ...postJobData.Preferences,
                                  eligibility_criteria: {
                                    ...postJobData?.Preferences
                                      ?.eligibility_criteria,
                                    skills: skills,
                                  },
                                },
                              });

                              setErrorValues({ ...errorValues, skills: "" });
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  color="primary"
                                  sx={{
                                    backgroundColor: alpha(
                                      theme.palette.primary.main,
                                      0.1
                                    ),
                                  }}
                                  label={option?.skill_name}
                                  deleteIcon={<Close />}
                                  size="small"
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <CustomActionChipTextField
                                {...params}
                                size="small"
                                label="Skills *"
                                placeholder="Add More Skills"
                                error={errorValues?.skills ? true : false}
                                helperText={
                                  errorValues?.skills ? errorValues?.skills : ""
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            varient="body2"
                            sx={{
                              fontFamily: "gilRoy-semibold",
                              fontWeight: "500",
                              [theme.breakpoints.only("xs")]: {
                                fontSize: "14px",
                              },
                            }}
                          >
                            Work Experience
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={exp}
                            getOptionLabel={(option) => option?.title}
                            value={minWorkEx ? minWorkEx : null}
                            onChange={(e, newValue) => {
                              setMinWorkEx(newValue);
                              setErrorValues({ ...errorValues, minWorkEx: "" });
                            }}
                            style={{
                              background: "#fff",
                              borderRadius: "8px",
                            }}
                            PaperComponent={CustomAutoCompletePaper}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                variant="outlined"
                                label="Min Work Exp *"
                                size="small"
                                error={errorValues?.minWorkEx ? true : false}
                                helperText={
                                  errorValues?.minWorkEx
                                    ? errorValues?.minWorkEx
                                    : ""
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Autocomplete
                            options={exp}
                            getOptionLabel={(option) => option?.title}
                            value={maxWorkEx ? maxWorkEx : null}
                            onChange={(e, newValue) => {
                              setMaxWorkEx(newValue);
                              setErrorValues({ ...errorValues, maxWorkEx: "" });
                            }}
                            PaperComponent={CustomAutoCompletePaper}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                variant="outlined"
                                label="Max Work Exp *"
                                size="small"
                                error={errorValues?.maxWorkEx ? true : false}
                                helperText={
                                  errorValues?.maxWorkEx
                                    ? errorValues?.maxWorkEx
                                    : ""
                                }
                              />
                            )}
                          />
                        </Grid>
                        {/* <Hidden only={["xs"]}> */}
                        <Grid item xs={12}>
                          <Typography
                            varient="body2"
                            sx={{
                              fontFamily: "gilRoy-semibold",
                              fontWeight: "500",
                              [theme.breakpoints.only("xs")]: {
                                fontSize: "14px",
                              },
                            }}
                          >
                            Notice Period
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} style={{ display: "grid" }}>
                          <CustomTextField
                            variant="outlined"
                            label="Notice Period(days) *"
                            size="small"
                            type="number"
                            // onWheel={() => document.activeElement.blur()}
                            value={
                              postJobData?.notice_period
                                ? postJobData?.notice_period
                                : ""
                            }
                            error={errorValues?.notice_period ? true : false}
                            helperText={
                              errorValues?.notice_period
                                ? errorValues?.notice_period
                                : ""
                            }
                            onChange={(e) => {
                              setPostJobData({
                                ...postJobData,

                                notice_period: parseFloat(e.target.value),
                              });
                              setErrorValues({
                                ...errorValues,
                                notice_period: "",
                              });
                            }}
                            InputProps={{
                              inputProps: { min: 1 },
                              // endAdornment: (
                              //   <InputAdornment position="end"></InputAdornment>
                              // ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            varient="body2"
                            sx={{
                              fontFamily: "gilRoy-semibold",
                              fontWeight: "500",
                              [theme.breakpoints.only("xs")]: {
                                fontSize: "14px",
                              },
                            }}
                          >
                            Applicant’s Qualification(s) and Minimum required
                            Percentage
                          </Typography>
                        </Grid>
                        {postJobData?.Preferences?.eligibility_criteria
                          ?.qualifications?.length > 0 && (
                          <>
                            <Grid item xs={12} sx={{ pb: 0, pt: 0 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  flexWrap: "wrap",
                                }}
                              >
                                {postJobData?.Preferences?.eligibility_criteria
                                  ?.qualifications?.length > 0 &&
                                  postJobData?.Preferences?.eligibility_criteria?.qualifications?.map(
                                    (qualification, index) => (
                                      <Box
                                        sx={{
                                          marginRight: 1,
                                          mb: 1,
                                        }}
                                      >
                                        <SmallQualifications
                                          qualification={qualification}
                                          index={index}
                                          totalLength={
                                            postJobData?.Preferences
                                              ?.eligibility_criteria
                                              ?.qualifications?.length
                                          }
                                          deleteQualification={
                                            deleteQualification
                                          }
                                        />
                                      </Box>
                                    )
                                  )}
                              </Box>
                            </Grid>
                          </>
                        )}
                        {/* </Hidden> */}
                        <Grid item xs={6} sm={4} style={{ display: "grid" }}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={educationLevelTypes}
                            getOptionLabel={(option) => option?.name}
                            value={edulevel}
                            onChange={(e, newValue) => {
                              if (newValue) setEdulevel(newValue);
                            }}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                size="small"
                                label="Level of Education *"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4} style={{ display: "grid" }}>
                          <CustomTextField
                            size="small"
                            label="Minimum Percentage *"
                            variant="outlined"
                            type="number"
                            onWheel={() => document.activeElement.blur()}
                            value={parseFloat(percentage)} //postJobData?.Preferences?.eligibility_criteria?.percentage
                            onChange={(e) => {
                              if (Number(e.target.value) > 100) {
                                setPercentage(100);
                              } else {
                                setPercentage(e.target.value);
                              }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4} sx={{ display: "grid" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              borderRadius: "8px",
                              boxShadow: "none",
                              height: "40px",
                              fontSize: "14px",
                            }}
                            onClick={handleAddQualification}
                          >
                            {postJobData?.Preferences?.eligibility_criteria
                              ?.qualifications?.length > 0
                              ? "Add More Qualification"
                              : "Add Qualification"}
                          </Button>
                          {errorValues?.qualifications && (
                            <Typography
                              variant="body2"
                              sx={{
                                color: "#DC3545",
                                fontSize: "12px",

                                // fontStyle: "italic",
                                fontWeight: 400,
                                marginTop: "8px",
                                marginBottom: -2,
                                marginLeft: "15px",
                                [theme.breakpoints.only("xs")]: {
                                  fontSize: "14px",
                                },
                              }}
                            >
                              {errorValues?.qualifications}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            multiple
                            limitTags={2}
                            id="multiple-limit-tags"
                            options={year}
                            getOptionLabel={(option) => option?.value}
                            //defaultValue={[year[1], year[2], year[1]]}
                            value={
                              postJobData?.Preferences?.eligibility_criteria
                                ?.graduation_years
                            }
                            onChange={(e, i) => {
                              var arr = uniqueArray(i);
                              setPostJobData({
                                ...postJobData,
                                Preferences: {
                                  ...postJobData.Preferences,
                                  eligibility_criteria: {
                                    ...postJobData.Preferences
                                      .eligibility_criteria,
                                    graduation_years: arr,
                                  },
                                },
                              });
                              setErrorValues({
                                ...errorValues,
                                passingYears: "",
                              });
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  color="primary"
                                  sx={{
                                    backgroundColor: alpha(
                                      theme.palette.primary.main,
                                      0.1
                                    ),
                                  }}
                                  label={option?.value}
                                  deleteIcon={<Close />}
                                  size="small"
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            style={{
                              background: "#fff",
                              borderRadius: "8px",
                            }}
                            PaperComponent={CustomAutoCompletePaper}
                            renderInput={(params) => (
                              <CustomActionChipTextField
                                {...params}
                                variant="outlined"
                                label="Eligible Graduation Years *"
                                size="small"
                                error={errorValues?.passingYears ? true : false}
                                helperText={
                                  errorValues?.passingYears
                                    ? errorValues?.passingYears
                                    : ""
                                }
                              />
                            )}
                          />
                        </Grid>{" "}
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              mt: 3,
                            }}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                setPostJobData({
                                  ...postJobData,
                                  Preferences: {
                                    ...postJobData.Preferences,
                                    eligibility_criteria: {
                                      ...postJobData?.Preferences
                                        ?.eligibility_criteria,
                                      skills: [],
                                      experience: [],
                                      qualifications: [],
                                      graduation_years: [],
                                    },
                                  },
                                });
                                setFormCheckBox({
                                  ...formCheckBox,
                                  eligiblityCriteria: false,
                                });
                                setMinWorkEx(null);
                                setMaxWorkEx(null);
                                setPercentage("");
                              }}
                              sx={{
                                boxShadow: "none",
                                height: "40px",
                                width: "150px",
                                borderWidth: "1.33px",
                                [theme.breakpoints.only("xs")]: {
                                  //height: "35px",
                                  width: "auto",
                                  fontSize: "12px",
                                },
                              }}
                            >
                              Reset
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => {
                                if (validateForm(2)) {
                                  setFormTwo(true);
                                  setExpanded("panel3");
                                  setFormCheckBox({
                                    ...formCheckBox,
                                    eligiblityCriteria: true,
                                  });
                                }
                              }}
                              sx={{
                                boxShadow: "none",
                                height: "40px",
                                width: "150px",
                                backgroundColor: "#33AA28",
                                color: "#fff",
                                marginLeft: "10px",
                                [theme.breakpoints.only("xs")]: {
                                  // height: "35px",
                                  width: "auto",
                                  fontSize: "12px",
                                },
                              }}
                            >
                              Save & Update
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  [theme.breakpoints.only("xs")]: {
                    p: 0,
                    mb: 2,
                  },
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 11px 0px #0000001C",
                    p: 0,
                    pt: 2,
                    pb: 2,
                    borderRadius: "8px",
                    [theme.breakpoints.down("sm")]: {
                      boxShadow: "none",
                      borderBottom: "2px solid #D8D8D8",
                      // boxShadow: "none",
                      p: 0,
                    },
                    [theme.breakpoints.only("xs")]: {
                      boxShadow: "none",
                      borderBottom: "2px solid #D8D8D8",
                      p: 0,
                      paddingBottom: "12px",
                      borderRadius: "unset",
                    },
                  }}
                >
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: "500",
                              fontFamily: "gilRoy-semibold",
                              color: "#000",
                              [theme.breakpoints.only("xs")]: {
                                fontSize: "18px",
                              },
                            }}
                          >
                            Selection Procedure
                          </Typography>
                        </div>

                        {formCheckBox.selectionProcedure && (
                          <IconButton
                            color="primary"
                            edge="end"
                            style={{ marginRight: "10px" }}
                          >
                            <CheckCircleIcon />
                          </IconButton>
                        )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        spacing={3}
                        sx={{
                          margin: 0,
                          width: "100%",
                          [theme.breakpoints.only("xs")]: {
                            p: 0,
                          },
                        }}
                      >
                        <Grid item xs={12} sm={4} sx={{ display: "grid" }}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                              label="Application Start Date *"
                              disablePast
                              value={
                                postJobData?.Colleges?.apply_start_date
                                  ? postJobData?.Colleges?.apply_start_date
                                  : null
                              }
                              onChange={(date) => {
                                if (date) {
                                  setPostJobData({
                                    ...postJobData,
                                    Colleges: {
                                      ...postJobData.Colleges,
                                      apply_start_date:
                                        moment(date).format("YYYY-MM-DD"),
                                      apply_end_date: postJobData?.Colleges
                                        ?.apply_end_date
                                        ? postJobData?.Colleges?.apply_end_date
                                        : null,
                                    },
                                  });
                                  setErrorValues({
                                    ...errorValues,
                                    startDate: "",
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <CustomTextField
                                  size="small"
                                  {...params}
                                  error={errorValues?.startDate ? true : false}
                                  helperText={
                                    errorValues?.startDate
                                      ? errorValues?.startDate
                                      : ""
                                  }
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{ display: "grid" }}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                              label="Application End Date *"
                              disablePast
                              value={
                                postJobData?.Colleges?.apply_end_date
                                  ? postJobData?.Colleges?.apply_end_date
                                  : null
                              }
                              onChange={(date) => {
                                if (date) {
                                  setPostJobData({
                                    ...postJobData,
                                    Colleges: {
                                      ...postJobData.Colleges,
                                      apply_end_date:
                                        moment(date).format("YYYY-MM-DD"),
                                      apply_start_date: postJobData?.Colleges
                                        ?.apply_start_date
                                        ? postJobData?.Colleges
                                            ?.apply_start_date
                                        : null,
                                    },
                                  });
                                  setErrorValues({
                                    ...errorValues,
                                    endDate: "",
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <CustomTextField
                                  size="small"
                                  {...params}
                                  error={errorValues?.endDate ? true : false}
                                  helperText={
                                    errorValues?.endDate
                                      ? errorValues?.endDate
                                      : ""
                                  }
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{ display: "grid" }}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                              label="Tentative Joining Date *"
                              disablePast
                              value={
                                postJobData.Basics?.job_duration_start
                                  ? postJobData.Basics?.job_duration_start
                                  : null
                              }
                              onChange={(date) => {
                                if (date) {
                                  setPostJobData({
                                    ...postJobData,
                                    Basics: {
                                      ...postJobData.Basics,
                                      job_duration_start:
                                        moment(date).format("YYYY-MM-DD"),
                                    },
                                  });
                                  setErrorValues({
                                    ...errorValues,
                                    joiningDate: "",
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <CustomTextField
                                  size="small"
                                  {...params}
                                  error={
                                    errorValues?.joiningDate ? true : false
                                  }
                                  helperText={
                                    errorValues?.joiningDate
                                      ? errorValues?.joiningDate
                                      : ""
                                  }
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              [theme.breakpoints.only("xs")]: {
                                flexDirection: "column",
                                alignItems: "flex-start",
                              },
                            }}
                          >
                            <Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  pr: 3,
                                  fontFamily: "gilRoy-semibold",
                                  fontWeight: "500",
                                  [theme.breakpoints.only("xs")]: {
                                    fontSize: "14px",
                                  },
                                }}
                              >
                                Hiring Type *:
                              </Typography>
                              {errorValues?.hiringType && (
                                <Typography
                                  variant="body2"
                                  style={{
                                    color: "#DC3545",
                                    fontSize: "12px",
                                    // fontStyle: "italic",
                                    fontWeight: 400,
                                  }}
                                >
                                  {errorValues?.hiringType}
                                </Typography>
                              )}
                            </Box>

                            <Box>
                              <FormControl>
                                <RadioGroup
                                  sx={{ flexDirection: "row" }}
                                  name="radio-buttons-group"
                                  // sx={{ flexDirection: "row", alignItems: "center" }}
                                  value={postJobData?.Colleges?.hiring_type}
                                  onChange={(e) => {
                                    if (e?.target?.value === "ON CAMPUS") {
                                      setPostJobData({
                                        ...postJobData,
                                        Colleges: {
                                          ...postJobData?.Colleges,
                                          hiring_type: e?.target?.value,
                                          college: [],
                                        },
                                        Basics: {
                                          ...postJobData?.Basics,
                                          is_ipo_job:
                                            e.target.value === "ON CAMPUS"
                                              ? false
                                              : true,
                                        },
                                      });
                                    } else if (
                                      e?.target?.value === "OFF CAMPUS"
                                    ) {
                                      setPostJobData({
                                        ...postJobData,
                                        Colleges: {
                                          ...postJobData?.Colleges,
                                          hiring_type: e?.target?.value,
                                        },
                                        OffCampus: {
                                          ...postJobData?.OffCampus,
                                          degree: [],
                                          specialization: [],
                                        },
                                        Preferences: {
                                          ...postJobData?.Preferences,
                                          eligibility_criteria: {
                                            ...postJobData?.Preferences
                                              ?.eligibility_criteria,
                                            courses: [],
                                            degrees: [],
                                          },
                                        },
                                        Basics: {
                                          ...postJobData?.Basics,
                                          is_ipo_job:
                                            e.target.value === "ON CAMPUS"
                                              ? false
                                              : true,
                                        },
                                      });
                                    }
                                    setErrorValues({
                                      ...errorValues,
                                      hiringType: "",
                                    }); //coursesCollges
                                    setErrorValues({
                                      ...errorValues,
                                      coursesCollges: "",
                                    });
                                  }}
                                >
                                  <FormControlLabel
                                    value="ON CAMPUS"
                                    control={<Radio />}
                                    label={
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          [theme.breakpoints.only("xs")]: {
                                            fontSize: "14px",
                                          },
                                        }}
                                      >
                                        Campus Hiring
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    value="OFF CAMPUS"
                                    control={<Radio />}
                                    label={
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          [theme.breakpoints.only("xs")]: {
                                            fontSize: "14px",
                                          },
                                        }}
                                      >
                                        Open To All
                                      </Typography>
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Box>
                        </Grid>

                        {postJobData?.Colleges?.hiring_type === "OFF CAMPUS" &&
                          postJobData?.OffCampus?.degree?.length > 0 &&
                          postJobData?.OffCampus?.specialization?.length >
                            0 && (
                            <Grid item xs={12} sx={{ pt: 0 }}>
                              <Box
                                sx={{
                                  boxShadow: "0px 4px 10px 0px #0000001C",

                                  border: "1px solid #DADADA",
                                  p: 1.5,
                                  borderRadius: "6px",
                                }}
                              >
                                <Paper
                                  sx={{
                                    boxShadow: "none",
                                    // boxShadow: "0px 0px 4px 0px #00000040",
                                    // borderRadius: "8px",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    flexWrap: "wrap",
                                    listStyle: "none",
                                    // p: 1.5,
                                    // mt: -1,
                                    m: 0,
                                    mb: 0,
                                    pl: 0,
                                  }}
                                  component="ul"
                                >
                                  {postJobData?.OffCampus?.degree[0]
                                    ?.all_degree_selected ? (
                                    <li>
                                      {" "}
                                      <Chiplist
                                        key={
                                          postJobData?.OffCampus?.degree[0]?.id
                                        }
                                      >
                                        <Chip
                                          size="small"
                                          color="primary"
                                          variant="outlined"
                                          sx={{
                                            backgroundColor: alpha(
                                              theme.palette.primary.main,
                                              0.1
                                            ),
                                          }}
                                          label={`All Courses Selected`}
                                          // deleteIcon={<CloseIcon />}
                                          // onDelete={() => {
                                          //   removeCollege(clg);
                                          // }}
                                        />
                                      </Chiplist>
                                    </li>
                                  ) : (
                                    postJobData?.OffCampus?.degree?.map((deg) =>
                                      deg?.all_spec_selected ? (
                                        <li>
                                          {" "}
                                          <Chiplist key={deg?.id}>
                                            <Chip
                                              size="small"
                                              color="primary"
                                              variant="outlined"
                                              sx={{
                                                backgroundColor: alpha(
                                                  theme.palette.primary.main,
                                                  0.1
                                                ),
                                              }}
                                              label={`${deg?.name} - All Branches`}
                                              // deleteIcon={<CloseIcon />}
                                              // onDelete={() => {
                                              //   removeDegree(deg);
                                              // }}
                                            />
                                          </Chiplist>
                                        </li>
                                      ) : (
                                        deg?.spec?.map((sp, idx) => (
                                          <li>
                                            {" "}
                                            <Chiplist key={deg?.id}>
                                              <Chip
                                                size="small"
                                                color="primary"
                                                variant="outlined"
                                                sx={{
                                                  backgroundColor: alpha(
                                                    theme.palette.primary.main,
                                                    0.1
                                                  ),
                                                }}
                                                label={`${deg?.name} - ${sp?.name}`}
                                                // deleteIcon={<CloseIcon />}
                                                // onDelete={() => {
                                                //   removeSep(sp);
                                                // }}
                                              />
                                            </Chiplist>
                                          </li>
                                        ))
                                      )
                                    )
                                  )}
                                </Paper>
                              </Box>
                            </Grid>
                          )}
                        {postJobData?.Colleges?.hiring_type === "OFF CAMPUS" ? (
                          <Grid item xs={12} sx={{ pt: 0 }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              <OpenToAll
                                postJobData={postJobData}
                                setPostJobData={setPostJobData}
                                degree={degree}
                                setDegree={setDegree}
                                specialization={specialization}
                                specializationLoader={specializationLoader}
                                setSpecialization={setSpecialization}
                                handleSelectAllCourses={handleSelectAllCourses}
                                handleSelectAllSpecialization1={
                                  handleSelectAllSpecialization1
                                }
                                handleSelectSingleSpecialization1={
                                  handleSelectSingleSpecialization1
                                }
                                renderChips={renderChips}
                              />
                              <Typography
                                variant="body2"
                                sx={{
                                  textAlign: "left",
                                  marginTop: "8px",
                                  fontSize: "10px",
                                  color: "gray",
                                }}
                              >
                                Click the button to choose Courses (Optional)
                              </Typography>
                              {errorValues?.coursesCollges && (
                                <Typography
                                  variant="body2"
                                  style={{
                                    color: "#DC3545",
                                    fontSize: "10px",
                                    fontWeight: 400,
                                    marginTop: "8px",
                                  }}
                                >
                                  {errorValues?.coursesCollges}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        ) : (
                          postJobData?.Colleges?.hiring_type ===
                            "ON CAMPUS" && (
                            <>
                              <Grid item xs={12} sx={{ pt: 0, pb: 0 }}>
                                {postJobData?.Colleges?.college?.length > 0 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography
                                      varient="body2"
                                      sx={{
                                        display: "flex",
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      Institutes
                                      {postJobData?.Colleges?.college?.length >
                                      0
                                        ? `(${postJobData?.Colleges?.college?.length})`
                                        : ``}
                                    </Typography>

                                    <Button
                                      variant="text"
                                      color="primary"
                                      sx={{
                                        [theme.breakpoints.only("xs")]: {
                                          fontSize: "14px",
                                        },
                                      }}
                                    >
                                      View All
                                    </Button>
                                  </div>
                                )}
                              </Grid>
                              {!postJobData?.college_id_list?.checkAll &&
                              postJobData?.Colleges?.college?.length > 0 ? (
                                postJobData?.Colleges?.college?.map((clg) => (
                                  <>
                                    <Grid item xs={12} sx={{ pt: 0 }}>
                                      <Institutes
                                        postJobData={postJobData}
                                        setPostJobData={setPostJobData}
                                        clg={clg}
                                        openEdit={openEdit}
                                        setOpenEdit={setOpenEdit}
                                      />
                                    </Grid>
                                  </>
                                ))
                              ) : (
                                <>
                                  {postJobData?.college_id_list?.checkAll && (
                                    <Grid item xs={12} sx={{ pt: 0 }}>
                                      <Card
                                        sx={{
                                          boxShadow:
                                            "0px 0px 4px 0px #00000040",
                                        }}
                                      >
                                        <Box>
                                          <Grid container spacing={2}>
                                            <Grid item>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  width: 87,
                                                  height: 62,
                                                  borderRadius: "8px",
                                                  border: "1px solid #B0BEC5",
                                                  [theme.breakpoints.only(
                                                    "xs"
                                                  )]: {
                                                    width: 55,
                                                    height: 55,
                                                  },
                                                }}
                                              >
                                                <img
                                                  style={{
                                                    margin: "auto",
                                                    //  display: "block",
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                  }}
                                                  alt="complex"
                                                  src={collegeLogo}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              // xs={12}
                                              xs
                                              sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                              }}
                                              sm
                                              container
                                            >
                                              <Grid
                                                item
                                                xs
                                                container
                                                direction="column"
                                                spacing={2}
                                                style={{ display: "contents" }}
                                              >
                                                <div
                                                  style={{
                                                    padding: "5px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-start",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <Typography variant="h6">
                                                    {"All Institutes"}
                                                  </Typography>
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      marginTop: "10px",
                                                      // marginBottom: "10px",
                                                      flexDirection: "row",
                                                      alignItems: "center",
                                                      width: "100%",
                                                      [theme.breakpoints.only(
                                                        "xs"
                                                      )]: {
                                                        mb: 0,
                                                      },
                                                    }}
                                                  >
                                                    <Typography
                                                      variant="body2"
                                                      sx={{
                                                        color: "#6C757D",
                                                        width: "50%",
                                                        // ml: 2,
                                                        [theme.breakpoints.only(
                                                          "xs"
                                                        )]: {
                                                          fontSize: "14px",
                                                        },
                                                      }}
                                                    >
                                                      Start Date: NA
                                                    </Typography>

                                                    <Typography
                                                      variant="body2"
                                                      sx={{
                                                        color: "#6C757D",
                                                        width: "50%",
                                                        [theme.breakpoints.only(
                                                          "xs"
                                                        )]: {
                                                          fontSize: "14px",
                                                        },
                                                      }}
                                                    >
                                                      End Date: NA
                                                    </Typography>
                                                  </Box>
                                                </div>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Card>
                                    </Grid>
                                  )}
                                </>
                              )}

                              <Grid
                                item
                                xs={12}
                                // sx={{
                                //   pt: 0,
                                //   // pb: 0,
                                // }}
                                sx={{ pt: 0 }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <SelectCourseDialog
                                    postJobData={postJobData}
                                    setPostJobData={setPostJobData}
                                    setAllCities={setAllCities}
                                    allCities={allCities}
                                    errorValues={errorValues}
                                    setErrorValues={setErrorValues}
                                    openEdit={openEdit}
                                    setOpenEdit={setOpenEdit}
                                  />
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      textAlign: "left",
                                      marginTop: "8px",
                                      fontSize: "10px",
                                      color: "gray",
                                    }}
                                  >
                                    Click the button to choose Colleges
                                    (Optional)
                                  </Typography>
                                  {errorValues?.coursesCollges && (
                                    <Typography
                                      variant="body2"
                                      style={{
                                        color: "#DC3545",
                                        fontSize: "10px",
                                        // fontStyle: "italic",
                                        fontWeight: 400,
                                        marginTop: "8px",
                                      }}
                                    >
                                      {errorValues?.coursesCollges}
                                    </Typography>
                                  )}
                                </Box>
                              </Grid>
                            </>
                          )
                        )}
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: "500",
                              fontFamily: "gilRoy-semibold",
                              [theme.breakpoints.only("xs")]: {
                                fontSize: "14px",
                              },
                            }}
                          >
                            Rounds Description:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={jobRounds}
                            getOptionLabel={(option) => option?.name}
                            value={currentRound}
                            onChange={(event, newValue) => {
                              // if (newValue) setCurrentRound(newValue);
                              // else setCurrentRound();
                              setCurrentRound(newValue);
                            }}
                            style={{
                              background: "#fff",
                              borderRadius: "8px",
                            }}
                            PaperComponent={CustomAutoCompletePaper}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                variant="outlined"
                                label="Select Rounds *"
                                size="small"
                                error={errorValues?.jobRounds ? true : false}
                                helperText={
                                  errorValues?.jobRounds
                                    ? errorValues?.jobRounds
                                    : ""
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid xs={6} sm={6} md={2} sx={{ display: "grid" }}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            popupIcon={
                              <AccessTimeIcon
                                sx={{ fontSize: "18px", marginTop: "4px" }}
                              />
                            }
                            options={roundtime}
                            value={roundDuration}
                            onChange={(event, newValue) => {
                              if (newValue) setRoundDuration(newValue);
                              else setRoundDuration(null);
                            }}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                size="small"
                                label="Duration"
                              />
                            )}
                          />
                        </Grid>
                        <Grid xs={6} sm={6} md={3} style={{ display: "grid" }}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={venue}
                            value={roundVenue}
                            onChange={(event, newValue) => {
                              if (newValue) setRoundVenue(newValue);
                              else setRoundVenue(null);
                            }}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                size="small"
                                label="Venue Type"
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={3}
                          md={2}
                          sx={{ display: "grid" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              boxShadow: "none",
                              borderRadius: "8px",
                              height: "40px",
                              fontSize: "14px",
                            }}
                            onClick={addRound}
                          >
                            Add Round
                          </Button>
                        </Grid>
                        {/* <Grid item xs={12} /> */}
                        <Grid item xs={12} sm={12} sx={{ p: 0 }}>
                          {postJobData?.Basics?.rounds?.length > 0 && (
                            <>
                              <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable-1">
                                  {(provided) => (
                                    <Box
                                      sx={{ mt: 2, minHeight: "200px" }}
                                      className="droppable-1"
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      <Grid container spacing={2}>
                                        {postJobData?.Basics?.rounds?.length >
                                          0 &&
                                          postJobData?.Basics?.rounds?.map(
                                            (round, index) => (
                                              <Draggable
                                                key={round.round_id}
                                                draggableId={
                                                  "draggable-" + round.round_id
                                                }
                                                index={index}
                                              >
                                                {(provided) => (
                                                  <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={12}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                  >
                                                    <Box
                                                      sx={{
                                                        position: "relative",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <NewRound
                                                        round={round}
                                                        index={index}
                                                        totalLength={
                                                          postJobData?.Basics
                                                            ?.rounds?.length
                                                        }
                                                        deleteRound={
                                                          deleteRound
                                                        }
                                                      />
                                                    </Box>
                                                  </Grid>
                                                )}
                                              </Draggable>
                                            )
                                          )}
                                      </Grid>
                                    </Box>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              mt: 3,
                            }}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                setPostJobData({
                                  ...postJobData,
                                  Colleges: {
                                    ...postJobData.Colleges,
                                    apply_start_date: null,
                                    apply_end_date: null,
                                    hiring_type: "",
                                    college: [],
                                  },
                                  Basics: {
                                    ...postJobData.Basics,
                                    rounds: [],
                                    job_duration_start: null,
                                  },
                                });
                                setFormCheckBox({
                                  ...formCheckBox,
                                  selectionProcedure: false,
                                });
                                setCurrentRound(null);
                                setRoundVenue(null);
                                setRoundDuration(null);
                              }}
                              sx={{
                                boxShadow: "none",
                                height: "40px",
                                width: "150px",
                                borderWidth: "1.33px",
                                [theme.breakpoints.only("xs")]: {
                                  //height: "35px",
                                  width: "auto",
                                  fontSize: "12px",
                                },
                              }}
                            >
                              Reset
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => {
                                if (
                                  validateForm(3) &&
                                  validateForm(2) &&
                                  validateForm(1)
                                ) {
                                  setFormThree(true);
                                  setExpanded(false);
                                  setFormCheckBox({
                                    ...formCheckBox,
                                    selectionProcedure: true,
                                  });
                                  alert.success(
                                    "Kudos! You're a Click Away to Post Job Sucessfully!"
                                  );
                                }
                              }}
                              sx={{
                                boxShadow: "none",
                                height: "40px",
                                width: "150px",
                                backgroundColor: "#33AA28",
                                color: "#fff",
                                marginLeft: "10px",
                                [theme.breakpoints.only("xs")]: {
                                  //height: "35px",
                                  width: "auto",
                                  fontSize: "12px",
                                },
                              }}
                            >
                              Save & Update
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <AppBar
            color="inherit"
            sx={{
              boxShadow: "none",
              position: "relative",
              marginTop: "10px",
              [theme.breakpoints.only("sm")]: {
                // top: "auto",
                // bottom: 0,
                // boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
                // position: "fixed",
              },
              [theme.breakpoints.only("xs")]: {
                // top: "auto",
                // bottom: 0,
                // boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
                // position: "fixed",
              },
            }}
          >
            <Grid container spacing={3} sx={{ width: "100%", margin: 0 }}>
              <Grid
                item
                md={6}
                sx={{
                  [theme.breakpoints.only("sm")]: {
                    display: "none",
                  },
                  [theme.breakpoints.only("xs")]: {
                    display: "none",
                  },
                }}
              />

              <Grid item xs={6} md={3} sx={{ display: "grid" }}>
                <Button
                  color="primary"
                  variant="outlined"
                  sx={{
                    height: "50px",
                    width: "100%",
                    boxShadow: "none",
                    borderRadius: "8px",
                    display: "flex",
                    fontWeight: "400",
                    fontSize: "15px",
                  }}
                  onClick={() => createJob("preview")}
                >
                  Preview
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    !formCheckBox.aboutJob ||
                    !formCheckBox.eligiblityCriteria ||
                    !formCheckBox.selectionProcedure
                  }
                  sx={{
                    height: "50px",
                    width: "100%",
                    boxShadow: "none",
                    borderRadius: "8px",
                    display: "flex",
                    fontWeight: "400",
                    fontSize: "15px",
                  }}
                  onClick={() => {
                    // setStep(0);
                    // if(flagCheck === "edit"){
                    //   createJob();
                    // }else{
                    if (validateForm(4)) {
                      createJob("OPEN");
                    }
                    // }
                  }}
                >
                  {loading ? (
                    <CircularProgress size={18} sx={{ color: "white" }} />
                  ) : flagCheck === "edit" ? (
                    "Update Job"
                  ) : (
                    "Post Job"
                  )}
                </Button>
              </Grid>
            </Grid>
          </AppBar>

          <DescriptionTemplate
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            jobRoleList={jobRoleList}
            jobDes={jobDes}
            setJobDes={setJobDes}
          />

          <PreviewJob
            // activeStep={activeStep}
            // steps={step}
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            data={previewData}
            postJobData={postJobData}
            flagCheck={"flagCheck"}
            createJob={createJob}
            validateSelectionProcedure={validateSelectionProcedure}
            setDraftJobLoader={setDraftJobLoader}
            postJobLoader={loading}
            draftJobLoader={draftJobLoader}
          />
        </>
      )}
    </>
  );
}

export default MergedThirdForm;

const data = [
  { label: "1 Month", val: 1 },
  { label: "2 Months", val: 2 },
  { label: "3 Months", val: 3 },
  { label: "4 Months", val: 4 },
  { label: "5 Months", val: 5 },
  { label: "6 Months", val: 6 },
  { label: "7 Months", val: 7 },
  { label: "8 Months", val: 8 },
  { label: "9 Months", val: 9 },
  { label: "10 Months", val: 10 },
  { label: "11 Months", val: 11 },
  { label: "12 Months", val: 12 },
];

const exp = [
  { title: "Fresher", val: 0 },
  { title: "1 years", val: 1 },
  { title: "2 years", val: 2 },
  { title: "3 years", val: 3 },
  { title: "4 years", val: 4 },
  { title: "5 years", val: 5 },
  { title: "6 years", val: 6 },
  { title: "7 years", val: 7 },
  { title: "8 years", val: 8 },
  { title: "9 years", val: 9 },
  { title: "10 years", val: 10 },
  { title: "10+ years", val: 11 },
];

const MobileJobDes = ({ handleClickOpen, state, setState }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpenDes = () => {
    setOpen(true);
  };

  const handleCloseDes = () => {
    setOpen(false);
  };
  const theme = useTheme();

  return (
    <>
      <div>
        <Button
          startIcon={
            <ControlPointIcon
              sx={{ color: "#000", marginLeft: 0, marginRight: "4px" }}
            />
          }
          onClick={handleClickOpenDes}
          variant="text"
          sx={{
            borderRadius: "8px",
            boxShadow: "none",
            display: "flex",
            height: "40px",
          }}
        >
          <Typography
            style={{
              fontSize: "12px",
              color: "#000",
              fontFamily: "gilRoy-semibold",
            }}
          >
            Add Job Description
          </Typography>
        </Button>
        <Dialog
          sx={{
            [theme.breakpoints.only("xs")]: {
              height: 500,
              alignItems: "flex-end",
              top: "unset",
            },

            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
          fullScreen
          open={open}
          onClose={handleCloseDes}
          TransitionComponent={Transition}
        >
          <Box sx={{ position: "relative" }}>
            <Editor
              className="test-2"
              state={state}
              setState={setState}
              handleClickOpen={handleClickOpen}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <AppBar
              color="inherit"
              sx={{
                boxShadow: "none",
                position: "relative",

                [theme.breakpoints.only("sm")]: {
                  top: "auto",
                  bottom: 0,
                  boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
                  position: "fixed",
                },
                [theme.breakpoints.only("xs")]: {
                  top: "auto",
                  bottom: 0,
                  boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
                  position: "fixed",
                },
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  width: "100%",
                  margin: 0,
                  pl: 1.5,
                  pr: 1.5,
                  justifyContent: "flex-end",
                }}
              >
                <Grid xs={6} sm={3} md={3} sx={{ display: "grid" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleCloseDes}
                    sx={{
                      height: "40px",
                      borderRadius: "8px",
                      boxShadow: "none",
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid xs={6} sm={3} md={3} sx={{ display: "grid" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      height: "40px",
                      borderRadius: "8px",
                      boxShadow: "none",
                    }}
                  >
                    Save
                    {/* Add Institutes */}
                  </Button>
                </Grid>
              </Grid>
            </AppBar>
            {/* </Container> */}
          </Box>
        </Dialog>
      </div>
    </>
  );
};
