import { Chip } from "@mui/material";
import React from "react";

const StatusChip = ({ successCondition, pendingCondition }) => {
  return (
    <Chip
      size="small"
      color="primary"
      variant="outlined"
      sx={{
        backgroundColor: pendingCondition
          ? "#DD9D13"
          : successCondition
          ? "#CEE8CC" //green
          : "#f3d0d2", //red
        border: "none",
        color: pendingCondition
          ? "#fff"
          : successCondition
          ? "#0B8A00" //green
          : "#c4161c", //red
        display: "grid", //0B8A00
      }}
      label={
        pendingCondition
          ? "Pending"
          : successCondition
          ? "Accepted"
          : "Rejected"
      }
      // deleteIcon={<CloseIcon />}
      // onDelete={handleDelete(data)}
    />
  );
};

export default StatusChip;
