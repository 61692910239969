import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Typography,
  Box,
  Chip,
  useTheme,
  alpha,
  Divider,
  Button,
  Paper,
  styled,
  IconButton,
  CircularProgress,
} from "@mui/material";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import moment from "moment";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import LinkIcon from "@mui/icons-material/Link";
import ReactHtmlParser from "react-html-parser";
import Institutes from "../../Cards/Post/Institutes";
import JobRound from "../../Cards/Post/JobRound";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import companyLogo from "../../../Assets/Images/png/companyLogo.png";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import CustomButtonLoader from "../../Common/Loaders/CustomButtonLoader";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
  marginLeft: "0px",
}));

function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
    : Math.sign(num) * Math.abs(num);
}

function LFormatter(num) {
  return Math.abs(num) > 99999
    ? Math.sign(num) * (Math.abs(num) / 100000).toFixed(1) + "L"
    : Math.sign(num) * Math.abs(num);
}

function MFormatter(num) {
  return Math.abs(num) > 999999
    ? Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "M"
    : Math.sign(num) * Math.abs(num);
}

function checkForUrl(str) {
  var new_str = "";

  if (str) {
    if (str.includes("http")) new_str = str;
    else new_str = "https://" + str;
  } else {
    new_str = str;
  }

  return new_str;
}

function PreviewJobDetail({
  data,

  createJob,

  draftJobLoader,

  handleClose = () => {},
  postJobLoginFlow
}) {
  const mainData = [
    {
      icon: <ApartmentIcon style={{ color: "#6c757d", height: "18px" }} />,
      heading: "Hiring Type",
      details: data?.hiring_type === "ON CAMPUS" ? "On-Campus" : "Off-Campus",
      show: data?.hiring_type ? true : false,
    },
    {
      icon: (
        <WorkOutlineOutlinedIcon style={{ color: "#6c757d", height: "18px" }} />
      ),
      heading: "Job Type",
      details: data?.job_type === 7 ? "Full-time" : "Internship",
      show: data?.job_type ? true : false,
    },

    {
      icon: (
        <MoreTimeOutlinedIcon style={{ color: "#6c757d", height: "18px" }} />
      ),
      heading: "Duration",
      details: data?.duration ? `${data?.duration?.label}` : "NA",
      show:
        data?.job_type && data?.job_type === 1 && data?.duration ? true : false,
    },
    {
      icon: (
        <PaymentsOutlinedIcon style={{ color: "#6c757d", height: "18px" }} />
      ),
      heading: "Stipend",
      details:
        data?.internship_salary_type === "PAID"
          ? `₹${kFormatter(data?.stipend)} Monthly`
          : data?.internship_salary_type === "UNPAID"
          ? "Unpaid"
          : "Not Disclosed",
      show:
        data?.job_type && data?.job_type === 1 && data?.internship_salary_type
          ? true
          : false,
    },
    {
      icon: <BadgeOutlinedIcon style={{ color: "#6c757d", height: "18px" }} />,
      heading: "PPO",
      details: data?.ppo ? "Yes" : "No",
      show: data?.job_type && data?.job_type === 1 && data?.ppo ? true : false,
    },
    {
      icon: (
        <AccountBalanceWalletOutlinedIcon
          style={{ color: "#6c757d", height: "18px" }}
        />
      ),
      heading: "CTC Post PPO",
      details:
        data?.salary_type === "PAID"
          ? `₹${LFormatter(data?.ctc_min)} - ₹${LFormatter(
              data?.ctc_max
            )} Yearly`
          : data?.salary_type === "UNPAID"
          ? "Unpaid"
          : "Not Disclosed",

      show:
        data?.job_type && data?.job_type === 1 && data?.salary_type
          ? true
          : false,
    },

    {
      icon: (
        <AccountBalanceWalletOutlinedIcon
          style={{ color: "#6c757d", height: "18px" }}
        />
      ),
      heading: "CTC Offered",
      details:
        data?.salary_type === "PAID"
          ? `₹${
              data?.ctc_min > 99999
                ? LFormatter(data?.ctc_min)
                : kFormatter(data?.ctc_min)
            } - ₹${LFormatter(data?.ctc_max)} Yearly`
          : data?.salary_type === "UNPAID"
          ? "Unpaid"
          : "Not Disclosed",
      show:
        data?.job_type && data?.job_type === 7 && data?.salary_type
          ? true
          : false,
    },
    {
      icon: (
        <DateRangeOutlinedIcon style={{ color: "#6c757d", height: "18px" }} />
      ),
      heading: "Apply start",
      details: moment(data?.apply_start_date).format("DD MMM YY"),
      show: data?.apply_start_date ? true : false,
    },
    {
      icon: (
        <HourglassEmptyOutlinedIcon
          style={{ color: "#6c757d", height: "18px" }}
        />
      ),
      heading: "Apply by",
      details: moment(data?.apply_end_date).format("DD MMM YY"),
      show: data?.apply_end_date ? true : false,
    },
    {
      icon: (
        <HourglassEmptyOutlinedIcon
          style={{ color: "#6c757d", height: "18px" }}
        />
      ),
      heading: "Joining date",
      details: moment(data?.job_duration_start).format("DD MMM YY"),
      show: data?.job_duration_start ? true : false,
    },
    {
      icon: (
        <HomeWorkOutlinedIcon style={{ color: "#6c757d", height: "18px" }} />
      ),
      heading: "Work Mode",
      details:
        data?.work_from_home === "WORK FROM OFFICE"
          ? "Work From Office"
          : data?.work_from_home === "HYBRID"
          ? "Hybrid"
          : "Work From Home",
      show: data?.work_from_home ? true : false,
    },
    // {
    //   icon: (
    //     <HourglassEmptyOutlinedIcon
    //       style={{ color: "#6c757d", height: "18px" }}
    //     />
    //   ),
    //   heading: "Apply By",
    //   details: "efrjj",
    //   show: data?.application_end_date ? true : false,
    // },
    // {
    //   icon: <TimelapseOutlined style={{ color: "#6c757d", height: "18px" }} />,
    //   heading: "Duration",
    //   details:
    //     data?.internship_duration && data?.internship_duration > 1
    //       ? `${data?.internship_duration} months`
    //       : `${data?.internship_duration} month`,
    //   show: data?.internship_duration ? true : false,
    // },

    // {
    //   icon: (
    //     <AccountBalanceWalletOutlinedIcon
    //       style={{ color: "#6c757d", height: "18px" }}
    //     />
    //   ),
    //   heading: "Stipend",
    //   details:
    //     data?.salary_type === "PAID"
    //       ? data?.ctc_min !== 0 &&
    //         data?.ctc_max !== 0 &&
    //         valueLabelFormat(data?.ctc_min) +
    //           " - " +
    //           valueLabelFormat(data?.ctc_max) +
    //           " per month"
    //       : data?.salary_type === "UNPAID"
    //       ? "Unpaid"
    //       : "Not Disclosed",
    //   show: data?.job_type_id === 1 || data?.job_type_id === 3 ? true : false,
    // },
    // {
    //   icon: <CardMembershipIcon style={{ color: "#6c757d", height: "18px" }} />,
    //   heading: "PPO",
    //   details: "Yes",
    //   show: data?.ppo ? true : false,
    // },
    // {
    //   icon: (
    //     <AccountBalanceWalletOutlinedIcon
    //       style={{ color: "#6c757d", height: "18px" }}
    //     />
    //   ),
    //   heading: <>Salary After PPO</>,
    //   details:
    //     data?.ppo_salary_type === "PAID"
    //       ? data?.ppo_ctc_min !== 0 &&
    //         data?.ppo_ctc_max !== 0 &&
    //         valueLabelFormat(data?.ppo_ctc_min) +
    //           " - " +
    //           valueLabelFormat(data?.ppo_ctc_max) +
    //           " PA"
    //       : data?.salary_type === "UNPAID"
    //       ? "Unpaid"
    //       : "Not Disclosed",
    //   show: data?.job_type_id === 1 && data?.ppo === true ? true : false,
    // },
    // {
    //   icon: (
    //     <AccountBalanceWalletOutlinedIcon
    //       style={{ color: "#6c757d", height: "18px" }}
    //     />
    //   ),
    //   heading: "Compensation",
    //   details:
    //     data?.salary_type === "PAID"
    //       ? data?.ctc_min !== 0 &&
    //         data?.ctc_max !== 0 &&
    //         valueLabelFormat(data?.ctc_min) +
    //           " - " +
    //           valueLabelFormat(data?.ctc_max) +
    //           " PA"
    //       : data?.salary_type === "UNPAID"
    //       ? "Unpaid"
    //       : "Not Disclosed",
    //   show: data?.job_type_id === 7 ? true : false,
    // },
  ];

  const theme = useTheme();
  const [skillShowMore, setSkillShowMore] = useState(false);
  const [degreeCourse, setDegreeCourse] = useState(null);
  const [degreeCourseShowMore, setDegreeCourseShowMore] = useState(false);
  const [gradYearShowMore, setGradYearShowMore] = useState(false);
  const [instituteShowMore, setInstituteShowMore] = useState(false);
  const [locationShowMore, setLocationShowMore] = useState(false);

  useEffect(() => {
    if (!degreeCourse) {
      degreeCourseArr();
    }
  }, [data?.eligibility_criteria?.degrees]);

  const degreeCourseArr = () => {
    let temp = [];
    data?.eligibility_criteria?.degrees?.map((deg) => {
      if (deg?.all_spec_selected) {
        temp.push({ name: `${deg?.name}-All Branches` });
      } else {
        deg?.spec?.length > 0 &&
          deg?.spec?.map((specialization) => {
            temp.push({
              name: `${specialization?.degree_name}-${specialization?.name}`,
            });
          });
      }
    });
    setDegreeCourse(temp);
  };

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item>
            <div
              style={{
                display: "flex",
                width: 150,
                height: 80,
                borderRadius: "8px",
                border: ".5px solid #e2e6ea",
              }}
            >
              <img
                style={{
                  margin: "auto",
                  //  display: "block",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                alt="complex"
                src={
                  data?.company?.company_logo
                    ? data?.company?.company_logo
                    : companyLogo
                }
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
            sm
            container
          >
            <Grid
              item
              xs
              container
              direction="column"
              spacing={2}
              style={{ display: "contents" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>         
                  <Typography variant="h3">{data?.job_title}</Typography>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      marginBottom: "10px",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ fontWeight: 600, color: "#6C757D" }}
                    >
                      {data?.company?.company_name}
                    </Typography>
                  </div>
                </Box>
                {!postJobLoginFlow && <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      borderRadius: "8px",
                      boxShadow: "none",
                      height: "40px",
                      mr: 2,
                      minWidth: "120px",
                    }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Edit Job
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      borderRadius: "8px",
                      boxShadow: "none",
                      height: "40px",
                      minWidth: "120px",
                    }}
                    onClick={() => {
                      createJob("DRAFT");
                    }}
                  >
                    {draftJobLoader ? (
                      <CircularProgress size={18} sx={{ color: "white" }} />
                    ) : (
                      "Draft Job"
                    )}
                  </Button>
                </Box>}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          <Grid
            item
            md={12}
            container
            direction="row"
            style={{ display: "flex", justifyContent: "flex-start" }}
            spacing={1}
          >
            {mainData?.map(
              (item) =>
                item.show && (
                  <Grid item xs={6} md={2}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "space-around",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      {item.icon}
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "flex-start",
                          alignContent: "center",
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ fontWeight: 600 }}
                        >
                          {item.heading}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {item.details}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                )
            )}
          </Grid>
        </Grid>
      </Box>
      {data?.job_location?.length > 0 && (
        <Box>
          <div style={{ color: "#6c757d", marginTop: "20px" }}>
            <Box style={{ display: "flex" }}>
              <RoomOutlinedIcon />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ paddingBottom: "10px", fontWeight: 600 }}
              >
                Location
              </Typography>
            </Box>
            <Paper
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
                boxShadow: "none",
              }}
              component="ul"
            >
              {data?.work_from_home && !data?.job_location?.length > 0 && (
                <ListItem>
                  <Chip
                    variant="outlined"
                    size="medium"
                    label="Work from Home"
                    color="primary"
                    sx={{
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      color: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                      border: "1px solid",
                    }}
                  />
                </ListItem>
              )}

              {data?.work_from_home &&
              data?.job_location?.length > 0 &&
              !locationShowMore ? (
                <>
                  {data?.job_location?.slice(0, 5)?.map((loc) => (
                    <ListItem>
                      <Chip
                        variant="outlined"
                        size="medium"
                        label={loc?.city}
                        color="primary"
                        sx={{
                          backgroundColor: alpha(
                            theme.palette.primary.main,
                            0.1
                          ),
                          color: theme.palette.primary.main,
                          borderColor: theme.palette.primary.main,
                          border: "1px solid",
                        }}
                      />
                    </ListItem>
                  ))}
                  {data?.job_location?.length > 5 && !locationShowMore && (
                    <Typography
                      color="primary"
                      variant="body2"
                      style={{ cursor: "pointer", marginTop: "7px" }}
                      onClick={() => {
                        setLocationShowMore(!locationShowMore);
                      }}
                    >
                      {`+${data?.job_location?.length - 5} More`}
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  {data?.work_from_home &&
                    data?.job_location?.length > 0 &&
                    data?.job_location?.map((loc) => (
                      <ListItem>
                        {" "}
                        <Chip
                          variant="outlined"
                          size="medium"
                          label={loc?.city}
                          color="primary"
                          sx={{
                            backgroundColor: alpha(
                              theme.palette.primary.main,
                              0.1
                            ),
                            color: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                            border: "1px solid",
                          }}
                        />
                      </ListItem>
                    ))}
                  {data?.work_from_home &&
                    data?.job_location?.length > 5 &&
                    locationShowMore && (
                      <Typography
                        color="primary"
                        variant="body2"
                        style={{ cursor: "pointer", marginTop: "7px" }}
                        onClick={() => {
                          setLocationShowMore(!locationShowMore);
                        }}
                      >
                        {`Show Less`}
                      </Typography>
                    )}
                </>
              )}

              {!data?.work_from_home &&
              data?.job_location?.length > 0 &&
              !locationShowMore ? (
                <>
                  {!data?.work_from_home &&
                    data?.job_location?.slice(0, 5)?.map((loc) => (
                      <ListItem>
                        {" "}
                        <Chip
                          variant="outlined"
                          size="medium"
                          label={loc?.city}
                          color="primary"
                          sx={{
                            backgroundColor: alpha(
                              theme.palette.primary.main,
                              0.1
                            ),
                            color: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                            border: "1px solid",
                          }}
                        />
                      </ListItem>
                    ))}
                  {!data?.work_from_home &&
                    data?.job_location?.length > 5 &&
                    !locationShowMore && (
                      <Typography
                        color="primary"
                        variant="body2"
                        style={{ cursor: "pointer", marginTop: "7px" }}
                        onClick={() => {
                          setLocationShowMore(!locationShowMore);
                        }}
                      >
                        {`+${data?.job_location?.length - 5} More`}
                      </Typography>
                    )}
                </>
              ) : (
                <>
                  {!data?.work_from_home &&
                    data?.job_location?.length > 0 &&
                    data?.job_location?.map((loc) => (
                      <ListItem>
                        {" "}
                        <Chip
                          variant="outlined"
                          size="medium"
                          label={loc?.city}
                          color="primary"
                          sx={{
                            backgroundColor: alpha(
                              theme.palette.primary.main,
                              0.1
                            ),
                            color: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                            border: "1px solid",
                          }}
                        />
                      </ListItem>
                    ))}
                  {!data?.work_from_home &&
                    data?.job_location?.length > 5 &&
                    locationShowMore && (
                      <Typography
                        color="primary"
                        variant="body2"
                        style={{ cursor: "pointer", marginTop: "7px" }}
                        onClick={() => {
                          setLocationShowMore(!locationShowMore);
                        }}
                      >
                        {`Show Less`}
                      </Typography>
                    )}
                </>
              )}
            </Paper>
          </div>
        </Box>
      )}
      {data?.eligibility_criteria?.skills?.length > 0 && (
        <Box>
          <div style={{ color: "#6c757d", marginTop: "20px" }}>
            <Box style={{ display: "flex" }}>
              <TipsAndUpdatesOutlinedIcon
                sx={{ fontSize: "22px", marginRight: "5px" }}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ paddingBottom: "10px", fontWeight: 600 }}
              >
                Skills Required
              </Typography>
            </Box>
            <Paper
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
                boxShadow: "none",
              }}
              component="ul"
            >
              {data?.eligibility_criteria?.skills?.length > 0 &&
                !skillShowMore &&
                data?.eligibility_criteria?.skills
                  ?.slice(0, 5)
                  ?.map((data1) => (
                    <ListItem key={data1?.skill_id}>
                      <Chip
                        sx={{
                          backgroundColor: alpha(
                            theme.palette.primary.main,
                            0.1
                          ),
                          color: theme.palette.primary.main,
                          borderColor: theme.palette.primary.main,
                          border: "1px solid",
                        }}
                        size="medium"
                        label={data1?.skill_name}
                      />
                    </ListItem>
                  ))}
              {data?.eligibility_criteria?.skills?.length > 5 &&
                !skillShowMore && (
                  <Typography
                    color="primary"
                    variant="body2"
                    style={{ cursor: "pointer", marginTop: "7px" }}
                    onClick={() => {
                      setSkillShowMore(!skillShowMore);
                    }}
                  >
                    {`+${data?.eligibility_criteria?.skills?.length - 5} More`}
                  </Typography>
                )}

              {data?.eligibility_criteria?.skills?.length > 0 &&
                skillShowMore &&
                data?.eligibility_criteria?.skills?.map((data1) => (
                  <ListItem key={data1?.skill_id}>
                    <Chip
                      sx={{
                        backgroundColor: alpha(theme.palette.primary.main, 0.1),
                        color: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                        border: "1px solid",
                      }}
                      size="medium"
                      label={data1?.skill_name}
                    />
                  </ListItem>
                ))}
              {data?.eligibility_criteria?.skills?.length > 5 &&
                skillShowMore && (
                  <Typography
                    color="primary"
                    variant="body2"
                    style={{ cursor: "pointer", marginTop: "7px" }}
                    onClick={() => {
                      setSkillShowMore(!skillShowMore);
                    }}
                  >
                    {`Show Less`}
                  </Typography>
                )}
            </Paper>
          </div>
        </Box>
      )}
      <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
      {data?.company?.company_website && data?.company?.company_description && (
        <Box>
          <div style={{}}>
            <Typography variant="h3">
              About {data?.company?.company_name}
            </Typography>
            {data?.company?.company_website && (
              <a
                href={checkForUrl(data?.company?.company_website)}
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <Button
                  color="primary"
                  endIcon={<LaunchIcon />}
                  sx={{ pl: 0 }}
                  style={{ display: "flex" }}
                >
                  {data?.company?.company_website}
                </Button>
              </a>
            )}
            {data?.company?.company_description && (
              <Typography
                variant="body2"
                style={{
                  marginBottom: "10px",
                  color: "#6C757D",
                }}
              >
                {ReactHtmlParser(data?.company?.company_description)}
              </Typography>
            )}
          </div>
        </Box>
      )}
      <Box>
        {data?.job_description && (
          <>
            <Typography variant="h3">{"Job Description"}</Typography>

            <Typography
              variant="body2"
              style={{ marginBottom: "30px", color: "#6C757D" }}
            >
              {ReactHtmlParser(data?.job_description)}
            </Typography>
          </>
        )}
      </Box>
      <Box sx={{ mt: 1 }}>
        {data?.is_service_bond && (
          <>
            <Typography variant="h3">Service Bond</Typography>

            <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
              {`Duration: ${data?.service_bond} Months`}
            </Typography>

            <Typography
              //component="li"
              variant="body2"
              // align="center"
              //key={line}
              style={{ marginBottom: "10px", color: "#6C757D" }}
            >
              {ReactHtmlParser(data?.job_tmc)}
            </Typography>
            {/*  {eligibility.map((tier) => (
            <div style={{ marginLeft: "15px", marginTop: "7px" }}>
              <ol style={{ display: "contents" }}>
                {tier.description.map((line) => (
                  <Typography
                    component="li"
                    variant="body2"
                    // align="center"
                    key={line}
                    style={{ marginBottom: "10px", color: "#6C757D" }}
                  >
                    {line}
                  </Typography>
                ))}
              </ol>
            </div>
          ))} */}
          </>
        )}
      </Box>
      {(data?.eligibility_criteria?.experience?.length > 0 ||
        data?.eligibility_criteria?.qualifications?.length > 0 ||
        data?.eligibility_criteria?.graduation_years?.length > 0 ||
        degreeCourse?.length > 0) && (
        <Box sx={{ mt: 1 }}>
          <Typography variant="h3">Eligibility Criteria</Typography>

          <Typography variant="body2" sx={{ mt: 0.5, mb: 0.5 }}>
            Work Experience
          </Typography>

          <Chip
            variant="outlined"
            size="medium"
            label={
              data?.eligibility_criteria?.experience?.exp
                ? `${data?.eligibility_criteria?.experience?.exp}`
                : "NA"
            }
            color="primary"
            sx={{
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              border: "1px solid",
            }}
          />

          {data?.eligibility_criteria?.qualifications?.length > 0 && (
            <>
              <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                Education Level
              </Typography>
              <Typography
                variant="body2"
                sx={{ mt: 1, mb: 0.5, color: "#979797" }}
              >
                Minimum Required Percentage
              </Typography>
              <Paper
                sx={{
                  // display: "flex",
                  // justifyContent: "flex-start",
                  // flexWrap: "wrap",
                  listStyle: "none",
                  p: 0.5,
                  m: 0,
                  boxShadow: "none",
                }}
                component="ul"
              >
                {data?.eligibility_criteria?.qualifications?.map(
                  (data1, idx) => {
                    return (
                      <ListItem variant="body2">
                        <Typography variant="body2">
                          {`${idx + 1}. ${data1?.education_type_name}: ${
                            data1?.percentage
                          } %`}
                        </Typography>
                      </ListItem>
                    );
                  }
                )}
              </Paper>
            </>
          )}
          {data?.eligibility_criteria?.graduation_years?.length > 0 && (
            <>
              <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                Eligible Passing Years
              </Typography>
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  listStyle: "none",
                  p: 0.5,
                  m: 0,
                  boxShadow: "none",
                }}
                component="ul"
              >
                {data?.eligibility_criteria?.graduation_years?.length > 0 &&
                  !gradYearShowMore &&
                  data?.eligibility_criteria?.graduation_years
                    ?.slice(0, 5)
                    ?.map((data1, i) => (
                      <ListItem key={i}>
                        <Chip
                          sx={{
                            backgroundColor: alpha(
                              theme.palette.primary.main,
                              0.1
                            ),
                            color: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                            border: "1px solid",
                          }}
                          size="medium"
                          label={data1}
                        />
                      </ListItem>
                    ))}
                {data?.eligibility_criteria?.graduation_years?.length > 5 &&
                  !gradYearShowMore && (
                    <Typography
                      color="primary"
                      variant="body2"
                      style={{ cursor: "pointer", marginTop: "7px" }}
                      onClick={() => {
                        setGradYearShowMore(!gradYearShowMore);
                      }}
                    >
                      {`+${
                        data?.eligibility_criteria?.graduation_years?.length - 5
                      } More`}
                    </Typography>
                  )}

                {data?.eligibility_criteria?.graduation_years?.length > 0 &&
                  gradYearShowMore &&
                  data?.eligibility_criteria?.graduation_years?.map(
                    (data1, i) => (
                      <ListItem key={i}>
                        <Chip
                          sx={{
                            backgroundColor: alpha(
                              theme.palette.primary.main,
                              0.1
                            ),
                            color: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                            border: "1px solid",
                          }}
                          size="medium"
                          label={data1}
                        />
                      </ListItem>
                    )
                  )}
                {data?.eligibility_criteria?.graduation_years?.length > 5 &&
                  gradYearShowMore && (
                    <Typography
                      color="primary"
                      variant="body2"
                      style={{ cursor: "pointer", marginTop: "7px" }}
                      onClick={() => {
                        setGradYearShowMore(!gradYearShowMore);
                      }}
                    >
                      {`Show Less`}
                    </Typography>
                  )}
              </Paper>
            </>
          )}
          {degreeCourse?.length > 0 && (
            <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
              {`Eligible Degree(s) & Courses`}
            </Typography>
          )}
          <Paper
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              listStyle: "none",
              p: 0.5,
              m: 0,
              boxShadow: "none",
            }}
            component="ul"
          >
            {degreeCourse?.length > 0 &&
              !degreeCourseShowMore &&
              degreeCourse?.slice(0, 5)?.map((data1, index) => (
                <ListItem key={index}>
                  <Chip
                    sx={{
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      color: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                      border: "1px solid",
                    }}
                    size="medium"
                    label={data1?.name}
                  />
                </ListItem>
              ))}
            {degreeCourse?.length > 5 && !degreeCourseShowMore && (
              <Typography
                color="primary"
                variant="body2"
                style={{ cursor: "pointer", marginTop: "7px" }}
                onClick={() => {
                  setDegreeCourseShowMore(!degreeCourseShowMore);
                }}
              >
                {`+${degreeCourse?.length - 5} More`}
              </Typography>
            )}

            {degreeCourse?.length > 0 &&
              degreeCourseShowMore &&
              degreeCourse?.map((data1, index) => (
                <ListItem key={index}>
                  <Chip
                    sx={{
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      color: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                      border: "1px solid",
                    }}
                    size="medium"
                    label={data1?.name}
                  />
                </ListItem>
              ))}
            {degreeCourse?.length > 5 && degreeCourseShowMore && (
              <Typography
                variant="body2"
                color="primary"
                style={{ cursor: "pointer", marginTop: "7px" }}
                onClick={() => {
                  setDegreeCourseShowMore(!degreeCourseShowMore);
                }}
              >
                {`Show Less`}
              </Typography>
            )}
          </Paper>
        </Box>
      )}
      {data?.hiring_type === "ON CAMPUS" && data?.college?.length > 0 && (
        <Box>
          <Typography variant="h3" sx={{ mt: 1, mb: 2 }}>
            Campus Hiring
          </Typography>
          {!instituteShowMore ? (
            <>
              {data?.college?.slice(0, 3)?.map((clg) => (
                <Box>
                  <Institutes clg={clg} />
                </Box>
              ))}
              {data?.college?.length > 5 && !instituteShowMore && (
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ cursor: "pointer", marginTop: "7px" }}
                  onClick={() => {
                    setInstituteShowMore(!instituteShowMore);
                  }}
                >
                  {`+${data?.college?.length - 3} More`}
                </Typography>
              )}
            </>
          ) : (
            <>
              {data?.college?.map((clg) => (
                <Box>
                  <Institutes clg={clg} />
                </Box>
              ))}
              {data?.college?.length > 5 && instituteShowMore && (
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ cursor: "pointer", marginTop: "7px" }}
                  onClick={() => {
                    setInstituteShowMore(!instituteShowMore);
                  }}
                >
                  {`Show Less`}
                </Typography>
              )}
            </>
          )}
        </Box>
      )}
      {data?.rounds?.length > 0 && (
        <Box>
          <Typography variant="h3" sx={{ mt: 1, mb: 2 }}>
            Selection Procedure
          </Typography>
          <Grid container spacing={2}>
            {data?.rounds?.length > 0 &&
              data?.rounds?.map((round) => (
                <Grid md={3}>
                  <JobRound round={round} />
                </Grid>
              ))}
          </Grid>
        </Box>
      )}

      {data?.documents?.length > 0 && (
        <Box sx={{ mt: 1 }}>
          <Typography variant="h3">Required Documents</Typography>
          <Paper
            sx={{
              listStyle: "none",
              p: 0.5,
              m: 0,
              boxShadow: "none",
            }}
            component="ol"
          >
            {data?.documents?.map((item, index) => {
              return (
                <>
                  <ListItem>{`${index + 1}. ${item}`}</ListItem>
                </>
              );
            })}
          </Paper>
        </Box>
      )}
      <Box>
        {data?.vacancy && (
          <Typography variant="h3">
            Number of Openings: {data?.vacancy}
          </Typography>
        )}
      </Box>
    </>
  );
}

export default PreviewJobDetail;
