import {
  CircularProgress,
  TextField,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

import {
  BackendBaseApi,
  emailRegex,
  handleCopyClick,
  PRAVESH_API,
} from "../../../../Constants/constants";
import { CustomTextField } from "../../../Common/InputText/InputField";
import fetchApi from "../../../../Apis/fetchApi";

export default function EnterPhone({
  setStep,
  details,
  setDetails,
  email_history,
  phone_history,
  userData,
}) {
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [timerComplete, setTimerComplete] = React.useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const prefilled_email = userData?.data?.user_details.email || "";
  const prefilled_phone = userData?.data?.user_details.phone || "";
// const [phoneExists, setPhoneExists] = useState(false);  // const [phone, setPhone] = useState("");
  
  const [loading, setLoading] = useState(false);
   const [apiMessage, setApiMessage] = useState(""); // Store API response message
     const [Messagecolor,setMessagecolor]=useState(false);
     const [isPhoneEdited, setIsPhoneEdited] = useState(false);
   
   useEffect(() => {
    if (!isPhoneEdited) return;
    const phone = details?.basic?.phone || phone_history || prefilled_phone;
  
    if (!phone || phone.length !== 10) {
      // setPhoneExists(null);
      setApiMessage("");
      return;
    }
  
    const timer = setTimeout(() => {
      checkPhoneExists(phone);
    }, 1000);
  
    return () => clearTimeout(timer); // Cleanup function
  }, [details?.basic?.phone, phone_history, prefilled_phone]);
  
  
  const checkPhoneExists = async (phoneNumber) => {
    setLoading(true);
    try {
      const response = await axios.get(`${PRAVESH_API}company_saas/new/login/signup/check-phone?phone=${phoneNumber}`);
  
      if (response.data.success && response.data.data.is_exist &&response.data.data.is_verified) {
        // setPhoneExists(true);
        setMessagecolor(false);
        setApiMessage("Phone number is already registered !");
      }
      else if(!response.data.success && response.data.data.is_exist === true && response.data.data.is_verified===false)
        {
          // setPhoneExists(true);
          setMessagecolor(false);
          setApiMessage("Phone number is already registered And  Not verified")
        }
      
      else  if (!response.data.success && response.data.data.is_exist === false && response.data.data.is_verified===false) {
        // setPhoneExists(false);
        setMessagecolor(true);
        setApiMessage("Phone number is not registered! You can proceed.");
      } else {
        setMessagecolor(false);
        setApiMessage("Something went wrong. Please try again.");
        setMessagecolor(false);
        setApiMessage("Phone number is already registered")
      }
    } catch (error) {
      setMessagecolor(false);
      setApiMessage("Error checking phone number");
    } finally {
      setLoading(false);
    }
  };


  const handleChange = (e) => {
    setIsPhoneEdited(true);
    setCode(e);
    setCodeError(null);
    setDetails({
      ...details,
      basic: { ...details.basic, phone: e.target.value },
    });
  };

  const helper2 = (data) => {
    if (data.success) {
      setStep(0);
    } else {
      if (data?.data?.code === 2) {
        alert.error(data?.data?.code_text);
      }
    }
  };

  const validateDataOtp = () => {
    if (details?.basic?.phone.length === 10) {
      const body = {
        email: email_history || prefilled_email,
        mobile: details?.basic?.phone
          ? details.basic?.phone
          : phone_history || prefilled_phone,
        user_type: "company",
        action_type: "LOGIN",
        is_mobile_modify: 1,
      };

      fetchApi({
        url: `${PRAVESH_API}api/generate_otp/`,
        method: "post",
        alert: alert,
        body: JSON.stringify(body),
        callBackFn: helper2,
        loginFlow: true,
      });
    } else {
      alert.error("Please Enter valid Phone Number");
    }
  };

  return (
    <div>
      <Typography
        variant="h4"
        style={{
          textAlign: "center",
          fontWeight: "400",
          marginBottom: "20px",
        }}      
      >
        Edit Phone number
      </Typography>

      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} style={{ display: "grid" }}>
            <CustomTextField
              size="small"
              label="Phone Number"
              variant="outlined"
              type="phone"
              value={details?.basic?.phone
                ? details.basic?.phone
                : phone_history || prefilled_phone}
              onChange={handleChange}
              // helperText={
              //   <>
              //     {apiMessage && (
              //       <Typography
              //       style={{
              //         color: phoneExists ? "red" : "green",
              //         fontSize: "12px",
              //       }}
              //     >
              //       {apiMessage}
              //     </Typography>

              //     )}
              //     {!apiMessage && " *OTP Send to your new number"}
              //   </>
              // }
              helperText={
                <>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    apiMessage && (
                      <Typography
                        style={{
                          color: Messagecolor ? "green" : "red",
                          fontSize: "12px",
                        }}
                      >
                        {apiMessage}
                      </Typography>
                    )
                  )}
                  {!apiMessage && !loading && " *OTP Send to your new number"}
                </>
              }
            />
            {/* <Typography
              sx={{ color: "#6C757D", fontSize: "10px", textAlign: "left" }}
            >
              *OTP Send to your new number
            </Typography> */}
          </Grid>
          <Grid item xs={12} style={{ display: "grid", marginTop: 15 }}>
            {" "}
            <Button
              variant="contained"
              sx={{
                display: "flex",
                borderRadius: "8px",
                boxShadow: "none",
                height: "40px",
                fontWeight: "400",
                fontSize: "15px",
              }}
              onClick={() => {
                if (!loadingSubmit) validateDataOtp();
              }}
              color="primary"
            >
              {loadingSubmit ? (
                <CircularProgress style={{ color: "#fff" }} size={18} />
              ) : (
                "Send OTP"
              )}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
