import AdjustIcon from "@mui/icons-material/Adjust";
import InsertInvitationRoundedIcon from "@mui/icons-material/InsertInvitationRounded";
import { Box, Chip, Hidden, Paper, alpha, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import collegeLogo from "../../../Assets/Images/png/collegeLogo.png";
import StatusChip from "../../Common/StatusChip";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function NewInstitutes({
  clg,
  fromJobDetail = false,
  openEdit,
  setOpenEdit,
}) {
  const [expanded, setExpanded] = useState(false);
  const [chipData, setChipData] = useState([
    { key: 0, label: "B. Tech Computer Science" },
    { key: 1, label: "B. Tech IT" },
    { key: 2, label: "M. Tech Computer Science" },
    { key: 3, label: "M. Tech IT" },
    { key: 4, label: "M. Tech Computer Science" },
  ]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const theme = useTheme();

  return (
    <Card
      sx={{
        border: "1px solid #DADADA",
        boxShadow: "0px 4px 10px 0px #0000001C",
        borderRadius: "4px",
        mb: 2,
        position: "relative",
      }}
    >
      <CardActions disableSpacing sx={{ pt: 2.2, pb: 2.2, pl: 2 }}>
        <Box>
          <Grid container spacing={2}>
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  width: 155,
                  height: 75,
                  borderRadius: "8px",
                  border: "1px solid #B0BEC5",
                  [theme.breakpoints.only("xs")]: {
                    width: 55,
                    height: 55,
                  },
                }}
              >
                <img
                  style={{
                    margin: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    borderRadius: "8px",
                  }}
                  alt="complex"
                  src={clg?.college_logo ? clg?.college_logo : collegeLogo}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              sm
              container
            >
              <Grid
                item
                xs
                container
                direction="column"
                spacing={2}
                style={{ display: "contents" }}
              >
                <Box
                  sx={{
                    padding: "5px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    [theme.breakpoints.only("xs")]: {
                      justifyContent: "center",
                    },
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="body1"
                      className="singlecollegeheading"
                      sx={{
                        fontWeight: "600",
                        mb: 1.5,
                        [theme.breakpoints.only("xs")]: {
                          fontSize: "14px",
                        },
                      }}
                    >
                      {clg?.college_name ? clg?.college_name : clg?.name}
                    </Typography>
                    {fromJobDetail && (
                      <div style={{ position: "absolute", right: 30 }}>
                        <StatusChip
                          pendingCondition={
                            !clg?.is_approved && !clg?.is_rejected
                          }
                          successCondition={clg?.is_approved}
                        />
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",

                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <InsertInvitationRoundedIcon
                        sx={{ color: "#6C757D", fontSize: "16px" }}
                      />{" "}
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#6C757D",
                          ml: 1,
                          fontSize: "10px",
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "6px",
                            ml: 0,
                          },
                        }}
                      >
                        Start Date:{" "}
                        {clg?.apply_start_date ? clg?.apply_start_date : "NA"}
                      </Typography>
                    </div>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "40px",
                        [theme.breakpoints.only("sm")]: {
                          marginLeft: "40px",
                        },
                        [theme.breakpoints.only("xs")]: {
                          ml: 0,
                          display: "flex",
                          alignItems: "center",
                        },
                      }}
                    >
                      <InsertInvitationRoundedIcon
                        sx={{ color: "#6C757D", fontSize: "16px" }}
                      />{" "}
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#6C757D",
                          ml: 1,
                          fontSize: "10px",
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "6px",
                            ml: 0,
                          },
                        }}
                      >
                        End Date:{" "}
                        {clg?.apply_end_date ? clg?.apply_end_date : "NA"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "40px",
                        [theme.breakpoints.only("sm")]: {
                          marginLeft: "40px",
                        },
                        [theme.breakpoints.only("xs")]: {
                          ml: 0,
                          display: "flex",
                          alignItems: "center",
                        },
                      }}
                    >
                      <AdjustIcon sx={{ color: "#6C757D", fontSize: "16px" }} />{" "}
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#6C757D",
                          ml: 1,
                          fontSize: "10px",
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "6px",
                            ml: 0,
                          },
                        }}
                      >
                        Backlog: {clg?.backlog ? "Allowed" : "NA"}
                      </Typography>
                    </Box>
                  </div>
                  {/* </Hidden> */}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <IconButton
          color="primary"
          sx={{ marginLeft: "auto", marginRight: "2px" }}
          onClick={() => {
            !openEdit && setOpenEdit(true);
          }}
        >
          <EditIcon sx={{ fontSize: "16px" }} />
        </IconButton>
        <ExpandMore
          sx={{ marginLeft: "0px !important " }}
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ArrowForwardIosRoundedIcon
            sx={{
              color: "#B0B6BA",
              fontSize: "18px",
              transform: "rotate(90deg)",
            }}
          />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent
          sx={{
            padding: "0px !important",
            paddingLeft: "10px !important",
            paddingRight: "10px!important",
            paddingBottom: "10px !important",
            [theme.breakpoints.only("xs")]: {
              p: 0.5,
              paddingBottom: "16px !important",
            },
          }}
        >
          <Hidden only={["xs", "sm", "md", "lg", "xl"]}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                p: 0,
              }}
            >
              <div
                style={{
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginBottom: "10px",
                    //flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    //justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <InsertInvitationRoundedIcon sx={{ color: "#6C757D" }} />{" "}
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#6C757D",
                        ml: 1,
                        fontSize: "10px",
                        [theme.breakpoints.only("xs")]: {
                          fontSize: "10px",
                          ml: 0,
                        },
                      }}
                    >
                      Start Date:{" "}
                      {clg?.apply_start_date ? clg?.apply_start_date : "NA"}
                    </Typography>
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "100px",
                      [theme.breakpoints.only("sm")]: {
                        marginLeft: "50px",
                      },
                      [theme.breakpoints.only("xs")]: {
                        ml: 0,
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                  >
                    <InsertInvitationRoundedIcon sx={{ color: "#6C757D" }} />{" "}
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#6C757D",
                        ml: 1,
                        fontSize: "10px",
                        [theme.breakpoints.only("xs")]: {
                          fontSize: "10px",
                          ml: 0,
                        },
                      }}
                    >
                      End Date:{" "}
                      {clg?.apply_end_date ? clg?.apply_end_date : "NA"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "100px",
                      [theme.breakpoints.only("sm")]: {
                        marginLeft: "50px",
                      },
                      [theme.breakpoints.only("xs")]: {
                        ml: 0.5,
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                  >
                    <InsertInvitationRoundedIcon sx={{ color: "#6C757D" }} />{" "}
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#6C757D",
                        ml: 1,
                        fontSize: "10px",
                        [theme.breakpoints.only("xs")]: {
                          fontSize: "10px",
                          ml: 0,
                        },
                      }}
                    >
                      Backlog: {clg?.backlog ? "Allowed" : "NA"}
                    </Typography>
                  </Box>
                </div>
              </div>
            </Box>
          </Hidden>
          <Paper
            sx={{
              boxShadow: "none",
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              listStyle: "none",
              p: 0.5,
              m: 0,
            }}
            component="ul"
          >
            {clg?.preferences?.map((degree) =>
              degree?.select_all ? (
                <ListItem key={degree}>
                  <Chip
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{
                      backgroundColor: alpha(theme.palette.secondary.main, 0.2),
                      display: "grid",
                    }}
                    label={`${degree?.degree_name} All Branches`}
                    // deleteIcon={<CloseIcon />}
                    // onDelete={handleDelete(data)}
                  />
                </ListItem>
              ) : (
                degree?.specialization?.map((spec) => (
                  <ListItem key={spec?.specialization_id}>
                    <Chip
                      size="small"
                      color="primary"
                      variant="outlined"
                      sx={{
                        backgroundColor: alpha(
                          theme.palette.secondary.main,
                          0.2
                        ),
                      }}
                      label={`${degree?.degree_name} ${spec?.specialization_name}`}
                      // deleteIcon={<CloseIcon />}
                      // onDelete={handleDelete(data)}
                    />
                  </ListItem>
                ))
              )
            )}
          </Paper>
        </CardContent>
      </Collapse>
    </Card>
  );
}
