import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
//import { makeStyles } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import {
  Typography,
  Chip,
  Paper,
  Checkbox,
  FormControlLabel,
  Badge,
  FormHelperText,
  FormControl,
  FormGroup,
  FormLabel,
  TextField,
  CircularProgress,
  alpha,
  Autocomplete,
  Box,
  useTheme,
  IconButton,
  AppBar,
  Toolbar,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShowMore from "../../Dialog/ShowMore";
import { CustomTextField } from "../InputText/InputField";
import { makeStyles } from "@mui/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ paddingLeft: "12px" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    color: "black",
    display: "flex",
    height: "auto",
    minHeight: "70dvh",
    marginBottom: "50px",
  },
  tabs: {
    borderRight: "1px solid #BABABA",
    minWidth: "32%",
    position: "fixed",
    height: "100%",
  },
}));

export default function NewPostJobCollegeFilter({
  filterData,
  filterLoading,
  setSelectedFilters,
  selectedFilters,
  setClickFilterApplyButton,
  filterDataError,
  clearFilter,
  setClearFilter,
  handleClose,
  mobileScreenFilter = false,
}) {
  const theme = useTheme();
  const classes = useStyles();

  const [filterCount, setFilterCount] = useState(0);
  const [tempFilterApplied, setTempFilterApplied] = useState(selectedFilters);
  const [showAll, setShowAll] = useState(false);
  const [state, setState] = useState({
    right: true,
  });
  const [newstate, setNewState] = useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (selectedFilters) {
      setTempFilterApplied(selectedFilters);
    }
  }, []);

  useEffect(() => {
    if (filterData && Object?.keys(filterData).length > 0 && !selectedFilters) {
      let obj = {};
      Object?.keys(filterData).forEach((item) => {
        obj[filterData[item]?.param_name] = [];
      });

      setTempFilterApplied(obj);
    }
  }, [filterData]);

  useEffect(() => {
    let count = 0;
    if (selectedFilters && Object?.keys(selectedFilters)?.length) {
      Object.keys(selectedFilters)?.map((item) => {
        count = count + selectedFilters[item]?.length;
      });
    } else count = 0;
    setFilterCount(count);
  }, [selectedFilters]);

  useEffect(() => {
    let count = 0;
    if (tempFilterApplied && Object.keys(tempFilterApplied)?.length) {
      Object.keys(tempFilterApplied).map((item) => {
        count = count + tempFilterApplied[item]?.length;
      });
    } else count = 0;
    setFilterCount(count);
  }, [tempFilterApplied]);

  useEffect(() => {
    if (clearFilter) {
      var obj = {};
      Object?.keys(tempFilterApplied)?.forEach((item) => {
        obj[item] = [];
      });
      setTempFilterApplied(obj);
      setClearFilter(false);
    }
  }, [clearFilter]);

  const selectCheckboxData = (paramName, currentObj, selectionType) => {
    let currentArray = [...tempFilterApplied[paramName]];
    if (
      tempFilterApplied[paramName]?.some((item) => item?.id === currentObj?.id)
    ) {
      currentArray = currentArray?.filter(
        (item) => item?.id !== currentObj?.id
      );
    } else {
      if (selectionType === "single") currentArray = [currentObj];
      else currentArray?.push(currentObj);
    }
    setTempFilterApplied({ ...tempFilterApplied, [paramName]: currentArray });
  };

  const checkApplyButtonDisabled = () => {
    let flag = true;
    Object.keys(tempFilterApplied).forEach((item) => {
      if (tempFilterApplied[item]?.length > 0) flag = false;
    });
    return flag;
  };

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const toggleDrawerOnClick = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  const handleDelete = (chipToDelete, paramName) => () => {
    let tempArray = [...tempFilterApplied[paramName]];
    tempArray = tempArray.filter((item) => item?.id !== chipToDelete?.id);
    setTempFilterApplied({ ...tempFilterApplied, [paramName]: tempArray });
  };

  const handleChange = (event) => {
    setNewState({ ...newstate, [event.target.name]: event.target.checked });
  };

  const { gilad, jason, antoine } = state;
  const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 320,
        display: "contents",
        position: "relative",
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "#fff",
            zIndex: 1000,
            borderBottom: "1px solid #BABABA",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "10px",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box display="flex" sx={{ pb: 0 }}>
              <Typography
                variant="body2"
                style={{ marginLeft: "20px", color: "#000" }}
              >
                {" "}
                Filter By
              </Typography>
            </Box>
            <Button
              color="secondary"
              // onClick={() => handleClearAll()}
            >
              <Typography color="primary" variant="body2">
                Clear All
              </Typography>
            </Button>
          </div>
          {tempFilterApplied && filterData && (
            <Box
              style={{
                marginLeft: "13px",
                overflowY: "scroll",
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              <Paper
                component="ul"
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  listStyle: "none",
                  padding: 0.5,
                  margin: 0,
                  boxShadow: "none",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  overflow: "scroll",
                }}
              >
                {tempFilterApplied &&
                  filterData &&
                  Object.keys(tempFilterApplied).map(
                    (data) =>
                      tempFilterApplied[data].length > 0 &&
                      tempFilterApplied[data].map((item) => (
                        <li key={item?.id}>
                          <Chip
                            variant="outlined"
                            color="primary"
                            sx={{
                              backgroundColor: alpha(
                                theme.palette.primary.main,
                                0.1
                              ),
                              color: theme.palette.primary.main,
                              borderColor: theme.palette.primary.main,
                              border: "1px solid",
                              margin: "5px",
                            }}
                            label={item?.name}
                            onDelete={handleDelete(item, data)}
                            size="small"
                            deleteIcon={<CloseIcon />}
                          />
                        </li>
                      ))
                  )}
              </Paper>
            </Box>
          )}
        </Box>

        <div className={classes.root}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChangeTab}
            aria-label="Vertical tabs example"
            className={classes.tabs}
            sx={{
              "&& .Mui-selected": {
                backgroundColor: theme.palette.primary.main,
              },
            }}
          >
            {tempFilterApplied &&
              filterData &&
              Object.keys(filterData).map((item, index) => (
                <Tab
                  label={filterData[item]?.heading}
                  {...a11yProps(index)}
                  sx={{
                    fontSize: "12px",
                    border: "1px solid #BABABA",
                    borderRight: "none",
                    borderTop: "none",
                    color: "#000",
                    "&.Mui-selected": {
                      color: "#fff",
                    },
                  }}
                />
              ))}

            {/* <Divider /> */}
          </Tabs>
          {tempFilterApplied &&
            filterData &&
            Object.keys(filterData).map((item, index) => (
              <TabPanel
                value={value}
                index={index}
                style={{ width: "70%", position: "relative", left: "32%" }}
              >
                <>
                  <Box sx={{ pl: 1, pr: 2 }}>
                    {filterData[item]?.field_type === "checkbox" &&
                      filterData[item].data?.length && (
                        <div /* className={classes.checkboxroot} */>
                          <FormControl
                            component="fieldset"
                            //className={classes.formControl}
                          >
                            <FormGroup>
                              {filterData[item]?.data.map((displayData) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      size="small"
                                      name="gilad"
                                      checked={tempFilterApplied[
                                        filterData[item]?.param_name
                                      ].some(
                                        (item) => item?.id === displayData?.id
                                      )}
                                      onChange={(e) => {
                                        selectCheckboxData(
                                          filterData[item]?.param_name,
                                          displayData,
                                          "single"
                                        );
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{ fontSize: "12px !important" }}
                                    >
                                      {displayData?.name}
                                    </Typography>
                                  }
                                />
                              ))}
                            </FormGroup>
                          </FormControl>
                        </div>
                      )}
                    {filterData[item]?.field_type === "multiple_checkbox" &&
                      filterData[item].data?.length && (
                        <div>
                          <FormControl component="fieldset">
                            <FormGroup>
                              {filterData[item]?.data.map((displayData) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      size="small"
                                      name="gilad"
                                      checked={tempFilterApplied[
                                        filterData[item]?.param_name
                                      ].some(
                                        (item) => item?.id === displayData?.id
                                      )}
                                      onChange={(e) => {
                                        selectCheckboxData(
                                          filterData[item]?.param_name,
                                          displayData,
                                          "multiple"
                                        );
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{ fontSize: "12px !important" }}
                                    >
                                      {" "}
                                      {displayData?.name}{" "}
                                    </Typography>
                                  }
                                />
                              ))}
                            </FormGroup>
                          </FormControl>
                        </div>
                      )}
                    {filterData[item]?.field_type === "autocomplete_checkbox" &&
                    filterData[item].data?.length ? (
                      <>
                        <Autocomplete
                          id="select-demo"
                          options={filterData[item]?.data}
                          getOptionLabel={(option) => String(option?.name)}
                          onChange={(e, newValue) => {
                            selectCheckboxData(
                              filterData[item]?.param_name,
                              newValue,
                              "multiple"
                            );
                          }}
                          style={{
                            background: "#fff",
                            borderRadius: "8px",
                            marginTop: 10,
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              size="small"
                              variant="outlined"
                              label={filterData[item]?.heading}
                              InputLabelProps={{}}
                            />
                          )}
                        />
                        <div>
                          <FormControl component="fieldset">
                            <FormGroup>
                              {filterData[item]?.data.map((displayData) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      size="small"
                                      name="gilad"
                                      checked={tempFilterApplied[
                                        filterData[item]?.param_name
                                      ].some(
                                        (item) => item?.id === displayData?.id
                                      )}
                                      onChange={(e) => {
                                        selectCheckboxData(
                                          filterData[item]?.param_name,
                                          displayData,
                                          "multiple"
                                        );
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{ fontSize: "12px !important" }}
                                    >
                                      {displayData?.name}{" "}
                                    </Typography>
                                  }
                                />
                              ))}
                            </FormGroup>
                          </FormControl>
                        </div>
                      </>
                    ) : null}
                  </Box>
                </>
              </TabPanel>
            ))}
        </div>

        <AppBar
          position="fixed"
          sx={{
            top: "auto",
            bottom: 0,
            backgroundColor: "white",
            boxShadow: "none",
            border: "solid 1px #e5e9ec;",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ display: "grid" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                  sx={{ width: "100%", height: "40px" }}
                >
                  Close Filter
                </Button>
              </Grid>

              <Grid item xs={6} sx={{ display: "grid" }}>
                <Button
                  variant="contained"
                  sx={{ width: "100%", height: "40px" }}
                  color="primary"
                  onClick={() => {
                    setSelectedFilters(tempFilterApplied);
                    toggleDrawerOnClick(anchor, false);
                    setClickFilterApplyButton(true);
                    mobileScreenFilter && handleClose();
                  }}
                >
                  Apply Filter
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </>
    </Box>
  );

  return (
    <div>
      {filterLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translate(0, -50%)",
            left: "50%",
          }}
        >
          <CircularProgress size={24} />
        </Box>
      ) : filterDataError ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            alignItems: "center",
          }}
        >
          <p align="center" style={{ margin: 20 }}>
            {" "}
            Some Error Occurred
            <br />
            <span style={{ color: "red" }}>"{filterDataError}"</span>
          </p>
        </div>
      ) : (
        <> {list(/* anchor */)}</>
      )}
    </div>
  );
}
