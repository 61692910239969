import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CancelIcon from "@mui/icons-material/Cancel";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  IconButton,
  Typography,
  Card,
  Divider,
  Switch,
  styled,
} from "@mui/material";
import vactorone from "../../../Assets/Images/svg/online-vactor.svg";
import vactortwo from "../../../Assets/Images/svg/Calling-vactor.svg";
import { useHistory } from "react-router-dom";
import fetchApi from "../../../Apis/fetchApi";
import { useAlert } from "react-alert";
import { NIYUKTI_API } from "../../../Constants/constants";
import AIAssessmentDialog from "./AIAssessmentDialog";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#00203F",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function JobPostingDialog({ jobId, jobTitle }) {
  const history = useHistory();
  const alert = useAlert();

  const [open, setOpen] = useState(true);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [checked, setChecked] = useState(false);
  const [toggleResponse, settoggleResponse] = useState(null);
  const [errorToggleResponse, setErrorToggleResponse] = useState(null);
  const [ openAiAssessmentDialog, setOpenAiAssessmentDialog]=useState(false);

  useEffect(() => {
    if (
      // !toggleResponse?.data?.assessment_status||
      errorToggleResponse
    )
      setChecked(!checked);
    settoggleResponse(null);
    setErrorToggleResponse(null);
  }, [toggleResponse, errorToggleResponse]);

  const handleClose = () => {
    // setOpenAiAssessmentDialog(true);
    setOpen(false);
  };
  

  const handleChange = (event) => {
    setChecked(!checked);
    fetchApi({
      url: `${NIYUKTI_API}api/ai_job/company/assessment_toggle/?job_id=${jobId}`,
      setResponse: settoggleResponse,
      setError: setErrorToggleResponse,
      alert: alert,
      showAlert: true,
    });
  };

  const onEnableAiBotCallingClicked = () => {
    handleClose();
    history.push(`/ai-bot-calling/${btoa(jobId)}/${btoa(jobTitle)}`);
  };
  const onEnableAiAssessmentClicked = () => {
    setOpenAiAssessmentDialog(true);
    // setOpen(false);
   
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            background:
              "linear-gradient(92.55deg, #172768 -7.8%, #384FC2 100%)",
            height: "150px",
            width: "100%",
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: "5px", right: "5px" }}
          >
            <CancelIcon sx={{ color: "#fff" }} />
          </IconButton>
          <VerifiedIcon sx={{ color: "#fff", fontSize: "50px", mb: 1 }} />
          <Typography
            sx={{
              fontSize: "16px",
              color: "#fff",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Congratulations!!
            <br />
            You have posted the job successfully.
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "600",
              mt: 1,
              mb: 1,
            }}
          >
            Try our new <span style={{ color: "#203281" }}>AI Featured</span>{" "}
            and get ahead in recruiting
          </Typography>

          <Card
            sx={{
              display: "flex",
              color: "text.secondary",
              justifyContent: "space-between",
              "& svg": {
                m: 1,
              },
              "& hr": {
                mx: 0.5,
                mt: "10%",
                mb: "10%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img src={vactorone} />
              {Leftdata.map((item) => (
                <div style={{ marginTop: "15px" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    {item.heading}
                  </Typography>

                  <ul style={{ margin: 0, paddingLeft: 20 }}>
                    {item.info.map((info) => (
                      <li>
                        <Typography sx={{ fontSize: "12px", color: "#494949" }}>
                          {info}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}

              <Box
                // sx={{
                //   border: "1px solid",
                //   borderColor: checked === true ? "#00203F" : "#6C757D",
                //   height: "40px",
                //   pl: 2,
                //   pr: 2,
                //   borderRadius: 1,
                //   display: "flex",
                //   justifyContent: "center",
                //   alignItems: "center",
                //   mt: 2,
                //   mb: 4,
                // }}
              >
                {/* <span
                  style={{
                    fontSize: "14px",
                    color: checked === true ? "#00203F" : "#6C757D",
                    fontWeight: "600",
                    marginRight: 10,
                  }}
                >
                  Enable AI Assessment
                </span> */}
                 <Button
                variant="contained"
                color="primary"
                sx={{
                  fontWeight: "600",
                  boxShadow: "none",
                  fontSize: "14px",
                  mt: 2,
                  mb: 2,
                  height: "40px",
                }}
                onClick={onEnableAiAssessmentClicked}
              >
                Enable AI Assessment
              </Button>
              {openAiAssessmentDialog && (
  <AIAssessmentDialog
    openAiAssessmentDialog={openAiAssessmentDialog}
    setOpenAiAssessmentDialog={setOpenAiAssessmentDialog}
    jobId={jobId}
  />
)}
                {/* <AntSwitch checked={checked} onChange={handleChange} /> */}
              </Box>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img src={vactortwo} />
              {Rightdata.map((item) => (
                <div style={{ marginTop: "15px" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    {item.heading}
                  </Typography>

                  <ul style={{ margin: 0, paddingLeft: 20 }}>
                    {item.info.map((info) => (
                      <li>
                        <Typography sx={{ fontSize: "12px", color: "#494949" }}>
                          {info}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              <Button
                variant="contained"
                color="primary"
                sx={{
                  fontWeight: "600",
                  boxShadow: "none",
                  fontSize: "14px",
                  mt: 2,
                  mb: 2,
                  height: "40px",
                }}
                onClick={onEnableAiBotCallingClicked}
              >
                Enable AI Bot Calling
              </Button>
            </Box>
          </Card>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

const Leftdata = [
  {
    heading: "Enable AI Assessment",
    info: [
      "Improved Accuracy in Screening",
      "Automated Reporting",
      "Behavioral Insights",
    ],
  },
];
const Rightdata = [
  {
    heading: "Enable AI Bot Calling",
    info: [
      "70% Faster Average Handling Time",
      "30% Boost in Agent Efficiency",
      "0 Wait Time or Missed Leads",
    ],
  },
];
