import { CircularProgress, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useMediaQuery } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import OtpInput from "react-otp-input";
import { useHistory, useLocation } from "react-router-dom";

import { PRAVESH_API } from "../../../../Constants/constants";

import fetchApi from "../../../../Apis/fetchApi";

export default function EnterEmail({
  setStep,
  setStep_,
  details,
  phoneValue,
  setEmailVerified,
  setPhoneVerified,
  setSession = () => {},
  fromLoginEmail,
  setFromLoginEmail = () => {},
  fromLoginPhone = () => {},
  setFromLoginPhone,
  email_history,
  phone_history,
  userData,
}) {
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [timerComplete, setTimerComplete] = React.useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const prefilled_email = userData?.data?.user_details.email || ""; //if user refresh the page and then come to basic details the email will remain prefill

  // const prefilled_name=userData?.user_details.full_name ||'';
  const prefilled_phone = userData?.data?.user_details.phone || ""; //if user refresh the page and then come to basic details the phone will remain prefill

  const handleChange = (code) => {
    setCode(code);
    // setOtpForgetPwd(code);
    setCodeError(null);
  };
  const helper2 = (data) => {
    if (data.success) {
      setTimerComplete(false);
      setSeconds(30);
    } else {
      if (data?.data?.code === 2) {
        alert.error(data?.data?.code_text);
      }
    }
  };

  const resendOtp = () => {
    const body = phoneValue
      ? {
          email: email_history || prefilled_email,
          mobile: details?.basic?.phone
            ? details.basic?.phone
            : phone_history || prefilled_phone,
          user_type: "company",
          is_mobile_modify: 1,
        }
      : {
          email: email_history,
          user_type: "company",
        };

    fetchApi({
      url: `${PRAVESH_API}api/resend_otp/`,
      method: "post",
      alert: alert,
      body: JSON.stringify(body),
      //setLoading: setLoadingSubmit,
      callBackFn: helper2,
      loginFlow: true,
    });
  };

  const helper_email = (data) => {
    if (data?.success) {
      setStep(1);
      setEmailVerified(true);
      setSession(false);
      setFromLoginEmail(false);
    }
  };

  const helper = (data) => {
    if (data?.success) {
      setStep(2);
      setPhoneVerified(true);
      setSession(false);
      setFromLoginPhone(false);
      if (data?.data?.user_details) {
        window.sessionStorage.setItem(
          "user_details",
          JSON.stringify({
            user_id: data?.data?.user_details?.user_id,
            company_id: data?.data?.user_details?.company_id,
          })
        );
      }
    }
  };
  const validateDataOtp = () => {
    if (!code) {
      setCodeError("Please Enter Your Verification Code");
      return;
    }
    if (code && code?.length < 4) {
      setCodeError("Please Enter Your Verification Code Correctly");
      return;
    }

    const body = phoneValue
      ? {
          email: email_history || prefilled_email,
          mobile: details?.basic?.phone
            ? details.basic?.phone
            : phone_history || prefilled_phone,
          user_type: "company",
          otp: code,
          is_mobile_modify: 1,
        }
      : {
          email: email_history || prefilled_email,
          user_type: "company",
          otp: code,
        };

    fetchApi({
      url: `${PRAVESH_API}company_saas/new/login/validate/`,
      method: "post",
      alert: alert,
      body: JSON.stringify(body),
      setLoading: setLoadingSubmit,
      callBackFn: phoneValue ? helper : helper_email,
      loginFlow: true,
      alert: alert,
    });
  };

  const [seconds, setSeconds] = React.useState(30);
  // const editEmail = useContext(UserContext);

  React.useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div>
      <Typography
        variant="h4"
        style={{
          textAlign: "center",
          fontWeight: "400",
        }}
      >
        Enter OTP
      </Typography>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            fontWeight: "500",
            padding: 12,
          }}
        >
          <span style={{ color: "#707070" }}>Sent to</span>{" "}
          <span style={{ color: "#00162c" }}>
            {phoneValue
              ? details?.basic?.phone || phone_history || prefilled_phone
              : details?.basic?.email || email_history || prefilled_email}
          </span>
        </Typography>
        <EditIcon
          style={{
            color: "#00162c",
            fontSize: "18px",
            cursor: "pointer",
            marginLeft: "-7px",
          }}
          onClick={() => {
            phoneValue ? setStep_(1) : history.push({ pathname: "/" });
          }}
        />
      </div>
      <div>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div>
              <OtpInput
                value={code}
                onChange={(e) => handleChange(e)}
                numInputs={4}
                separator={<span style={{ width: "20px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  border: ".5px solid #a5a5a5",
                  borderRadius: "8px",
                  width: "54px",
                  height: "54px",
                  fontSize: "20px",
                  fontFamily: "gilRoy",
                  // color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                }}
                focusStyle={{
                  border: "1px solid #CFD3DB",
                  outline: "none",
                }}
              />
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  padding: 12,
                }}
              >
                <span style={{ color: "#ed1111" }}> </span>{" "}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  textAlign: "center",
                  fontWeight: "500",
                  padding: 12,
                }}
              >
                <span
                  style={
                    /* timerComplete */ !seconds
                      ? { color: "#00162c", cursor: "pointer" }
                      : { color: "#707070" }
                  }
                  onClick={() => {
                    if (/* timerComplete */ !seconds) resendOtp();
                  }}
                >
                  Resend OTP
                </span>
                {
                  /* !timerComplete &&  */ seconds ? (
                    <span style={{ color: "#707070" }}>{""}&nbsp; in </span>
                  ) : (
                    <></>
                  )
                }
                <span style={{ color: "#00162c" }}>
                  {
                    /* !timerComplete && */ seconds ? (
                      // <Countdown
                      //   date={Date.now() + 30000}
                      //   onComplete={() => setTimerComplete(true)}
                      //   renderer={(props) => (
                      //     <div>
                      //       <b>{props.seconds + " sec"}</b>
                      //     </div>
                      //   )}
                      // />
                      <div>{seconds + " sec"}</div>
                    ) : (
                      <></>
                    )
                  }
                </span>
              </Typography>
            </div>
          </Grid>
          {/*    <Grid item xs={12} sm={12} style={{ display: "grid" }}>
            <CustomTextField
              size="small"
              label="Enter Code"
              variant="outlined"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                setCodeError("");
              }}
              error={codeError ? true : false}
              helperText={codeError ? codeError : ""}
            />
          </Grid> */}
          <Grid item xs={12} style={{ display: "grid" }}>
            {" "}
            <Button
              variant="contained"
              sx={{
                display: "flex",
                borderRadius: "8px",
                boxShadow: "none",
                height: "40px",
                fontWeight: "400",
                fontSize: "15px",
              }}
              onClick={() => {
                //  validateDataOtp()
                //  phone ? setStep(2) : setStep(1)
                validateDataOtp();
              }}
              color="primary"
            >
              {loadingSubmit ? (
                <CircularProgress style={{ color: "#fff" }} size={18} />
              ) : (
                " Verify"
              )}
            </Button>
            <Grid item xs={12} mt={2} sx={{ textAlign: "center" }}>
              {phoneValue ? "OTP sent to your number" : ""}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
