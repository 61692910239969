import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  IconButton,
  Typography,
  Switch,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  CircularProgress,
} from "@mui/material";
import { NIYUKTI_API } from "../../../Constants/constants";
import a1 from "../../../Assets/Images/png/a1.png";
import a2 from "../../../Assets/Images/png/a2.png";
import a3 from "../../../Assets/Images/png/a3.png";
import a4 from "../../../Assets/Images/png/a4.png";
import e1 from "../../../Assets/Images/png/e1.png";
import e2 from "../../../Assets/Images/png/e2.png";
import e3 from "../../../Assets/Images/png/e3.png";
import e4 from "../../../Assets/Images/png/e4.png";
import fetchApi from "../../../Apis/fetchApi";
import { useAlert } from "react-alert";

// const AntSwitch = styled(Switch)(({ theme }) => ({
//   width: 28,
//   height: 16,
//   padding: 0,
//   display: "flex",
//   "&:active": {
//     "& .MuiSwitch-thumb": {
//       width: 15,
//     },
//     "& .MuiSwitch-switchBase.Mui-checked": {
//       transform: "translateX(9px)",
//     },
//   },
//   "& .MuiSwitch-switchBase": {
//     padding: 2,
//     "&.Mui-checked": {
//       transform: "translateX(12px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         opacity: 1,
//         backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#00203F",
//       },
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
//     width: 12,
//     height: 12,
//     borderRadius: 6,
//     transition: theme.transitions.create(["width"], {
//       duration: 200,
//     }),
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor:
//       theme.palette.mode === "dark"
//         ? "rgba(255,255,255,.35)"
//         : "rgba(0,0,0,.25)",
//     boxSizing: "border-box",
//   },
// }));

export default function AIAssessmentDialog({
  openAiAssessmentDialog,
  setOpenAiAssessmentDialog,
  jobId,
  currentRow = {},
  enableAiAutoAssessmentHelper = () => {},
  // currentRowAutoAssessment
}) {
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    // width: "250px",
    backgroundColor: assessmentType === "EDMYST" ? "#FFF3CE" : "#F1F6FF",
    color: theme.palette.primary.main,
    borderRadius: "30px",
    height: "45px",
    "& .MuiToggleButtonGroup-grouped": {
      margin: theme.spacing(0.5),
      border: 0,
      "&.Mui-disabled": {
        border: 0,
      },
      "&:not(:first-of-type)": {
        borderRadius: "30px",
      },
      "&:first-of-type": {
        borderRadius: "30px",
      },
    },
  }));

  const NewToggleButton = styled(ToggleButton)(({ theme }) => ({
    margin: 0,
    // width: "175px",
    color: theme.palette.primary.main,
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "#fff",
      background:
        assessmentType === "EDMYST"
          ? "linear-gradient(180deg, #E4AD02 3.75%, #FFD556 100%)"
          : theme.palette.primary.main,

      // boxShadow: "0px 5px 7px 0px #5243C23B",
    },
  }));

  const alert = useAlert();

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("xs");

  const [assessmentType, setAssessmentType] = useState(
    currentRow?.auto_ai_assessment_type || "EDMYST"
  );

  // const [checked, setChecked] = useState(
  //   currentRow?.auto_ai_assessment !== undefined
  //     ? currentRow?.auto_ai_assessment
  //     : false
  // );
  // const [toggleResponse, settoggleResponse] = useState(null);
  // const [errorToggleResponse, setErrorToggleResponse] = useState(null);

  // useEffect(() => {
  //   if (toggleResponse || errorToggleResponse) {
  //     if (
  //       currentRow?.auto_ai_assessment === undefined
  //         ? !toggleResponse?.data?.assessment_status || errorToggleResponse
  //         : errorToggleResponse
  //     )
  //       setChecked(!checked);
  //   }
  //   settoggleResponse(null);
  //   setErrorToggleResponse(null);
  // }, [toggleResponse, errorToggleResponse]);

  const handleClose = () => {
    setOpenAiAssessmentDialog(false);
    // setChecked(false);
  };

  const [loader, setLoader] = useState(false);
  const handleGenerateAssessment = () => {
    // if (currentRow?.auto_ai_assessment !== undefined || !checked) {
    //   setChecked(!checked);
    const url =
      assessmentType === "EDMYST"
        ? `${NIYUKTI_API}api/edmyst_ai/create_assessment/?job_id=${
            currentRow?.id || currentRow?.job_id||jobId
          }`
        : `${NIYUKTI_API}api/ai_job/company/assessment_toggle/?job_id=${
            currentRow?.id || currentRow?.job_id||jobId
          }`;
    fetchApi({
      url: url,
      setLoading: setLoader,
      // setResponse: settoggleResponse,
      // setError: setErrorToggleResponse,
      alert: alert,
      showAlert: true,
      callBackFn: () => enableAiAutoAssessmentHelper(assessmentType),
    });
    // }
  };

  const handleTabChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAssessmentType(newAlignment);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openAiAssessmentDialog}
        onClose={loader ? () => {} : handleClose}
        sx={{
          // background: "#000",
          "& .MuiPaper-root": {
            background: "#000",
            background:
              assessmentType === "EDMYST"
                ? "linear-gradient(180deg, #FFF9E7 -22.05%, #FFFFFF 76.06%)"
                : "#fffz",
          },
          "& .MuiBackdrop-root": {
            // backgroundColor: "transparent",
          },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <IconButton
            onClick={handleClose}
            color="primary"
            sx={{ position: "absolute", top: "5px", right: "5px" }}
            disabled={loader}
          >
            <CancelIcon />
          </IconButton>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginTop: "30px",
                padding: 10,
                borderRadius: "30px",
                backgroundColor:
                  assessmentType === "EDMYST" ? "#FFF3CE" : "#F1F6FF",
              }}
            >
              <StyledToggleButtonGroup
                color="primary"
                value={assessmentType}
                exclusive
                onChange={handleTabChange}
                aria-label="Platform"
                disabled={loader}
              >
                <NewToggleButton
                  style={{
                    margin: 0,
                    paddingLeft: 20,
                    paddingRight: 20,
                    borderRadius: "30px",
                    fontFamily: "gilRoy",
                  }}
                  value="EDMYST"
                >
                  Elite AI Assessment
                </NewToggleButton>
                <NewToggleButton
                  style={{
                    margin: 0,
                    paddingLeft: 20,
                    paddingRight: 20,
                    borderRadius: "30px",
                    fontFamily: "gilRoy",
                  }}
                  value="INHOUSE"
                >
                  AI Assessment
                </NewToggleButton>
              </StyledToggleButtonGroup>
            </div>
            {assessmentType === "EDMYST" ? (
              <Box
                sx={{
                  px: 4,
                }}
              >
                {/* <img
                  src={vactorTwo}
                  style={{ marginTop: 20, height: "200px" }}
                /> */}
                {Rightdata.map((item) => (
                  <div style={{ marginTop: "15px" }}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        mb: 1,
                        textAlign: "left",
                        background:
                          "linear-gradient(180deg, #FFBE00 2.5%, #FFD549 77.5%)",
                        WebkitBackgroundClip: "text",
                        backgroundClip: "text",
                        color: "transparent",
                      }}
                    >
                      {item.heading}
                    </Typography>

                    <div style={{ margin: 0 }}>
                      {item.info.map((info) => (
                        <div
                          style={{
                            // display: "flex",
                            // alignItems: "center",
                            marginBottom: "10px",
                            marginTop: "20px",
                          }}
                        >
                          <img src={info?.img} style={{}} />
                          <Typography
                            color="primary"
                            sx={{ fontSize: "14px", fontWeight: "600" }}
                          >
                            {info.title}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px", color: "#485E74" }}
                          >
                            {info.des}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {!currentRow?.auto_ai_assessment ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          mt: 2,
                          boxShadow: "none",
                          mb: 4,
                          width: "192px",
                          height: "40px",
                        }}
                        onClick={handleGenerateAssessment}
                      >
                        {loader ? (
                          <CircularProgress size={18} sx={{ color: "white" }} />
                        ) : (
                          "Generate Assessment"
                        )}
                      </Button>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            ) : (
              <Box sx={{ px: 4 }}>
                {Leftdata.map((item) => (
                  <div style={{ marginTop: "15px" }}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        mb: 1,
                        textAlign: "left",
                      }}
                    >
                      {item.heading}
                    </Typography>

                    <div style={{ margin: 0 }}>
                      {item.info.map((info) => (
                        <div
                          style={{
                            // display: "flex",
                            // alignItems: "center",
                            marginBottom: "10px",
                            marginTop: "20px",
                          }}
                        >
                          <img src={info?.img} style={{}} />
                          <Typography
                            color="primary"
                            sx={{ fontSize: "14px", fontWeight: "600" }}
                          >
                            {info.title}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px", color: "#485E74" }}
                          >
                            {info.des}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}

                {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  <Box
                    sx={{
                      border: "1px solid ",
                      borderColor: checked === true ? "#00203F" : "#6C757D",
                      height: "40px",
                      pl: 2,
                      pr: 2,
                      borderRadius: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                      mb: 4,
                      width: "fit-content",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        color: checked === true ? "#00203F" : "#6C757D",
                        fontWeight: "600",
                        marginRight: 10,
                      }}
                    >
                      Enable AI Assessment
                    </span>
                    <AntSwitch checked={checked} onChange={handleChange} />
                  </Box>
                </Box> */}

                {!currentRow?.auto_ai_assessment ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        mt: 2,
                        boxShadow: "none",
                        mb: 4,
                        width: "192px",
                        height: "40px",
                      }}
                      onClick={handleGenerateAssessment}
                    >
                      {loader ? (
                        <CircularProgress size={18} sx={{ color: "white" }} />
                      ) : (
                        "Generate Assessment"
                      )}
                    </Button>
                  </Box>
                ) : null}
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

const Leftdata = [
  {
    heading: "AI Assessment",
    info: [
      {
        img: e1,
        title: "Smart Screening with Precision",
        des: "Uses advanced AI to accurately evaluate candidates by their skills to the job requirements.",
      },
      {
        img: e2,
        title: "Interactive Video-Based Assessment ",
        des: "AI-powered chat interview dynamically assesses candidates using job-specific questions, capturing verbal and video responses. ",
      },
      {
        img: e3,
        title: "Comprehensive Behavioral Analysis",
        des: "Offers detailed insights into candidates' personalities through verbal response analysis for a holistic evaluation.",
      },
      {
        img: e4,
        title: "Integrity-Driven Proctoring",
        des: "Ensures fairness by recording the candidate's video and screen activity, with auto-submit activated after multiple tab switches.",
      },
    ],
  },
];

const Rightdata = [
  {
    heading: "Elite AI Assessment",
    info: [
      {
        img: a1,
        title: "Comprehensive Skill and Behavioral Evaluation",
        des: "Delivers an in-depth analysis of technical, aptitude, communication, and writing skills alongside advanced behavioral analytics.",
      },
      {
        img: a2,
        title: "Premium Reporting Dashboard with Actionable Insights",
        des: "Offers a visually enhanced analytics interface for quick interpretation, role-specific insights, and actionable data to streamline hiring decisions.",
      },
      {
        img: a3,
        title: "Personalized Feedback and Growth Opportunities",
        des: "Highlights strengths and areas for improvement, providing actionable insights for growth and a future-ready workforce",
      },
      {
        img: a4,
        title: "Integrity-Driven Proctoring and Trust Scoring",
        des: "A proprietary Trust Score highlights potential violations ensuring secure and fair assessments.",
      },
    ],
  },
];
