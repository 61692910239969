import React, { useEffect, useState } from "react";
import { alpha, styled, useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Radio,
  Button,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { CustomTextField } from "../../Common/InputText/InputField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useAlert } from "react-alert";
import moment from "moment";
import InsertInvitationRoundedIcon from "@mui/icons-material/InsertInvitationRounded";
import collegeLogo from "../../../Assets/Images/png/collegeLogo.png";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function NewCollegeDetails({
  clg,
  getDegreeAndCourses,
  loading,
  setCollegeList,
  collegeList,
  setPostJobData,
  postJobData,
  setCollege,
  college,
  source = null,
}) {
  const alert = useAlert();
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);
  const [allSpecializationChange, setAllSpecializationChange] = useState(false);
  const [specializationChange, setSpecializationChange] = useState(false);
  const initialStateMonitor = {
    backlog: false,
    applyStartDate: false,
    applyEndDate: false,
  };
  const [monitorChange, setMonitorChange] = useState(initialStateMonitor);
  const [selectedValue, setSelectedValue] = useState("b");

  useEffect(() => {
    if (!college) return;

    const currentCollege = collegeList[college];
    let allColleges = postJobData?.Colleges?.college;
    let education_details = currentCollege?.education_details;
    let preferences = [];
    if (currentCollege?.select_all) preferences = education_details;
    else {
      education_details?.forEach((degree) => {
        let temp = {
          degree_id: degree?.degree_id,
          degree_name: degree?.degree_name,
          specialization: [],
        };
        if (degree?.select_all) {
          preferences?.push(degree);
        } else {
          let found = false;
          degree?.specialization?.forEach((specialization) => {
            if (specialization?.selected) {
              temp?.specialization?.push(specialization);
              found = true;
            }
          });
          if (found) preferences?.push(temp);
        }
      });
    }
    let newCollege = {
      college_id: currentCollege.college_id,
      college_name: currentCollege.college_name,
      college_logo: currentCollege?.college_logo,
      preferences: preferences,
      apply_start_date: collegeList[college].apply_start_date,
      apply_end_date: collegeList[college].apply_end_date,
      backlog: collegeList[college].backlog,
      backlog_no: collegeList[college].backlog_number,
      select_all: currentCollege.select_all,
    };
    let collegeExist = false;
    allColleges?.forEach((clg) => {
      if (clg.college_id == college) {
        //college already in the list
        collegeExist = true;
        return;
      }
    });
    if (collegeExist) {
      if (
        !specializationChange &&
        !allSpecializationChange &&
        !monitorChange?.backlog &&
        !monitorChange?.applyEndDate &&
        !monitorChange?.applyStartDate
      )
        return;
      else {
        var index = allColleges?.findIndex((x) => x?.college_id === college);
        allColleges?.splice(index, 1);
      }
    }

    //college is not in the list
    allColleges?.push(newCollege);
    setPostJobData({
      ...postJobData,
      Colleges: {
        ...postJobData?.Colleges,
        college: allColleges,
      },
    });

    setAllSpecializationChange(false);
    setSpecializationChange(false);
    setMonitorChange(initialStateMonitor);
    setCollege();
  }, [college]);

  const handleExpandClick = (clgId, clgName) => {
    if (expanded === false) {
      if (clg?.education_details?.length === 0)
        getDegreeAndCourses(clgId, clgName);
    }
    setExpanded(!expanded);
  };

  const handleSelectAllCourses = (event) => {
    var key = event.target.value;
    var newCollegeList = collegeList;
    updateCollegeListAllCourses(key, !newCollegeList[key].select_all);
  };

  const updateCollegeListAllCourses = (college_id, value) => {
    var newCollegeList = collegeList;
    var newAllSelectVal = value;
    newCollegeList[college_id].select_all = newAllSelectVal;
    newCollegeList[college_id].education_details.forEach((education) => {
      education.specialization.forEach((spec) => {
        spec.selected = newAllSelectVal;
      });
      education.select_all = newAllSelectVal;
    });
    setAllSpecializationChange(true);
    setSpecializationChange(true);
    setCollegeList({ ...newCollegeList });
  };

  const handleSelectAllSpecialization = (event, college_id) => {
    var degree_id = event.target.value;
    var newCollegeList = collegeList;
    var newDegreeObj = newCollegeList[college_id].education_details.filter(
      (data) => data.degree_id === parseInt(degree_id)
    )[0];
    var allDegreesChecked = true;
    newDegreeObj.specialization.forEach((spec) => {
      spec.selected = !newDegreeObj.select_all;
    });
    setAllSpecializationChange(true);

    newDegreeObj.select_all = !newDegreeObj.select_all;
    newCollegeList[college_id].education_details.forEach((education) => {
      if (education.select_all === false) allDegreesChecked = false;
    });
    newCollegeList[college_id].select_all = allDegreesChecked;
    setCollegeList({ ...newCollegeList });
  };
  const handleSelectSingleSpecialization = (event, college_id, degree_id) => {
    var specialization_id = event.target.value;
    var newCollegeList = collegeList;
    var newDegreeObj = newCollegeList[college_id].education_details.filter(
      (data) => data.degree_id == parseInt(degree_id)
    )[0];
    setSpecializationChange(true);
    var allSpecializationChecked = true;
    var allDegreesChecked = true;
    newDegreeObj.specialization.forEach((spec) => {
      if (spec.specialization_id === parseInt(specialization_id))
        spec.selected = !spec.selected;
      if (spec.selected === false) allSpecializationChecked = false;
    });
    newDegreeObj.select_all = allSpecializationChecked;
    newCollegeList[college_id].education_details.forEach((education) => {
      if (education.select_all === false) allDegreesChecked = false;
    });
    newCollegeList[college_id].select_all = allDegreesChecked;
    setCollegeList({ ...newCollegeList });
    setPostJobData({ ...postJobData });
  };

  const handleSelectStartDate = (date, college_id) => {
    var newCollegeList = collegeList;
    newCollegeList[college_id].apply_start_date =
      moment(date).format("YYYY-MM-DD");
    setCollegeList({ ...newCollegeList });
  };
  const handleSelectEndDate = (date, college_id) => {
    var newCollegeList = collegeList;
    newCollegeList[college_id].apply_end_date =
      moment(date).format("YYYY-MM-DD");
    setCollegeList({ ...newCollegeList });
  };

  const handleBacklog = (e, college_id) => {
    var newCollegeList = collegeList;
    newCollegeList[college_id].backlog = e.target.checked;

    setCollegeList({ ...newCollegeList });
  };

  const handleBacklogNumber = (e, college_id) => {
    var newCollegeList = collegeList;
    newCollegeList[college_id].backlog_number = e.target.value;
    setCollegeList({ ...newCollegeList });
  };

  const addSelectedCollege = (college_id, college_name) => {
    var newCollegeList = collegeList;
    var newDegreeObj = newCollegeList[college_id]?.education_details;
    var specialization_selected = false;
    if (newDegreeObj?.select_all) specialization_selected = true;
    else {
      alert.success(college_name + " Added Successfully");
      newDegreeObj?.forEach((dets) => {
        if (dets?.select_all) {
          specialization_selected = true;
        }
        dets?.specialization?.forEach((spec) => {
          if (spec.selected) {
            specialization_selected = true;
          }
        });
      });
    }
    if (specialization_selected) {
      setCollege(college_id);
      setExpanded(false);
    } else alert.error("Please Select Atleast One Course or Specialization");
  };

  const checkDisabled = (clg) => {
    var disabling = true;
    if (collegeList[clg]?.education_details?.length > 0) {
      disabling = true;
      collegeList[clg]?.education_details?.map((clg_edu, id) =>
        clg_edu?.specialization?.map((spec, ind) => {
          if (spec.selected === true) {
            disabling = false;
          }
        })
      );
    } else disabling = false;

    return disabling;
  };

  return (
    <>
      <Card
        sx={{
          boxShadow: "0px 0px 4px 0px #00000040",
          borderRadius: "8px",
          mb: 2,

          backgroundColor:
            (postJobData.college_id_list.checkAll ||
              postJobData?.Colleges?.college?.find((college) =>
                college?.college_id === clg?.college_id ? true : false
              )) &&
            alpha(theme.palette.primary.main, 0.1),
        }}
      >
        <CardActions disableSpacing>
          <Box>
            <Grid container spacing={2}>
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    width: 130,
                    height: 65,
                    borderRadius: "8px",
                    border: "1px solid #B0BEC5",
                    [theme.breakpoints.only("xs")]: {
                      width: 55,
                      height: 55,
                    },
                  }}
                >
                  <img
                    style={{
                      margin: "auto",
                      //  display: "block",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                    alt="complex"
                    src={clg?.college_logo ? clg.college_logo : collegeLogo}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                sm
                container
              >
                <Grid
                  item
                  xs
                  container
                  direction="column"
                  spacing={2}
                  style={{ display: "contents" }}
                >
                  <Box
                    sx={{
                      padding: "5px",
                      display: "flex",
                      // marginTop: "15px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      paddingLeft: "20px",
                      marginTop: "10px",
                      [theme.breakpoints.only("xs")]: {
                        marginTop: "5px",
                        paddingLeft: "10px",
                      },
                    }}
                  >
                    <Typography
                      variant="h6"
                      className="collegeheading"
                      sx={{
                        [theme.breakpoints.only("xs")]: {
                          fontSize: "14px",
                        },
                      }}
                    >
                      {clg?.college_name}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "5px",
                        marginBottom: "10px",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#6C757D",
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "12px",
                          },
                        }}
                      >
                        {clg?.college_location}
                      </Typography>
                    </div>
                    {source === "collegeRecomendation" && (
                      <div
                        style={{
                          display: "flex",

                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <InsertInvitationRoundedIcon
                            sx={{ color: "#6C757D" }}
                          />{" "}
                          <Typography
                            variant="body2"
                            sx={{ color: "#6C757D", ml: 2 }}
                          >
                            Start Date:{" "}
                            {clg?.apply_start_date
                              ? clg?.apply_start_date
                              : "NA"}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "100px",
                          }}
                        >
                          <InsertInvitationRoundedIcon
                            sx={{ color: "#6C757D" }}
                          />{" "}
                          <Typography
                            variant="body2"
                            sx={{ color: "#6C757D", ml: 2 }}
                          >
                            End Date:{" "}
                            {clg?.apply_end_date ? clg?.apply_end_date : "NA"}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "100px",
                          }}
                        >
                          <InsertInvitationRoundedIcon
                            sx={{ color: "#6C757D" }}
                          />{" "}
                          <Typography
                            variant="body2"
                            sx={{ color: "#6C757D", ml: 2 }}
                          >
                            Backlog: {clg?.backlog ? "Allowed" : "NA"}
                          </Typography>
                        </div>
                      </div>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <ExpandMore
            expand={expanded}
            disabled={postJobData.college_id_list.checkAll}
            onClick={() => {
              handleExpandClick(clg?.college_id, clg?.college_name);
            }}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
          sx={{
            [theme.breakpoints.only("xs")]: {
              ml: 2,
              mr: 2,
            },
          }}
        >
          <CardContent
            sx={{
              width: "90%",
              [theme.breakpoints.only("xs")]: {
                width: "100%",
                p: 0,
                m: 0,
              },
            }}
          >
            {loading && clg?.education_details?.length === 0 && (
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop="5%"
                >
                  <CircularProgress />
                </Box>
              </Grid>
            )}
            {!loading && clg.education_details.length === 0 && (
              <Grid item xs={12}>
                <p align="center" style={{ marginTop: "5%" }}>
                  <b>No Courses Available for this college</b>
                </p>
              </Grid>
            )}

            {!loading && clg?.education_details?.length > 0 && (
              <Box
                sx={{
                  pl: 0.5,
                  [theme.breakpoints.only("xs")]: {
                    pl: 0,
                  },
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mb: 1,
                    p: 0,
                    // mt: 2,
                    [theme.breakpoints.only("xs")]: {
                      mt: 1,
                      p: 0,
                    },
                  }}
                >
                  <Grid xs={12} sm={6} md={6} sx={{ display: "grid" }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        label="Application Start Date"
                        disablePast
                        onChange={(date) => {
                          handleSelectStartDate(date, clg.college_id);
                          setMonitorChange({
                            ...monitorChange,
                            applyEndDate: true,
                          });
                        }}
                        value={collegeList[clg.college_id]?.apply_start_date}
                        renderInput={(params) => (
                          <CustomTextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid xs={12} sm={6} md={6} sx={{ display: "grid" }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        label="Application End Date"
                        disablePast
                        onChange={(date) => {
                          handleSelectEndDate(date, clg.college_id);
                          setMonitorChange({
                            ...monitorChange,
                            applyEndDate: true,
                          });
                        }}
                        value={collegeList[clg.college_id]?.apply_end_date}
                        renderInput={(params) => (
                          <CustomTextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid xs={12}>
                    {/* <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            name="gilad"
                            
                            checked={clg?.backlog}
                            onChange={(e) => {
                              handleBacklog(e, clg.college_id);
                              setMonitorChange({
                                ...monitorChange,
                                backlog: true,
                              });
                            }}
                          />
                        }
                        label={
                          <Typography variant="body2">Backlogs Allo</Typography>
                        }
                      />
                    </FormGroup> */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        [theme.breakpoints.only("xs")]: {
                          flexDirection: "column",
                          alignItems: "flex-start",
                        },
                      }}
                    >
                      <Box>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#6C757D",
                            pr: 3,
                            [theme.breakpoints.only("xs")]: {
                              fontSize: "14px",
                            },
                          }}
                        >
                          Backlogs :
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Radio
                            sx={{ flexDirection: "row" }}
                            name="radio-buttons-group"
                            checked={selectedValue == "a"}
                            value="a"
                            onChange={(e) => {
                              handleBacklog(e, clg.college_id);
                              setSelectedValue(e.target.value);
                              setMonitorChange({
                                ...monitorChange,
                                backlog: true,
                              });
                            }}
                          />

                          <Typography
                            variant="body2"
                            sx={{
                              [theme.breakpoints.only("xs")]: {
                                fontSize: "14px",
                              },
                            }}
                          >
                            Allowed
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Radio
                            sx={{ flexDirection: "row" }}
                            name="radio-buttons-group"
                            checked={selectedValue == "b"}
                            value="b"
                            onChange={(e) => {
                              setSelectedValue(e.target.value);
                              setMonitorChange({
                                ...monitorChange,
                                backlog: false,
                              });
                            }}
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              [theme.breakpoints.only("xs")]: {
                                fontSize: "14px",
                              },
                            }}
                          >
                            Not Allowed
                          </Typography>
                          {/* <FormControlLabel
                              value="b"
                              control={<Radio />}
                              label={
                                <Typography
                                  variant="body2"
                                  sx={{
                                    [theme.breakpoints.only("xs")]: {
                                      fontSize: "14px",
                                    },
                                  }}
                                >
                                  Not Allowed
                                </Typography>
                              }
                            /> */}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  {monitorChange?.backlog == true && (
                    <>
                      <Grid xs={12} sm={6} md={6} sx={{ display: "grid" }}>
                        <CustomTextField
                          size="small"
                          label="Enter no of backlogs"
                          onChange={(e) => {
                            handleBacklogNumber(e, clg.college_id);
                            setMonitorChange({
                              ...monitorChange,
                              backlog_number: e.target.value,
                            });
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                {/* <Typography variant="body2">Courses:</Typography> */}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="gilad"
                        checked={clg.select_all ? true : false}
                        onChange={handleSelectAllCourses}
                        value={clg.college_id}
                      />
                    }
                    label={
                      <Typography variant="body2" sx={{ color: "#6C757D" }}>
                        Select all courses
                      </Typography>
                    }
                  />
                </FormGroup>
                <Box sx={{ maxHeight: "30dvh", overflow: "scroll" }}>
                  {clg.education_details.length > 0 &&
                    clg.education_details.map((clg_edu, id) => {
                      return (
                        <CardComponent
                          clg_edu={clg_edu}
                          clg={clg}
                          id={id}
                          handleSelectAllSpecialization={
                            handleSelectAllSpecialization
                          }
                          handleSelectSingleSpecialization={
                            handleSelectSingleSpecialization
                          }
                        />
                      );
                    })}
                </Box>
              </Box>
            )}

            <Box sx={{ mt: 2, pl: 0.3 }}>
              <Button
                color="primary"
                variant="contained"
                sx={{
                  boxShadow: "none",
                  borderRadius: "8px",
                  height: "40px",
                  pl: 3,
                  pr: 3,
                }}
                onClick={() =>
                  addSelectedCollege(clg.college_id, clg.college_name)
                }
                disabled={checkDisabled(clg.college_id)}
              >
                {(source = "collegeRecomendation" ? "Save" : "Add Institute")}
              </Button>
            </Box>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
}

const CardComponent = ({
  clg_edu,
  clg,
  id,
  handleSelectAllSpecialization,
  handleSelectSingleSpecialization,
}) => {
  const [secondexpanded, setSecondExpanded] = React.useState(false);
  const theme = useTheme();
  const handleExpandSecondClick = () => {
    setSecondExpanded(!secondexpanded);
  };

  return (
    <>
      <Card
        sx={{
          border: "1px solid #B0B6BA",
          borderRadius: "8px",
          mb: 2,
          mt: 1,
          boxShadow: "none",
        }}
      >
        <>
          <CardActions disableSpacing sx={{ height: "40px" }}>
            <Box sx={{ pl: 2 }}>
              {" "}
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    name="gilad"
                    checked={clg_edu?.select_all ? true : false}
                    onChange={(event) => {
                      handleSelectAllSpecialization(event, clg?.college_id);
                    }}
                    value={clg_edu?.degree_id}
                  />
                }
                label={
                  <Typography variant="body2" sx={{ color: "#6C757D" }}>
                    {clg_edu?.degree_name}
                  </Typography>
                }
              />
            </Box>
            <ExpandMore
              expand={secondexpanded}
              onClick={handleExpandSecondClick}
              aria-expanded={secondexpanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={secondexpanded} timeout="auto" unmountOnExit>
            <CardContent
              sx={{
                pl: 7,
                pt: 0,
                [theme.breakpoints.only("xs")]: {
                  pl: 5,
                },
              }}
            >
              <FormGroup>
                {clg_edu?.specialization.map((spec, ind) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="gilad"
                        checked={spec.selected ? true : false}
                        onChange={(event) =>
                          handleSelectSingleSpecialization(
                            event,
                            clg.college_id,
                            clg_edu.degree_id
                          )
                        }
                        value={spec.specialization_id}
                      />
                    }
                    label={
                      <Typography variant="body2" sx={{ color: "#6C757D" }}>
                        {spec.specialization_name}
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </CardContent>
          </Collapse>
        </>
      </Card>
    </>
  );
};
