import Close from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
const text = {
  fontSize: "14px",
  color: "#979797",
};

function JobRound({ round, index, totalLength, deleteRound }) {
  return (
    <>
      <Box
        sx={{
          boxShadow: "0px 0px 4px 0px #00000040",
          p: 2,
          borderRadius: "8px",
          position: "relative",
          //height: "116px",
          height: "100px",
          //minWidth: "180px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: "600" }}>
          {`${round?.r_name}`}
          {/* Manager Round 1 */}
        </Typography>
        <Typography sx={text}>
          {round?.duration
            ? `Duration: ${round?.duration} minutes`
            : `Duration: NA`}
          {/* Duration: 60 minutes */}
        </Typography>
        <Typography sx={text}>
          {/* {`Venue: ${round?.venue}`} */}
          {round?.venue ? `Venue: ${round?.venue}` : `Venue: NA`}
        </Typography>
      </Box>
    </>
  );
}

export default JobRound;
