import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { alpha, styled, useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import DegreeCourseSelect from "../../Cards/Post/NewDegreeCoursesSelect";

export default function NewOpenToAll({
  postJobData,
  setPostJobData,
  setDegree,
  setSpecialization,
  handleSelectAllCourses,
  handleSelectAllSpecialization1,
  handleSelectSingleSpecialization1,
  degree,
  specialization,
  specializationLoader,
  renderChips,
}) {
  const theme = useTheme();
  const alert = useAlert();
  const [value, setValue] = React.useState(dayjs("2022-04-07"));
  const [scroll, setScroll] = React.useState("paper");
  const [degreeCourseOpen, setDegreeCourseOpen] = useState(false);
  const handleClickOpenDegreeCourse = (scrollType) => {
    setDegreeCourseOpen(true);
  };
  const handleClickCloseDegreeCourse = () => {
    setDegreeCourseOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (degreeCourseOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [degreeCourseOpen]);

  useEffect(() => {
    if (postJobData?.Colleges?.hiring_type === "OFF CAMPUS") {
      if (postJobData?.OffCampus?.specialization?.length === 0) {
        setDegreeCourseOpen(true);
      }
    }
  }, [postJobData?.Colleges?.hiring_type]);
  return (
    <>
      <Grid container spacing={3} sx={{ pl: 0, pr: 0, pt: 0 }}>
        <Grid item xs={12} sm={12} md={12} style={{ display: "grid" }}>
          <DegreeCourseSelect
            handleClickOpen={handleClickOpenDegreeCourse}
            handleClose={handleClickCloseDegreeCourse}
            postJobData={postJobData}
            open={degreeCourseOpen}
            setPostJobData={setPostJobData}
            allData={
              postJobData?.OffCampus?.allData
                ? Object.keys(postJobData?.OffCampus?.allData)
                : []
            }
            degree={degree}
            specialization={specialization}
            setDegree={setDegree}
            setSpecialization={setSpecialization}
            handleSelectAllCourses={handleSelectAllCourses}
            handleSelectAllSpecialization1={handleSelectAllSpecialization1}
            handleSelectSingleSpecialization1={
              handleSelectSingleSpecialization1
            }
            specializationLoader={specializationLoader}
            scroll={scroll}
            descriptionElementRef={descriptionElementRef}
            renderChips={renderChips}
          />
        </Grid>
      </Grid>
    </>
  );
}
