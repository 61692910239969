import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Quill } from "react-quill";
import getworklogo from "../../../../Assets/Images/png/getwork-logo.png";
import FlashOnRoundedIcon from "@mui/icons-material/FlashOnRounded";
import { Box, Button, CircularProgress, styled, useTheme } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import "./CustomEditor.css";
import { NIYUKTI_API } from "../../../../Constants/constants";
import axios from "axios";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#676565",
    color: "#fff",
    maxWidth: 120,
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: theme.typography.pxToRem(10),
    borderRadius: "4px",
  },
}));

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}
// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);
// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
];

Quill.register(Font, true);
// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};
// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
];
export const PostJobEditor = ({
  className,
  handleClickOpen,
  state,
  setState,
  from1 = "rest",
  setCount = () => {},
  limit = 999999999999999,
  postJobData,
}) => {
  // const [state, setState] = React.useState({ value: null });
  const reactQuillRef = React.useRef();
  const theme = useTheme();
  const [from, setFrom] = useState(from1 ? from1 : "rest");
  const [loading, setLoading] = useState(false);
  const checkCharacterCount = (event) => {
    const unprivilegedEditor = reactQuillRef?.current?.unprivilegedEditor;
    setCount(unprivilegedEditor?.getLength());
    if (
      from === "about" &&
      unprivilegedEditor?.getLength() > 255 &&
      event.key !== "Backspace"
    ) {
      event.preventDefault();
    } else if (
      from === "desc" &&
      unprivilegedEditor?.getLength() > 511 &&
      event.key !== "Backspace"
    ) {
      event.preventDefault();
    }
  };

  const getDescription = async () => {
    if (postJobData?.Details?.job_role) {
      setLoading(true);
      const url = `${NIYUKTI_API}/job/search/job_description/?job_role_id=${postJobData?.Details?.job_role}`;
      const result = await axios.get(url);
      if (result?.success) {
        setState(
          `${result?.data?.job_title} /n ${result?.data?.job_role_name} /n ${result?.data?.description} /n ${result?.data?.requirements}`
        );
      }
      setLoading(false);
    }
  };

  const handleChange = (value) => {
    const unprivilegedEditor = reactQuillRef?.current?.unprivilegedEditor;
    /*  setState(value); */
    if (from === "about" || from === "desc") {
      if (unprivilegedEditor?.getLength() > limit) {
        setState(unprivilegedEditor?.getText().slice(0, limit - 1));
        setCount(limit);
      } else {
        setState(value);
        setCount(unprivilegedEditor?.getLength());
      }
    } else {
      unprivilegedEditor?.getLength() === 1 ? setState("") : setState(value);
    }
  };

  let imgLogoTag = <img src={getworklogo} width="100px" height="100px" />;
  return (
    <Box className="text-editor">
      {/* <EditorToolbar /> */}
      <div
        id="toolbar"
        style={{ borderBottom: "none", position: "relative" }}
        className={`toolbar ${className}`}
      >
        <span className="ql-formats"></span>
        <span className="ql-formats">
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-strike" />
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <button className="ql-indent" value="-1" />
          <button className="ql-indent" value="+1" />
        </span>
        <span className="ql-formats">
          <button className="ql-script" value="super" />
          <button className="ql-script" value="sub" />
          <button className="ql-blockquote" />
          <button className="ql-direction" />
        </span>
        <span className="ql-formats">
          <select className="ql-align" />
          <select className="ql-color" />
          <select className="ql-background" />
        </span>
      </div>

      <ReactQuill
        theme="snow"
        value={state}
        onChange={handleChange}
        label="Job Description"
        onKeyDown={
          from === "about" || from === "desc" ? checkCharacterCount : () => {}
        }
        ref={reactQuillRef}
        placeholder={"Write something awesome..."}
        //modules={{ modules, toolbar: `.${className}` }}
        modules={{ toolbar: `.${className}` }}
        formats={formats}
      />
    </Box>
  );
};
export default PostJobEditor;
