import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import EnterEmailOtp from "./EnterOtp";
import EnterPhone from "./EnterPhone";
import { useTheme, makeStyles } from "@mui/styles";
import EnterOtp from "../../LoginRightSection/EnterOtp";

const useStyles = makeStyles((theme) => ({
  root1: {
    padding: "20px",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0px",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      padding: "0px",
      alignSelf: "flex-end",
      height: "auto",
      margin: 0,
      width: "100%",
    },
  },

  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

export default function VerificationDialog({
  setStep,
  details,
  setDetails,
  phone,
  emailVerified,
  setEmailVerified,
  phoneVerified,
  setPhoneVerified,
  setSession,
  fromLoginEmail,
  setFromLoginEmail,
  fromLoginPhone,
  setFromLoginPhone,
  email_history,
  phone_history,
  userData,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("xs");
  const phoneValue = phone ? phone : emailVerified;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [step_, setStep_] = useState(0);

  function getDialogForm() {
    switch (step_) {
      case 0:
        return (
          <EnterEmailOtp
            setStep={setStep}
            setStep_={setStep_}
            details={details}
            phoneValue={phoneValue}
            setEmailVerified={setEmailVerified}
            setPhoneVerified={setPhoneVerified}
            setSession={setSession}
            fromLoginEmail={fromLoginEmail}
            setFromLoginEmail={setFromLoginEmail}
            fromLoginPhone={fromLoginPhone}
            setFromLoginPhone={setFromLoginPhone}
            email_history={email_history}
            phone_history={phone_history}
            userData={userData}
          />
        );
      case 1:
        return (
          <EnterPhone
            setStep={setStep_}
            details={details}
            setDetails={setDetails}
            email_history={email_history}
            phone_history={phone_history}
            userData={userData}
          />
        );
    }
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperProps={{ classes: { root: classes.root1 } }}
      >
        <DialogContent>{getDialogForm()}</DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
