import { Search } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import {
  alpha,
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  DialogActions,
  FormControlLabel,
  FormGroup,
  InputBase,
  Paper,
  styled,
  useTheme,
  useMediaQuery,
  Hidden,
  DialogContent,
  Typography,
  AppBar,
  Button,
  IconButton,
  Slide,
  Dialog,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import fetchApi from "../../../Apis/fetchApi";
import noData from "../../../Assets/Images/png/openjob.png"; //../../../assets/images/openjob.png
import {
  generateCsvWithoutSpacing,
  getCookie,
  PRAVESH_API,
} from "../../../Constants/constants";
import NewPostJobCollegeFilter from "../../Common/FilterDrawer/NewPostJobCollegeFilter";
import PostJobCollegeFilter from "../../Common/FilterDrawer/PostJobCollegeFilter";
import {
  CustomActionTextField,
  CustomAutoCompletePaper,
} from "../../Common/InputText/InputField";
import PageLoader from "../../Common/PageLoader";
import FilterListIcon from "@mui/icons-material/FilterList";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { makeStyles } from "@mui/styles";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import NewCollegeDetails from "../../Cards/Post/NewCollegeDetails";

const Chiplist = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end",
      top: "unset",
    },
  },
  paper: {
    // make the content full width
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      borderRadius: "20px 20px 0 0",
      height: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      borderRadius: "20px",
    },
  },
}));

export default function NewSelectCourseDialog({
  postJobData,
  setPostJobData,

  openEdit,
  setOpenEdit,
}) {
  const classes = useStyles();
  const alert = useAlert();
  const theme = useTheme();
  const [nextUrl, setNextUrl] = useState(null);
  const [end, setEnd] = useState(false);
  const [collegeList, setCollegeList] = useState({});
  const [newCollegeList1, setNewCollegeList1] = useState([]);
  const [collegeListID, setCollegeListID] = useState([]);
  const [isApiCall, setIsApiCall] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(0);
  const [college, setCollege] = useState();
  const [allCollegeCount, setAllCollegeCount] = useState(0);
  const [applyBtnClicked, setApplyBtnClicked] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [applySearch, setApplySearch] = useState(false);

  const initialFiltersState = {
    eduLevel: [],
    degrees: [],
    courses: [],
    locations: [],
    jobSegment: [],
    maxCtc: null,
  };
  const [filters, setFilters] = useState(initialFiltersState);
  const [page, setPage] = useState(0);
  const [degreeList, setDegreeList] = useState([]);
  const [specializationList, setSpecializationList] = useState([]);
  const [jobSegementList, setJobSegementList] = useState([]); //api/education/degree/

  const educationLevelsObj = {
    Graduation: 3,
    "Post Graduation": 4,
    "Ph.D": 5,
  };

  const handleClickOpen = () => {
    setOpenEdit(true);
  };

  const handleClose = () => {
    setOpenEdit(false);
  };

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [chipData, setChipData] = React.useState([
    { key: 0, label: "B. Tech Computer Science" },
    { key: 1, label: "B. Tech IT" },
    { key: 2, label: "M. Tech Computer Science" },
    { key: 3, label: "M. Tech IT" },
    { key: 4, label: "M. Tech Computer Science" },
  ]);

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const uniqueArray = (arr) => {
    return [...new Set(arr)];
  };
  const generateCsv = (arr) => {
    let str = "";
    arr.forEach((item, index) =>
      arr.indexOf(item) !== arr.length - 1
        ? (str += item.city_id + ",")
        : (str += item.city_id)
    );
    return str;
  };

  const generateCsvJobSegment = (arr) => {
    let str = "";
    arr.forEach((item, index) =>
      arr.indexOf(item) !== arr.length - 1
        ? (str += item.job_segment_id + ",")
        : (str += item.job_segment_id)
    );
    return str;
  };

  const generateCsvqualification = (arr) => {
    let str = "";
    var item_id;
    arr.forEach((item, index) => {
      if (item === "Graduation") item_id = 3;
      if (item === "Post Graduation") item_id = 4;
      if (item === "Ph.D") item_id = 5;
      arr.indexOf(item) !== arr.length - 1
        ? (str += item_id + ",")
        : (str += item_id);
    });

    return str;
  };

  const generateCsvDegrees = (arr) => {
    let str = "";
    arr.forEach((item, index) =>
      arr.indexOf(item) !== arr.length - 1
        ? (str += item.id + ",")
        : (str += item.id)
    );
    return str;
  };
  const generateCsvSpecialization = (arr) => {
    let str = "";
    arr.forEach((item, index) =>
      arr.indexOf(item) !== arr.length - 1
        ? (str += item.specialization_id + ",")
        : (str += item.specialization_id)
    );
    return str;
  };

  const checkQualification = () => {
    if (filters?.eduLevel?.length > 0) {
      return `&qualification_required=${generateCsvqualification(
        filters?.eduLevel
      )}`;
    } else return "";
  };

  const checkDegrees = () => {
    if (filters?.degrees?.length > 0) {
      return `&degree=${generateCsvDegrees(filters?.degrees)}`;
    } else return "";
  };

  const checkSpecialization = () => {
    if (filters?.courses?.length > 0) {
      return `&specialization=${generateCsvSpecialization(filters?.courses)}`;
    } else return "";
  };

  const checkLocation = () => {
    if (filters?.locations?.length > 0) {
      return `&city=${generateCsv(filters?.locations)}`;
    } else return "";
  };
  const checkCTC = () => {
    if (parseInt(filters?.maxCtc) > 0) {
      return `&max_ctc=${parseInt(filters?.maxCtc)}`;
    } else return "";
  };

  const checkJobSegment = () => {
    if (filters?.jobSegment?.length > 0) {
      return `&job_segment=${generateCsvJobSegment(filters?.jobSegment)}`;
    } else return "";
  };

  const checkSearch = () => {
    if (searchQuery) {
      return `&search=${btoa(searchQuery)}`;
    } else return "";
  };

  const getAllColleges = (fromFilter = false) => {
    setLoader(true);

    const urlCollegeRank =
      nextUrl && !fromFilter
        ? nextUrl
        : PRAVESH_API +
          `company_saas/dashboard/get/college/?${checkFilterString()}${checkSearch()}`; //`api/company/new_college_rank/?per_page=8&uuid=${new_uuid}${checkFilterString()}${checkSearch()} //${checkSearch()}${checkLocation()}${checkCTC()}${checkJobSegment()}${checkQualification()}${checkDegrees()}${checkSpecialization()}

    axios
      .get(
        urlCollegeRank,
        // {
        //   company_id: userDetail?.company,
        //   company_skills:
        //     postJobData?.Preferences?.eligibility_criteria?.skills,
        //   company_courses: tempDegreeData,
        //   company_specialization: tempSpecData,
        // },
        {
          //headers: { Authorization: `Token ${getCookie("user_token_test")}` },
          withCredentials: true,
        }
      )
      .then((res) => {
        setIsApiCall(true);
        setLoader(true);

        if (res?.data?.success) {
          setAllCollegeCount(res?.data?.data?.data?.count);
          let temp = {};
          res?.data?.data?.data?.results?.forEach((data) => {
            let details = [];
            data?.education?.forEach((dets) => {
              const { degree_id, degree_name, specialization } = dets;
              const tempObj = {
                degree_id,
                degree_name,
                specialization,
              };
              dets?.specialization?.forEach((spec) => {
                spec.selected = false;
              });
              dets.select_all = false;
              details.push(tempObj);
            });
            temp[data.college_id] = {
              college_name: data?.college_name,
              college_id: data?.college_id,
              college_logo: data?.college_logo,
              college_location: data?.college_location,
              education_details: [],

              select_all: false,
              apply_start_date: null,
              apply_end_date: null,
            };
          });

          if (res?.data?.data?.data?.next) {
            setNextUrl(res?.data?.data?.data?.next);
          } else {
            setEnd(true);
          }

          if (fromFilter) {
            setCollegeList(temp);
            setNewCollegeList1(Object.values(temp));
          } else {
            setCollegeList({ ...collegeList, ...temp });
            let newTemp = [...newCollegeList1];

            setNewCollegeList1([...newTemp, ...Object.values(temp)]);
          }

          setCollegeListID(res?.data?.data?.college_ids);

          setFlag(0);
          setLoader(false);
          setApplyBtnClicked(false);
          setApplySearch(false);
        }
      })
      .catch((err) => {
        setFlag(0);
        setLoader(false);
        setApplyBtnClicked(false);
        setApplySearch(false);
      });
    setIsApiCall(false);
  };

  const checkAllSpecializationsSelected = (
    college_id,
    specialization_array_length,
    degree_id
  ) => {
    var index = postJobData?.Colleges?.college?.findIndex(
      (x) => x.college_id === college_id
    );
    var boole = false;
    if (index !== -1) {
      postJobData?.Colleges?.college[index]?.preferences?.map((item) => {
        if (
          item?.degree_id === degree_id &&
          item?.specialization?.length === specialization_array_length
        )
          boole = true;
      });
    }
    return boole;
  };
  const checkSpecializationSelected = (college_id, spec_id) => {
    var boole = false;

    var index = postJobData?.Colleges?.college?.findIndex(
      (x) => x?.college_id === college_id
    );

    if (index !== -1) {
      postJobData?.Colleges?.college[index]?.preferences.map((item) => {
        item?.specialization?.map((item2) => {
          if (item2?.specialization_id === spec_id) boole = true;
        });
      });
    }
    return boole;
  };

  const getDegreeAndCourses = (clg_id, clg_name) => {
    setLoading(true);

    axios
      .get(
        PRAVESH_API +
          "company_saas/dashboard/get/college_education/?college_id=" +
          clg_id,
        { withCredentials: true }
      )
      .then((res) => {
        setLoading(false);

        if (res?.data?.success && res?.data?.data) {
          let temp = collegeList[clg_id];
          let details = [];
          res?.data?.data.forEach((dets) => {
            const { degree_id, degree_name, specialization } = dets;
            const tempObj = {
              degree_id,
              degree_name,
              specialization,
            };
            dets?.specialization?.forEach((spec) => {
              spec.selected = checkSpecializationSelected(
                clg_id,
                spec.specialization_id
              );
            });
            dets.select_all = checkAllSpecializationsSelected(
              clg_id,
              dets?.specialization?.length,
              dets?.degree_id
            );
            details.push(tempObj);
          });
          collegeList[clg_id].education_details = res?.data?.data;
          setCollegeList({ ...collegeList });
          setNewCollegeList1([...Object.values(collegeList)]);
        } else {
          alert.error("Error Occured");
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Error");
      });
    // }
  };

  const jobSegmentHelper = (data) => {
    setJobSegementList(data?.data);
  };
  const getJobSegmentList = () => {
    fetchApi({
      url: `${PRAVESH_API}api/company/job_segment`, //job/company/job_post/?${CollegeID()}
      setResponse: jobSegmentHelper,
    });
  };

  const getDegreeList = async (edLvlStr) => {
    const res = await axios.get(
      `${PRAVESH_API}api/education/get_degree?education_level=${edLvlStr}`
    );
    try {
      if (res.status === 200) {
        setDegreeList(res?.data?.data);
      }
    } catch (error) {
      console.log("Error");
      throw error;
    }
  };

  const getSpecializationList = async (degreeStr) => {
    const res = await axios.get(
      `${PRAVESH_API}api/education/get_specialization?degree_id=${degreeStr}`
    );

    try {
      if (res.status === 200) {
        setSpecializationList(res?.data?.data);
      }
    } catch (error) {
      console.log("Error");
      throw error;
    }
  };

  const handleEducationLevelChange = (e) => {
    const educationlevel = e.target.value;
    let edLevelsFilterNew = filters?.eduLevel;
    if (edLevelsFilterNew.includes(educationlevel)) {
      ///remove it
      edLevelsFilterNew = edLevelsFilterNew?.filter(
        (edu_lv) => edu_lv !== educationlevel
      );
    } else {
      //add it
      edLevelsFilterNew.push(educationlevel);
    }
    setFilters({ ...filters, eduLevel: edLevelsFilterNew });
  };

  useEffect(() => {
    if (filters?.eduLevel?.length > 0) {
      const edLvlStr = generateCsvqualification(filters?.eduLevel);
      getDegreeList(edLvlStr);
    }
  }, [filters?.eduLevel?.length]);

  useEffect(() => {
    if (filters?.degrees?.length > 0) {
      const degreeStr = generateCsvDegrees(filters?.degrees);
      getSpecializationList(degreeStr);
    }
  }, [filters?.degrees?.length]);

  useEffect(() => {
    if (applyBtnClicked || applySearch) {
      getAllColleges();
    }
  }, [applyBtnClicked, applySearch]);

  useEffect(() => {
    getAllColleges();
    getJobSegmentList();
  }, []);

  const removeCollege = (remove_college) => {
    let allColleges = postJobData?.Colleges?.college;
    let collegeExist = false;
    allColleges?.forEach((clg) => {
      if (clg?.college_id == remove_college?.college_id) {
        //college exists in the list
        collegeExist = true;

        return;
      }
    });
    if (!collegeExist) return;
    //college in the list,remove it

    allColleges = allColleges.filter(
      (clg) => clg?.college_id !== remove_college?.college_id
    );
    setPostJobData({
      ...postJobData,
      Colleges: {
        ...postJobData?.Colleges,
        college: allColleges,
      },
    });
    setCollege();
    updateCollegeListAllCourses(remove_college?.college_id, false);
  };

  const updateCollegeListAllCourses = (college_id, value) => {
    var newCollegeList = collegeList;
    var newAllSelectVal = value;
    newCollegeList[college_id].select_all = newAllSelectVal;
    newCollegeList[college_id]?.education_details?.forEach((education) => {
      education?.specialization?.forEach((spec) => {
        spec.selected = newAllSelectVal;
      });
      education.select_all = newAllSelectVal;
    });
    setCollegeList({ ...newCollegeList });
    setNewCollegeList1([...newCollegeList1]);
  };

  /* ############################################## */
  const [filterData, setFilterData] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterDataError, setFilterDataError] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [clearFilter, setClearFilter] = useState(false);
  const [clickFilterApplyButton, setClickFilterApplyButton] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [oneApiCallMade, setOneApiCallMade] = useState(false);
  const [collegeCategory, setCollegeCategory] = useState(-1);
  const checkFilterString = () => {
    let filterString = "";
    if (selectedFilters && Object.keys(selectedFilters).length > 0)
      Object.keys(selectedFilters).forEach((item) => {
        if (selectedFilters[item]?.length > 0 && item === "organisation") {
          filterString = `${filterString}&${item}=${window.btoa(
            generateCsvWithoutSpacing(selectedFilters[item], "id")
          )}`;
        } else if (selectedFilters[item]?.length > 0) {
          filterString = `${filterString}&${item}=${generateCsvWithoutSpacing(
            selectedFilters[item],
            "id"
          )}`;
        }
      });
    return filterString;
  };

  const getFilters = () => {
    fetchApi({
      url: `${PRAVESH_API}company_saas/dashboard/filter/college/`,
      setResponse: setFilterData,
      setLoading: setFilterLoading,
      setError: setFilterDataError,
    });
  };

  useEffect(() => {
    if (clickFilterApplyButton) {
      setCollegeList({});
      setNewCollegeList1([]);
      setNextUrl(null);
      setEnd(false);
      setAllCollegeCount(0);
      setLoader(true);
      getAllColleges(true);

      setClickFilterApplyButton(false);
    }
  }, [clickFilterApplyButton]);

  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    if (postJobData?.Colleges?.hiring_type === "ON CAMPUS") {
      if (postJobData?.Colleges?.college?.length === 0) {
        setOpenEdit(true);
      }
    }
  }, [postJobData?.Colleges?.hiring_type]);
  useEffect(() => {
    document.body.style.zoom = "100%";
  }, []);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        sx={{
          height: "40px",
          fontSize: "14px",
          borderRadius: "8px",
          boxShadow: "none",
          paddingLeft: "30px",
          paddingRight: "30px",
          marginTop: postJobData?.Colleges?.college?.length > 0 ? "0px" : "0px",
        }}
        onClick={handleClickOpen}
      >
        {postJobData?.Colleges?.college?.length > 0
          ? "Add More Colleges"
          : "Select Colleges"}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        // fullScreen
        open={openEdit}
        onClose={handleClose}
        classes={{ container: classes.root, paper: classes.paper }}
        TransitionComponent={Transition}
        sx={{
          [theme.breakpoints.only("xs")]: {
            // height: 600,
            alignItems: "flex-end",
            // top: "unset",
            top: "64px",
          },
          "& .MuiDialog-paper": {
            borderRadius: "8px",
          },
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          <Box sx={{ height: "100%", position: "relative" }}>
            <Box sx={{ height: "100%" }}>
              <Container
                maxWidth="lg"
                sx={{
                  pl: "0px !important",
                  pr: "0px !important",
                  [theme.breakpoints.only("xs")]: {
                    pl: 0.2,
                    pr: 0.2,
                  },
                }}
              >
                {filterLoading ? (
                  <Box
                    sx={{
                      height: "100dvh",
                      width: "100dvw",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PageLoader />
                  </Box>
                ) : (
                  <Box sx={{ mt: 0 }}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        width: "100%",
                        margin: 0,
                        [theme.breakpoints.only("xs")]: {
                          p: 0.5,
                        },
                      }}
                    >
                      <Grid
                        xs={12}
                        sm={4}
                        md={4}
                        sx={{
                          [theme.breakpoints.only("xs")]: {
                            display: "none",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            borderRadius: "8px",
                            padding: "20px",
                            height: "80dvh",
                            overflow: "scroll",
                            overflowX: "hidden",
                            pt: 0,
                            position: "relative",
                          }}
                        >
                          <div style={{ display: "grid" }}>
                            {" "}
                            <PostJobCollegeFilter
                              filterData={filterData?.data}
                              filterLoading={filterLoading}
                              setSelectedFilters={setSelectedFilters}
                              selectedFilters={selectedFilters}
                              setClickFilterApplyButton={
                                setClickFilterApplyButton
                              }
                              filterDataError={filterDataError}
                              clearFilter={clearFilter}
                              setClearFilter={setClearFilter}
                            />
                          </div>
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={8} md={8}>
                        <Box>
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              width: "100%",
                              margin: 0,
                              [theme.breakpoints.only("xs")]: {
                                p: 0,
                              },
                            }}
                          >
                            <Grid xs={9} sm={12} md={12}>
                              <Paper
                                component="form"
                                sx={{
                                  p: "2px 4px",
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "8px",
                                  height: "37px",
                                  boxShadow: "0px 4px 15px 0px #0000001C",
                                }}
                              >
                                <InputBase
                                  sx={{
                                    ml: 1,
                                    flex: 1,
                                    "& .MuiInputBase-input": {
                                      fontSize: "14px",
                                    },
                                  }}
                                  placeholder="Search Colleges"
                                  value={searchQuery}
                                  onChange={(e) => {
                                    setSearchQuery(e.target.value);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                      e.preventDefault();
                                      setCollegeList({});
                                      setNewCollegeList1([]);
                                      setNextUrl(null);
                                      setAllCollegeCount(0);
                                      setEnd(false);
                                      setLoader(true);
                                      setApplySearch(true);
                                    }
                                  }}
                                />

                                {searchQuery && (
                                  <IconButton
                                    type="button"
                                    aria-label="search"
                                    onClick={() => {
                                      setSearchQuery("");
                                      setCollegeList({});
                                      setNewCollegeList1([]);
                                      setNextUrl(null);
                                      setAllCollegeCount(0);
                                      setEnd(false);
                                      setLoader(true);
                                      setApplySearch(true);
                                    }}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                )}

                                <IconButton
                                  type="button"
                                  aria-label="search"
                                  sx={{ ml: "-10px" }}
                                  onClick={(e) => {
                                    setCollegeList({});
                                    setNewCollegeList1([]);
                                    setNextUrl(null);
                                    setAllCollegeCount(0);
                                    setEnd(false);
                                    setLoader(true);
                                    setApplySearch(true);
                                  }}
                                >
                                  <Search />
                                </IconButton>
                              </Paper>
                            </Grid>
                            <Hidden only={["sm", "md", "lg", "xl"]}>
                              <Grid xs={3}>
                                <MobileFilter
                                  filterData={filterData?.data}
                                  filterLoading={filterLoading}
                                  setSelectedFilters={setSelectedFilters}
                                  selectedFilters={selectedFilters}
                                  setClickFilterApplyButton={
                                    setClickFilterApplyButton
                                  }
                                  filterDataError={filterDataError}
                                  clearFilter={clearFilter}
                                  setClearFilter={setClearFilter}
                                />
                              </Grid>
                            </Hidden>

                            <Grid xs={12} sm={12} md={12} sx={{ pb: 0, pt: 0 }}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size="small"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setPostJobData({
                                            ...postJobData,
                                            college_id_list: {
                                              ...postJobData?.college_id_list,
                                              checkAll: e.target.checked,
                                            },
                                          });
                                        } else {
                                          setPostJobData({
                                            ...postJobData,
                                            college_id_list: {
                                              ...postJobData?.college_id_list,
                                              checkAll: e.target.checked,
                                            },
                                            Colleges: {
                                              ...postJobData?.Colleges,
                                              college: [],
                                            },
                                          });
                                        }
                                      }}
                                      checked={
                                        postJobData?.college_id_list?.checkAll
                                      }
                                    />
                                  }
                                  label={
                                    <Typography variant="body2">
                                      Select All Institutes {allCollegeCount}
                                    </Typography>
                                  }
                                />
                              </FormGroup>
                              {postJobData?.college_id_list?.checkAll && (
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontStyle: "italic",
                                    [theme.breakpoints.only("xs")]: {
                                      fontSize: "12px",
                                    },
                                  }}
                                >
                                  The Apply Start date will be same as Global
                                  Start date. You can also set custom Apply
                                  Start and Apply end date for every college
                                </Typography>
                              )}
                            </Grid>
                            {postJobData?.Colleges?.college?.length > 0 && (
                              <Grid xs={12} sm={12} md={12}>
                                <Paper
                                  sx={{
                                    boxShadow: "none",
                                    display: "flex",
                                    overflowX: "scroll",
                                    flexDirection: "row",
                                    height: "30px",
                                    flexWrap: "nowrap",
                                    p: 0,
                                    mt: 0,
                                    mb: 0,
                                  }}
                                  component="ul"
                                >
                                  {postJobData?.Colleges?.college?.map(
                                    (clg) => (
                                      <Chiplist
                                        key={clg?.college_id}
                                        sx={{ display: "contents" }}
                                      >
                                        <Chip
                                          size="small"
                                          color="primary"
                                          variant="outlined"
                                          sx={{
                                            backgroundColor: alpha(
                                              theme.palette.primary.main,
                                              0.1
                                            ),
                                            mb: 0,
                                            mr: 0.5,
                                          }}
                                          label={clg?.college_name}
                                          deleteIcon={<CloseIcon />}
                                          onDelete={() => {
                                            removeCollege(clg);
                                          }}
                                        />
                                      </Chiplist>
                                    )
                                  )}
                                </Paper>
                              </Grid>
                            )}
                            <Grid xs={12} sm={12} md={12}>
                              <CollegeList
                                setPostJobData={setPostJobData}
                                postJobData={postJobData}
                                collegeList={collegeList}
                                newCollegeList1={newCollegeList1}
                                setNewCollegeList1={setNewCollegeList1}
                                setCollegeList={setCollegeList}
                                allCollegeCount={allCollegeCount}
                                end={end}
                                setEnd={setEnd}
                                isApiCall={isApiCall}
                                setIsApiCall={setIsApiCall}
                                getAllColleges={getAllColleges}
                                getDegreeAndCourses={getDegreeAndCourses}
                                loading={loading}
                                loader={loader}
                                college={college}
                                setCollege={setCollege}
                                setNextUrl={setNextUrl}
                                nextUrl={nextUrl}
                                page={page}
                                setPage={setPage}
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <DialogActions>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <AppBar
                              color="inherit"
                              sx={{
                                boxShadow: "none",
                                position: "relative",

                                [theme.breakpoints.only("sm")]: {
                                  top: "auto",
                                  bottom: 0,
                                  boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
                                  position: "fixed",
                                },
                                [theme.breakpoints.only("xs")]: {
                                  top: "auto",
                                  bottom: 0,
                                  boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
                                  position: "fixed",
                                },
                              }}
                            >
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  width: "100%",
                                  margin: 0,
                                  pl: 1.5,
                                  pr: 1.5,
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Grid
                                  xs={6}
                                  sm={3}
                                  md={3}
                                  sx={{ display: "grid" }}
                                >
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                      height: "40px",
                                      borderRadius: "8px",
                                      boxShadow: "none",
                                    }}
                                    onClick={handleClose}
                                  >
                                    Cancel
                                  </Button>
                                </Grid>
                                <Grid
                                  xs={6}
                                  sm={3}
                                  md={3}
                                  sx={{ display: "grid" }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                      height: "40px",
                                      borderRadius: "8px",
                                      boxShadow: "none",
                                    }}
                                    onClick={
                                      !postJobData?.Colleges?.college?.length >
                                        0 &&
                                      !postJobData?.college_id_list?.checkAll
                                        ? () => {
                                            alert?.error(
                                              "Please Select Atleast one Institute and course"
                                            );
                                          }
                                        : () => {
                                            handleClose();
                                          }
                                    }
                                  >
                                    Submit
                                  </Button>
                                </Grid>
                              </Grid>
                            </AppBar>
                          </Box>
                        </DialogActions>
                      </Grid>
                    </Grid>
                  </Box>
                )}{" "}
              </Container>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const CollegeList = ({
  setPostJobData,
  postJobData,
  collegeList,
  newCollegeList1,
  setNewCollegeList1,
  setCollegeList,
  allCollegeCount,
  setAllCollegeCount,
  end,
  setEnd,
  getAllColleges,
  isApiCall,
  setIsApiCall,
  getDegreeAndCourses,
  loading,
  loader,
  college,
  setCollege,

  page,
  setPage,
}) => {
  const loaderRef = useRef(null);
  const theme = useTheme();
  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting && end !== true && isApiCall) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    if (page > 0 && allCollegeCount > 8 && end !== true) {
      getAllColleges();
    }
  }, [page]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loaderRef.current) observer.observe(loaderRef.current);
  }, [handleObserver]);

  return (
    <>
      <Box
        sx={{
          p: 1,
          height: "55dvh",
          overflow: "scroll",
          overflowX: "hidden",
          [theme.breakpoints.only("xs")]: {
            p: 0.2,
            boxShadow: "none",
            height: "70dvh",
          },
        }}
      >
        {collegeList && newCollegeList1?.length > 0 ? (
          newCollegeList1?.map((clg, i) => {
            return (
              <NewCollegeDetails
                clg={clg}
                getDegreeAndCourses={getDegreeAndCourses}
                loading={loading}
                setCollegeList={setCollegeList}
                collegeList={collegeList}
                setPostJobData={setPostJobData}
                postJobData={postJobData}
                setCollege={setCollege}
                college={college}
                setNewCollegeList1={setNewCollegeList1}
                newCollegeList1={newCollegeList1}
              />
            );
          })
        ) : loader ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "30px auto 30px auto",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div
            style={{
              color: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img
              src={noData}
              alt=""
              style={{ height: "250px", marginTop: "25px" }}
            />
            <Typography color="primary" align="center">
              {" "}
              No data Found. Please Change the filters to get data
            </Typography>
          </div>
        )}

        <div
          ref={loaderRef}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {allCollegeCount > 8 && <CircularProgress />}
        </div>
      </Box>
    </>
  );
};

const MobileFilter = ({
  filterData,
  filterLoading,
  setSelectedFilters,
  selectedFilters,
  setClickFilterApplyButton,
  filterDataError,
  clearFilter,
  setClearFilter,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();

  return (
    <>
      <div>
        <Button
          startIcon={<FilterAltIcon sx={{ color: "#fff" }} />}
          onClick={handleClickOpen}
          variant="contained"
          sx={{
            borderRadius: "8px",
            boxShadow: "none",
            display: "flex",
            height: 40,
            width: "100%",
          }}
        >
          <Typography style={{ fontSize: "12px", color: "#fff" }}>
            Filter
          </Typography>
        </Button>
        <Dialog
          sx={{
            [theme.breakpoints.only("xs")]: {
              alignItems: "flex-end",
              top: "64px",
            },

            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box>
            <NewPostJobCollegeFilter
              handleClose={handleClose}
              filterData={filterData}
              filterLoading={filterLoading}
              setSelectedFilters={setSelectedFilters}
              selectedFilters={selectedFilters}
              setClickFilterApplyButton={setClickFilterApplyButton}
              filterDataError={filterDataError}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
              mobileScreenFilter={true}
              mobileFilterOpen={open}
            />
          </Box>
        </Dialog>
      </div>
    </>
  );
};
