import { Box, Button, Typography } from "@mui/material";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";
import { ReactComponent as NoData } from "../Assets/Images/svg/SmallScreen.svg";
import PageLoader from "../Components/Common/PageLoader";
import DomainDetailError from "../Components/Section/DomainDetailError";
import { HIDE_ON_PATH } from "../Constants/constants";
import useCheckMobileScreen from "../Hooks/useCheckMobileScreen";
import useOnPageChange from "../Hooks/useOnPageChange";
import Layout from "../Layouts/MainLayout/MainLayout";
import PublicLayout from "../Layouts/MainLayout/PublicLayout";
import MergedPostSteppers from "../Components/Section/CompanyPostSection/MergedPostStepper";

const CompanyLayout = React.lazy(() =>
  import("../Layouts/MainLayout/CompanyLayout")
);
const PublicNavbar = React.lazy(() =>
  import("../Components/Navbar/PublicNavbar")
);
const Login = React.lazy(() => import("../Pages/Login"));
const CompanyLogin = React.lazy(() => import("../Pages/CompanyLogin"));
const Assignment = React.lazy(() => import("../Pages/Assignment"));
const AssignmentDetail = React.lazy(() =>
  import("../Pages/Assignment/AssignmentDetail")
);
const CreateAssignments = React.lazy(() =>
  import("../Pages/Assignment/CreateAssignments")
);
//const CompanyLogin = React.lazy(() => import("../Pages/CompanyLogin"));
const CompanyProfile = React.lazy(() => import("../Pages/CompanyProfile"));
const MyProfile = React.lazy(() => import("../Pages/CompanyProfile/MyProfile"));
const Contact = React.lazy(() => import("../Pages/ContactUs"));
const EmployeeDetails = React.lazy(() =>
  import("../Pages/EmployeeDetails/EmployeeDetailsNew")
);
const PublicCandidateDetails = React.lazy(() =>
  import("../Pages/EmployeeDetails/PublicCandidateDetails")
);
const GetworkCreadit = React.lazy(() => import("../Pages/GetworkCredit"));
const HelpandSupport = React.lazy(() => import("../Pages/HelpandSupport"));
const Info = React.lazy(() => import("../Pages/HelpandSupport/Info"));
const Question = React.lazy(() => import("../Pages/HelpandSupport/Question"));

const Home = React.lazy(() => import("../Pages/Home"));
const FeaturedColleges = React.lazy(() =>
  import("../Pages/Home/FeaturedColleges")
);
const Recommendation = React.lazy(() => import("../Pages/Home/Recommendation"));
const Interview = React.lazy(() => import("../Pages/Interview"));
const CreateInterview = React.lazy(() =>
  import("../Pages/Interview/CreateInterview")
);
const InterviewDetails = React.lazy(() =>
  import("../Pages/Interview/InterviewDetail")
);
const JobDetail = React.lazy(() => import("../Pages/JobDetail"));
const CompanyPublicPage = React.lazy(() =>
  import("../Pages/JobDetail/CompanyPublicPage")
);
const PublicCompleteJobDetails = React.lazy(() =>
  import("../Pages/JobDetail/PublicCompleteJobDetails")
);
const PublicJobDetails = React.lazy(() =>
  import("../Pages/JobDetail/PublicJobDetails")
);
// const Login = React.lazy(() => import("../Pages/Login"));
const ManageCandidates = React.lazy(() => import("../Pages/ManageCandidates"));
const OpenJobs = React.lazy(() => import("../Pages/OpenJobs/OpenJobs"));
const CandidateDetails = React.lazy(() =>
  import("../Pages/ManageCandidates/CandidateDetails")
);
const Candidates = React.lazy(() =>
  import("../Pages/ManageCandidates/Candidates")
);
const SourceCandidates = React.lazy(() =>
  import("../Pages/ManageCandidates/SourceCandidates")
);
const JobTrack = React.lazy(() => import("../Pages/ManageJobs"));
const ManageTeam = React.lazy(() => import("../Pages/ManageTeam"));
const Notification = React.lazy(() => import("../Pages/Notification"));
const PlanPurchase = React.lazy(() => import("../Pages/PlanPurchase"));
const MyPlan = React.lazy(() => import("../Pages/PlanPurchase/MyPlan"));
const Payment = React.lazy(() => import("../Pages/PlanPurchase/Payment"));
const ProfileUnlockHistory = React.lazy(() =>
  import("../Pages/PlanPurchase/ProfileUnlockHistory")
);
const SlotDetailPage = React.lazy(() =>
  import("../Pages/PlanPurchase/SlotDetailPage")
);
const TempMySubscription = React.lazy(() =>
  import("../Pages/PlanPurchase/TempMySubscription")
);
const ThankYou = React.lazy(() => import("../Pages/PlanPurchase/ThankYou"));
const PostJob = React.lazy(() => import("../Pages/PostJob"));
const NewPostJob = React.lazy(() => import("../Pages/PostJob/NewPostJob"));
const CollegePostJob = React.lazy(() =>
  import("../Pages/PostJob/CollegePostJob")
);
const Preferences = React.lazy(() => import("../Pages/Preferences"));
const PublicTrackEmployee = React.lazy(() =>
  import("../Pages/TrackJob/PublicTrackEmployee")
);
const TrackEmployee = React.lazy(() =>
  import("../Pages/TrackJob/NewTrackEmployee")
);
const UserRole = React.lazy(() => import("../Pages/UserRole"));
const CompanyPostJob = React.lazy(() =>
  import("../Pages/CompanyPostJob/CompanyPostJob")
);
const GetworkAi = React.lazy(() => import("../Pages/GetWorkAi"));
const InterviewKit = React.lazy(() => import("../Pages/InterviewKit"));
const AIBotCalling = React.lazy(() =>
  import("../Pages/AIBotCalling/AIBotCalling")
);
const MyAIPlan = React.lazy(() => import("../Pages/PlanPurchase/MyAIPlan"));

function RouteLayout({ layout, type = "private", component, ...rest }) {
  const LOGGEDIN = useSelector((state) => state?.userDetails?.LOGGEDIN);

  // const loggedIn = localStorage.getItem("loggedIn");
  //console.log("LOGGEDIN", LOGGEDIN);
  //   let fromTrack = ["track-job", "students", "job-application"]?.some((str) =>
  //   location?.pathname?.includes(str)
  // );
  // const newLocation = {
  //   ...props?.location,
  //   pathname: fromTrack ? "/my-jobs/" : props?.location?.pathname,
  // };

  return (
    <Route
      {...rest}
      render={(props) =>
        /* loggedIn || */ type === "public" || LOGGEDIN === "true" ? (
          React.createElement(
            layout,
            props,
            React.createElement(component, props)
          )
        ) : (
          <Redirect
            to={{
              pathname: `/`,
              search: `?source=${
                ["track-job", "students", "job-applicants"]?.some((str) =>
                  props?.location?.pathname?.includes(str)
                )
                  ? "/my-jobs/"
                  : props?.location?.pathname
              }`,
              state: {
                from: {
                  ...props?.location,
                  pathname: ["track-job", "students", "job-applicants"]?.some(
                    (str) => props?.location?.pathname?.includes(str)
                  )
                    ? "/my-jobs/"
                    : props?.location?.pathname,
                },
              },
            }}
          />
        )
      }
    />
  );
}
function Routes({ setDesktopView }) {
  const domainDetail = useSelector((state) => state?.domainDetail?.data);
  const isMobileScreen = useCheckMobileScreen();
  const location = useLocation();
  useOnPageChange();

  return (
    <>
      <Suspense fallback={<PageLoader />}>
        {domainDetail?.is_private ? (
          <Route
            // layout={PublicLayout}
            // type="public"
            exact
            path="/"
            component={CompanyLogin}
          />
        ) : (
          <Route exact path="/" component={Login} />
        )}
        <Route
          // layout={PublicLayout}
          // type="public"
          path="/public-full-job-detail"
          component={PublicCompleteJobDetails}
        />
        <Route
          // layout={PublicLayout}
          // type="public"
          path="/public-job-detail"
          component={PublicJobDetails}
        />
        <Route
          // layout={PublicLayout}
          // type="public"
          path="/company-public-profile"
          component={CompanyPublicPage}
        />
        <RouteLayout
          layout={Layout}
          //type="public"
          path="/open-jobs"
          component={OpenJobs}
        />
        <Route
          // layout={P}
          // type="public"
          path="/new-sign"
          component={MergedPostSteppers} //This is route i have created for three steppers used
        />
        <Route path="/not-authorized" component={DomainDetailError} />
        <RouteLayout layout={Layout} path="/job-detail" component={JobDetail} />
        {/* <Route path="/public/track/:token?" component={PublicTrackEmployee} /> */}
        <RouteLayout
          layout={PublicLayout}
          type="public"
          path="/public/track/:token?"
          component={PublicTrackEmployee}
        />
        <RouteLayout
          //layout={}
          layout={PublicLayout}
          type="public"
          path="/public/candidate-details/track/"
          component={PublicCandidateDetails}
        />
        <RouteLayout
          layout={CompanyLayout}
          //type="public"
          path="/company-post-job"
          component={CompanyPostJob}
        />
        {isMobileScreen && !HIDE_ON_PATH?.includes(location?.pathname) ? (
          <>
            <div
              style={{
                height: "100vh",
                display: "flex",
                //marginTop: 80,
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <NoData
                  // height="250px"
                  // width="250px"
                  style={{
                    aspectRatio: "3/2",
                    width: "95%",
                    objectFit: "contain",
                  }}
                  alt="404 Error"
                  fill={"#007BFF"}
                />
                <Typography
                  variant="h3"
                  align="center"
                  sx={{ mt: -2, fontWeight: "600" }}
                >
                  {`Sorry for the inconvenience.`}
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  sx={{ mt: 2, mb: 1, maxWidth: 300 }}
                >
                  {`This website is not mobile device compatible, Please switch to desktop view or open the same website in your laptop or desktop.`}
                </Typography>

                <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      boxShadow: "none",
                      //borderRadius: "30px",
                      borderRadius: "8px",
                      height: "40px",
                      // width: 200,
                    }}
                    onClick={() => {
                      setDesktopView(true);
                    }}
                  >
                    Switch to desktop view
                  </Button>
                </Box>
              </div>
            </div>
          </>
        ) : (
          <>
            <RouteLayout
              layout={Layout}
              path="/admin/dashboard"
              component={Home}
            />

            {/* <Route path="/public/track/:token?" component={PublicTrackEmployee} /> */}

            {/*  <Layout> */}

            <RouteLayout
              layout={Layout}
              path="/getwork-recommendation"
              component={Recommendation}
            />
            <RouteLayout
              layout={Layout}
              path="/company-profile"
              component={CompanyProfile}
            />
            <RouteLayout
              layout={Layout}
              path="/hiring-preferences"
              component={Preferences}
            />
            <RouteLayout
              layout={Layout}
              path="/my-profile"
              component={MyProfile}
            />
            <RouteLayout
              layout={Layout}
              path="/featured-colleges"
              component={FeaturedColleges}
            />
            <RouteLayout
              layout={Layout}
              path="/source-candidates"
              component={SourceCandidates}
            />
            {/* <RouteLayout layout={Layout} path="/help" component={HelpandSupport} />
      <RouteLayout
        layout={Layout}
        path="/group_FAQs/:card_id?"
        component={Info}
      />
      <RouteLayout
        layout={Layout}
        path="/questions/:question_id?"
        component={Question}
      /> */}
            <RouteLayout
              layout={Layout}
              path="/candidates"
              component={Candidates}
            />
            <RouteLayout
              layout={Layout}
              path="/admin/manage-teams"
              component={ManageTeam}
            />
            <RouteLayout
              layout={Layout}
              path="/admin/user-role"
              component={UserRole}
            />
            <RouteLayout
              layout={Layout}
              path="/getwork-plan-pricing"
              component={PlanPurchase}
            />
            <RouteLayout
              layout={Layout}
              path="/thank-you"
              component={ThankYou}
            />
            <RouteLayout
              layout={Layout}
              path="/my-purchased-plan"
              component={MyPlan}
            />
            <RouteLayout layout={Layout} path="/payment" component={Payment} />
            <RouteLayout
              layout={Layout}
              path="/my-subscription"
              component={TempMySubscription}
            />
            <RouteLayout
              layout={Layout}
              path="/unlock-history"
              component={ProfileUnlockHistory}
            />
            <RouteLayout
              layout={Layout}
              path="/slot-detail-page"
              component={SlotDetailPage}
            />
            <RouteLayout layout={Layout} path="/my-jobs" component={JobTrack} />
            <RouteLayout
              layout={Layout}
              path="/manage-jobs"
              component={JobTrack}
            />
            <RouteLayout
              layout={Layout}
              path="/track-job"
              component={TrackEmployee}
            />
            <RouteLayout
              layout={Layout}
              path="/job-applicants"
              component={EmployeeDetails}
            />
            {/* <RouteLayout
        layout={Layout}
        path="/public/job-applicants/track/"
        component={EmployeeDetails}
      /> */}
            <RouteLayout
              layout={Layout}
              path="/post-job/:job_id"
              component={NewPostJob}
              exact
            />
            <RouteLayout
              layout={Layout}
              path="/post-job/:id"
              component={NewPostJob}
              exact
            />
            <RouteLayout
              layout={Layout}
              path="/new-post-job"
              component={NewPostJob}
              exact
            />

            <RouteLayout
              layout={Layout}
              path="/post-job"
              component={NewPostJob}
              exact
            />
            <RouteLayout
              layout={Layout}
              path="/college-post-job"
              component={CollegePostJob}
              exact
            />
            <RouteLayout
              layout={Layout}
              path="/manage-assignments"
              component={Assignment}
            />
            <RouteLayout
              layout={Layout}
              path="/create-assignments"
              component={CreateAssignments}
            />
            <RouteLayout
              layout={Layout}
              path="/ai-job-post"
              component={GetworkAi}
            />
            <RouteLayout
              layout={Layout}
              path="/interview-kit"
              component={InterviewKit}
            />
            <RouteLayout
              layout={Layout}
              path="/ai-bot-calling/:jobId/:jobTitle"
              component={AIBotCalling}
            />
            <RouteLayout
              layout={Layout}
              path="/assignment-details"
              component={AssignmentDetail}
            />
            <RouteLayout
              layout={Layout}
              path="/calendar-events/interviews"
              component={Interview}
            />
            <RouteLayout
              layout={Layout}
              path="/create-interview"
              component={CreateInterview}
            />
            <RouteLayout
              layout={Layout}
              path="/interview-details"
              component={InterviewDetails}
            />
            {/* <RouteLayout
              layout={Layout}
              path="/job-detail"
              component={JobDetail}
            /> */}

            <RouteLayout
              layout={Layout}
              path="/manage-candidates"
              component={ManageCandidates}
            />
            <RouteLayout
              layout={Layout}
              path="/candidate-details"
              component={CandidateDetails}
            />
            <RouteLayout layout={Layout} path="/contact" component={Contact} />
            <RouteLayout
              layout={Layout}
              path="/getwork-credits/overview"
              component={GetworkCreadit}
            />
            <RouteLayout
              layout={Layout}
              path="/all-notification"
              component={Notification}
            />
            <RouteLayout
              layout={Layout}
              path="/my-ai-plan"
              component={MyAIPlan}
            />
          </>
        )}{" "}
      </Suspense>

      {/*   </Layout> */}
    </>
  );
}

export default Routes;
