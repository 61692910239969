import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Container,
  InputBase,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Chip,
  alpha,
  useTheme,
  styled,
  CircularProgress,
  CardActions,
  Card,
  Collapse,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  useMediaQuery,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import {
  CustomActionTextField,
  CustomTextField,
} from "../../Common/InputText/InputField";
import CollegeDetails from "./CollegeDetails";
import Grid from "@mui/material/Unstable_Grid2";
import { useAlert } from "react-alert";
import PageLoader from "../../Common/PageLoader";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Chiplist = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        position: "relative",
        pb: 0,
        backgroundColor: "#fff",
      }}
      {...other}
    >
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          alignItems: "center",
          p: 0,
        }}
      >
        {children}
      </Container>
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function NewDegreeCourseSelect({
  postJobData,
  setPostJobData,
  handleClickOpen,
  handleClose,
  open,
  setDegree,
  setSpecialization,
  handleSelectAllCourses,
  handleSelectAllSpecialization1,
  handleSelectSingleSpecialization1,
  degree,
  specialization,
  specializationLoader,
  scroll,
  descriptionElementRef,
  renderChips,
}) {
  const theme = useTheme();
  const alert = useAlert();
  const [expanded, setExpanded] = React.useState(false);
  // const [degree, setDegree] = React.useState([]);
  // const [specialization, setSpecialization] = useState([]);
  const [degreeCourseFilter, setDegreeCourseFilter] = useState({
    eduLevel: [],
  });

  const generateCsvqualification = (arr) => {
    let str = "";
    var item_id;
    arr.forEach((item, index) => {
      if (item === "Graduation") item_id = 3;
      if (item === "Post Graduation") item_id = 4;
      if (item === "Ph.D") item_id = 5;
      arr.indexOf(item) !== arr.length - 1
        ? (str += item_id + ",")
        : (str += item_id);
    });

    return str;
  };

  useEffect(() => {
    if (degreeCourseFilter?.eduLevel?.length > 0) {
      const edLvlStr = generateCsvqualification(degreeCourseFilter?.eduLevel);
      //getDegreeList(edLvlStr);
    }
  }, [degreeCourseFilter?.eduLevel?.length]);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    const query = event?.target?.value?.toLowerCase() || "";
    setSearchQuery(query);

    const updatedData = { ...postJobData };

    Object.keys(updatedData.OffCampus.allData).forEach((degreeName) => {
      let hasVisibleSpecialization = false;

      updatedData.OffCampus.allData[degreeName] = updatedData.OffCampus.allData[
        degreeName
      ].map((specialization) => {
        const matches = specialization?.specialization?.name
          ?.toLowerCase()
          ?.includes(query);

        if (matches) hasVisibleSpecialization = true;

        return {
          ...specialization,
          hidden: !matches,
        };
      });

      updatedData.OffCampus.allData[degreeName].hidden =
        !hasVisibleSpecialization;
    });

    setPostJobData(updatedData);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        sx={{
          height: "40px",
          fontSize: "14px",
          borderRadius: "8px",
          boxShadow: "none",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
        onClick={handleClickOpen}
      >
        {`Add Degree & Course`}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll={scroll}
        sx={{
          // "& .MuiDialog-paper": { width: "500px" },
          [theme.breakpoints.only("xs")]: {
            // height: 600,
            alignItems: "flex-end",
            top: "unset",
            top: "64px",
          },

          "& .MuiDialog-paper": {
            borderRadius: "8px",
            width: "700px",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              borderRadius: "8px",
              height: "37px",
              width: "80%",
              boxShadow: "0px 4px 15px 0px #0000001C",
              [theme.breakpoints.only("xs")]: {
                width: "100%",
              },
            }}
          >
            <InputBase
              sx={{
                ml: 1,
                flex: 1,
                "& .MuiInputBase-input": {
                  fontSize: "14px",
                },
              }}
              placeholder="Search Course"
              value={searchQuery}
              onChange={handleSearchChange}
            />

            {searchQuery && (
              <IconButton
                type="button"
                aria-label="search"
                onClick={handleSearchChange}
              >
                <ClearIcon />
              </IconButton>
            )}

            <IconButton type="button" aria-label="search" sx={{ ml: "-10px" }}>
              <Search />
            </IconButton>
          </Paper>
        </BootstrapDialogTitle>

        <DialogContent
          dividers={scroll === "paper"}
          sx={{
            border: "none",
            pt: 0,
            [theme.breakpoints.only("sm")]: {
              p: 0,
            },
            [theme.breakpoints.only("xs")]: {
              p: 0,
            },
          }}
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Container
              maxWidth="md"
              sx={{
                paddingLeft: "16px !important",
                paddingRight: "16px !important",
              }}
            >
              {specializationLoader ? (
                <PageLoader />
              ) : (
                <Box sx={{ mt: 2 }}>
                  {/* <Grid container spacing={2}>
                    <Grid xs={12} sm={12} md={12}> */}
                  <Box>
                    <Grid container spacing={0}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                name="gilad"
                                onChange={(e) => {
                                  //   var len = Object.keys(allData);
                                  handleSelectAllCourses(e);
                                }}
                                checked={
                                  postJobData?.OffCampus?.degree?.[0]
                                    ?.all_degree_selected
                                    ? true
                                    : false
                                }
                                disabled={searchQuery}
                              />
                            }
                            label={
                              <Typography
                                variant="body2"
                                sx={{ color: "#6C757D" }}
                              >
                                Select all courses
                              </Typography>
                            }
                          />
                        </FormGroup>

                        <Paper
                          sx={{
                            boxShadow: "none",
                            display: "flex",
                            justifyContent: "flex-start",
                            flexWrap: "wrap",
                            listStyle: "none",
                            p: 0,
                            mt: 0,
                            // mb: 1,
                            // mt: -0.2,
                            // mb: -0.2,
                          }}
                          component="ul"
                        >
                          {renderChips && (
                            <>
                              {postJobData?.OffCampus?.degree[0]
                                ?.all_degree_selected ? (
                                <li>
                                  {" "}
                                  <Chiplist
                                    key={postJobData?.OffCampus?.degree[0]?.id}
                                  >
                                    <Chip
                                      size="small"
                                      color="primary"
                                      variant="outlined"
                                      sx={{
                                        backgroundColor: alpha(
                                          theme.palette.primary.main,
                                          0.1
                                        ),
                                      }}
                                      label={`All Courses Selected`}
                                      deleteIcon={<CloseIcon />}
                                      onDelete={(e) => {
                                        // removeCollege(clg);
                                        handleSelectAllCourses(e);
                                      }}
                                    />
                                  </Chiplist>
                                </li>
                              ) : (
                                postJobData?.OffCampus?.degree?.map((deg) =>
                                  deg?.all_spec_selected ? (
                                    <li>
                                      {" "}
                                      <Chiplist key={deg?.id}>
                                        <Chip
                                          size="small"
                                          color="primary"
                                          variant="outlined"
                                          sx={{
                                            backgroundColor: alpha(
                                              theme.palette.primary.main,
                                              0.1
                                            ),
                                          }}
                                          label={`${deg?.name} - All Branches`}
                                          deleteIcon={<CloseIcon />}
                                          onDelete={(e) => {
                                            handleSelectAllSpecialization1(
                                              e,
                                              deg?.name
                                            );
                                            // removeCollege(clg);
                                          }}
                                        />
                                      </Chiplist>
                                    </li>
                                  ) : (
                                    deg?.spec?.map((sp, idx) => (
                                      <li>
                                        {" "}
                                        <Chiplist key={deg?.id}>
                                          <Chip
                                            size="small"
                                            color="primary"
                                            variant="outlined"
                                            sx={{
                                              backgroundColor: alpha(
                                                theme.palette.primary.main,
                                                0.1
                                              ),
                                            }}
                                            label={`${deg?.name} - ${sp?.name}`}
                                            deleteIcon={<CloseIcon />}
                                            onDelete={(e) => {
                                              handleSelectSingleSpecialization1(
                                                e,
                                                deg?.name,
                                                sp?.index_in_all_data
                                              );
                                              // removeCollege(clg);
                                            }}
                                          />
                                        </Chiplist>
                                      </li>
                                    ))
                                  )
                                )
                              )}
                            </>
                          )}
                        </Paper>
                      </div>
                      <Grid xs={12} sm={12} md={12}>
                        <Box
                          sx={{
                            [theme.breakpoints.only("sm")]: {},
                            [theme.breakpoints.only("xs")]: {},
                          }}
                        >
                          {postJobData?.Colleges?.hiring_type == "OFF CAMPUS" &&
                            postJobData?.OffCampus?.allData && (
                              <Box
                                sx={{
                                  boxShadow: "0px 4px 10px 0px #0000001C",
                                  // pr: 0,
                                  p: 2,
                                  borderRadius: "5px",
                                  mb: 2,
                                  [theme.breakpoints.only("sm")]: {
                                    // pr: 0,
                                    boxShadow: "0px 4px 10px 0px #0000001C",
                                  },
                                  [theme.breakpoints.only("xs")]: {
                                    boxShadow: "none",
                                    p: 0,
                                    // pr: 0,
                                  },
                                }}
                              >
                                {Object.keys(
                                  postJobData?.OffCampus?.allData
                                )?.map((item, index) => (
                                  <CardComponent
                                    handleSelectAllSpecialization1={
                                      handleSelectAllSpecialization1
                                    }
                                    handleSelectSingleSpecialization1={
                                      handleSelectSingleSpecialization1
                                    }
                                    item={item}
                                    postJobData={postJobData}
                                    index={index}
                                    searchQuery={searchQuery}
                                  />
                                ))}
                              </Box>
                            )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* </Grid>
                  </Grid> */}
                </Box>
              )}
            </Container>
          </DialogContentText>
        </DialogContent>

        <DialogActions
          sx={{
            border: "none",
            [theme.breakpoints.only("sm")]: {
              p: 0,
            },
            [theme.breakpoints.only("xs")]: {
              p: 0,
            },
          }}
        >
          <Container maxWidth="md" sx={{ pl: 0.5, pr: 0.5 }}>
            <AppBar
              color="inherit"
              sx={{
                boxShadow: "none",
                position: "relative",
                [theme.breakpoints.only("sm")]: {
                  // top: "auto",
                  // bottom: 0,
                  boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
                  // position: "fixed",
                },
                [theme.breakpoints.only("xs")]: {
                  // top: "auto",
                  // bottom: 0,
                  boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
                  // position: "fixed",
                },
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  width: "100%",
                  margin: 0,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Grid xs={6} sm={3} md={3} sx={{ display: "grid" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                      height: "40px",
                      borderRadius: "8px",
                      boxShadow: "none",
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid
                  xs={6}
                  sm={3}
                  md={3}
                  sx={{
                    display: "grid",
                    [theme.breakpoints.up("md")]: {
                      pl: 0,
                    },
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      height: "40px",
                      borderRadius: "8px",
                      boxShadow: "none",
                    }}
                    onClick={
                      !postJobData?.OffCampus?.specialization?.length > 0 /* &&
                          !postJobData?.OffCampus?.checkAll */
                        ? () => {
                            alert?.error(
                              "Please Select Atleast one course or specialization"
                            );
                          }
                        : () => {
                            handleClose();
                          }
                    }
                  >
                    Submit
                    {/* Add Institutes */}
                  </Button>
                </Grid>
              </Grid>
            </AppBar>
          </Container>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const CardComponent = ({
  handleSelectAllSpecialization1,
  handleSelectSingleSpecialization1,
  item,
  postJobData,
  index,
  searchQuery,
}) => {
  const [secondexpanded, setSecondExpanded] = React.useState(false);

  const handleExpandSecondClick = () => {
    setSecondExpanded(!secondexpanded);
  };

  return (
    <>
      {!postJobData?.OffCampus?.allData[item]?.hidden && (
        <Card
          sx={{
            border: "1px solid #B0B6BA",
            borderRadius: "8px",
            mb: 2,
            // mt: 1,
            boxShadow: "none",
          }}
        >
          <CardActions disableSpacing sx={{ height: "50px", p: 0 }}>
            <Box sx={{ pl: 2 }}>
              {" "}
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    name="gilad"
                    onChange={(e) => {
                      handleSelectAllSpecialization1(e, item, index);
                      postJobData.OffCampus.allData[item][0].isValue =
                        e.target.checked;
                    }}
                    checked={postJobData?.OffCampus?.allData[item]?.every(
                      (e) => e.isActive === true
                    )}
                    disabled={searchQuery}
                  />
                }
                label={
                  <Typography variant="body2" sx={{ color: "#6C757D" }}>
                    {item}
                  </Typography>
                }
              />
            </Box>
            <ExpandMore
              expand={secondexpanded}
              onClick={handleExpandSecondClick}
              aria-expanded={secondexpanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={secondexpanded} timeout="auto" unmountOnExit>
            <CardContent sx={{ pl: 7, pt: 0 }}>
              <FormGroup>
                {postJobData?.OffCampus?.allData[item].map((i, index) =>
                  i?.specialization && !i?.hidden ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          name="gilad"
                          checked={i?.isActive}
                          onChange={(e) => {
                            handleSelectSingleSpecialization1(e, item, index);
                          }}
                        />
                      }
                      label={
                        <Typography variant="body2" sx={{ color: "#6C757D" }}>
                          {i?.specialization?.name}
                        </Typography>
                      }
                    />
                  ) : null
                )}
              </FormGroup>
            </CardContent>
          </Collapse>
        </Card>
      )}
    </>
  );
};
