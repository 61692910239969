import React, { useState, useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Box, Container, useTheme } from "@mui/material";

import MergedFirstForm from "./Steps/MergedFirstForm";
import MergedSecondForm from "./Steps/MergedSecondForm";
import MergedThirdForm from "./Steps/MergedThirdForm";
import ThirdForm from "./Steps/MergedThirdForm";
import fetchApi from "../../../Apis/fetchApi";

import {
  getCookie,
  NIYUKTI_API,
  PRAVESH_API,
} from "../../../Constants/constants";
// import { useSelector } from "react-redux";
import axios from "axios";
import VerificationDialog from "./Steps/VerificationDialog";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ApartmentIcon from "@mui/icons-material/Apartment";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Request from "./Steps/Request";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import AvatarMenu from "./../../Common/InputText/AvatarMenu";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "#CCCCCC",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const icons = {
    1: <PersonAddAltIcon />,
    2: <ApartmentIcon />,
    3: <WorkOutlineIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ["Basic Informations", "Company Details", "Post Internship/Job"];

export default function MergedPostSteppers() {
  const locationvalue = useLocation();
  const { step_history } = locationvalue.state || {};
  const { email_history } = locationvalue.state || {};
  const theme = useTheme();
  const [email, setemail] = useState("");
  const [emailVerified, setEmailVerified] = useState("");
  const [phoneVerified, setPhoneVerified] = useState("");
  const [basicInfo, setBasicInfo] = useState("");
  const [companyInfo, setCompanyInfo] = useState("");
  const [fromLoginEmail, setFromLoginEmail] = useState("");
  const [fromLoginPhone, setFromLoginPhone] = useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [jobRoleList, setJobRoleList] = useState([]);
  const [specializationLoader, setSpecializationLoader] = useState(false);
  const [location, setLocation] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [step, setStep] = useState(
    window.localStorage.getItem("loggedIn") ? 2 : step_history || 0
  );

  useEffect(() => {
    const helper = (res) => {
      if (res?.success) {
        if (
          step_history === 0 &&
          !window.localStorage.getItem("loggedIn") &&
          res.data?.user_details?.phone
        ) {
          setStep(1);
        }
      }
    };
    const fetchData = async () => {
      const payload = {
        data: email_history,
      };

      fetchApi({
        url: `${PRAVESH_API}company_saas/new/check/user_phone/`,
        method: "post",
        body: JSON.stringify(payload),
        setResponse: setUserData,
        setLoading: setLoading,
        callBackFn: helper,
      });
    };

    fetchData();
  }, []);

  // Sync step with step_history and emailVerifiedstatus consistently
  // useEffect(() => {
  //   if (window.localStorage.getItem("loggedIn")) {
  //     setStep(2);
  //   } else if (emailVerifiedstatus === true) {
  //     setStep(1);
  //   } else {
  //     setStep(step_history || 0);
  //   }
  // }, [step_history, emailVerifiedstatus]);

  useEffect(() => {
    if (basicInfo && emailVerified && companyInfo && phoneVerified) setStep(2);
    else if (basicInfo && emailVerified && companyInfo) setStep(1);
    else if (basicInfo && emailVerified) setStep(1);
  }, [basicInfo, emailVerified, companyInfo, phoneVerified]);

  const userDetail = useSelector(
    (state) => state?.userDetails?.userDetails?.user_details
  );

  const [details, setDetails] = React.useState({
    basic: {
      name: "",
      phone: null,
      email: email ?? "",
      otp: null,
      referralCode: "",
      password: "",
    },
    companyDetails: {
      companyName: null,
      website: null,
      cityName: null,
      stateName: null,
      designation: null,
      location: null,
      companySize: {
        title: "",
        value: "",
      },
    },
  });

  useEffect(() => {
    if (email) {
      axios
        .post(`${PRAVESH_API}company_saas/new/company_basic_info_list/`, {
          email: email,
        })
        .then((res) => {
          const prefill = res?.data?.data;
          setDetails({
            ...details,
            basic: {
              ...details.basic,
              name: prefill?.full_name && prefill?.full_name,
              phone: prefill?.phone && prefill?.phone,
              email: email ?? "",
              emailVerified: prefill?.email_verified && prefill?.email_verified,
              referralCode: prefill?.referral_code && prefill?.referral_code,
              checkIn: prefill?.email_verified,
              password: prefill?.user_password && prefill?.user_password,
            },
            companyDetails: {
              ...details.companyDetails,
              companyName: prefill?.comapny_name && prefill?.comapny_name,
              website: prefill?.company_website && prefill?.company_website,
              designation: prefill?.designation && prefill?.designation,
              cityName: prefill?.city_name && prefill?.city_name,
              stateName: prefill?.state_name && prefill?.state_name,
              companySize: {
                ...details.companyDetails.companySize,
                title: prefill?.company_size ?? "",
                value: "",
              },
            },
          });
        });
    }
  }, []);

  const initialState = {
    Basics: {
      duration: null,
      user_id: userDetail?.id,
      company_id: userDetail?.company,
      job_title: "",
      job_type: 7,
      ppo: 0,
      employment_type: 1,
      job_duration_start: null,
      job_duration_end: null,
      rounds: [],
      posted_on_job_lake: false,
      is_ipo_job: false,
      internship_ppo: true,
      stipend: 0,
      internship_unpaid: false,
      plan_id: -1,
    },
    Details: {
      job_description: "",
      job_role: null,
      job_segment: null,
      job_role_name: "",
      vacancy: null,
      ctc_not_disclosed: false,
      salary_type: "UNPAID",
      salary_payment_type: "PER ANNUM",
      ctc_min: 0,
      ctc_max: 0,
      is_equity: false,
      equity_min: 0,
      equity_max: 0,
      documents: [],
      job_location: [],
      allow_remote: false,
      is_service_bond: false,
      service_bond: 0,
      work_from_home: "WORK FROM OFFICE",
    },

    Preferences: {
      eligibility_criteria: {
        graduation_years: [],
        education_levels: [],
        college_preferences: [],
        degrees: [],
        courses: [],
        courses_rank: [],
        degrees_rank: [],
        skills: [],
        experience: [],
        qualifications: [],
        percentage: null,
      },
      backlog: false,
    },
    Colleges: {
      hiring_type: "",
      college: [],
      apply_start_date: null,
      apply_end_date: null,
    },
    extra: {
      degrees_ids: "",
    },
    extra1: {
      job_type_name: "",
      emp_type_name: "",
    },
    Extra1: {
      apply_start_date: null,
      apply_end_date: null,
    },
    OffCampus: {
      allData: {} /* null */,
      specialization: [],
      degree: [],
      flag: null,
    },
    degree_list: {
      list: null,
    },
    Preview: {
      data: null,
    },
    Rounds: {
      rounds: [],
    },
    ppo: {
      ppo: false,
    },
    Extra: {
      job_role_name: "",
    },
    loader: {
      loader: false,
    },
    college_id_list: {
      checkAll: false,
      list: [],
    },
  };
  const [postJobData, setPostJobData] = React.useState(initialState);

  const helper2 = (data) => {
    setJobRoleList(data?.data);
  };
  const getJobRoleList = () => {
    fetchApi({
      url: `${NIYUKTI_API}job/role/`,
      setResponse: helper2,
    });
  };
  const getLocation = () => {
    fetchApi({
      url: `${PRAVESH_API}api/shared/city_and_state/`,
      setResponse: (data) => {
        setLocation(data?.data ?? []);
      },
    });
  };

  /* ############################################################################################## */
  const findCompanyLocation = (cityId) => {
    const loc = location?.filter((location) => location?.city_id === cityId);
    if (loc?.length > 0) {
      return loc[0];
    } else {
      return null;
    }
  };

  const companyHelper = (data) => {
    setCompanyData(data?.data);
  };
  const getCompanyList = () => {
    fetchApi({
      url: `${PRAVESH_API}company_saas/company_list/`,
      alert: alert,
      setResponse: companyHelper,
      loginFlow: true,
      alert: alert,
    });
  };

  useEffect(() => {
    if (location && userDetail?.company_details?.city_id)
      setDetails({
        ...details,
        companyDetails: {
          ...details?.companyDetails,
          location: findCompanyLocation(userDetail?.company_details?.city_id),
        },
      });
  }, [location, userDetail?.company_details?.city_id]);

  const getDegreeCourse = () => {
    // setLoader(true);
    // setLoading(true);
    setSpecializationLoader(true);
    axios
      .get(PRAVESH_API + "api/education/job/get_specialization")
      .then((res) => {
        if (res.data?.success && res?.data?.data) {
          const temp = {};
          res?.data?.data?.forEach((item) => {
            var tempSpzl = {
              ...item,
              isChecked: false,
              isActive: false,
              isValue: false,
            };
            if (temp[item.degree.name] === undefined) {
              if (item?.specialization === null)
                temp[item.degree.name] = [
                  {
                    degree: item.degree,
                    specialization: {},
                    isChecked: false,
                    isValue: false,
                  },
                ];
              else {
                temp[item.degree.name] = [tempSpzl];
              }
            } else {
              if (item?.specialization === null)
                temp[item.degree.name].push({
                  degree: item.degree,
                  specialization: {},
                  isChecked: false,
                  isValue: false,
                });
              else temp[item.degree.name].push(tempSpzl);
            }
          });

          //   setAllData(temp);
          setPostJobData({
            ...postJobData,
            OffCampus: { ...postJobData?.OffCampus, allData: temp },
          });
          setSpecializationLoader(false);
        } else {
          alert.error("Error Occured");
          setSpecializationLoader(false);
        }
      })
      .catch((err) => {
        setSpecializationLoader(false);
      });
  };

  React.useEffect(() => {
    if (
      Object.keys(postJobData?.OffCampus?.allData)?.length === 0 &&
      postJobData?.Colleges?.hiring_type === "OFF CAMPUS"
    )
      getDegreeCourse();
  }, [postJobData?.Colleges?.hiring_type]);

  useEffect(() => {
    getLocation();
    if (!jobRoleList?.length > 0) getJobRoleList();
    getCompanyList();
  }, []);

  useEffect(() => {}, [postJobData]);

  const [postPlaError, setPostPlaError] = useState(false);

  function getForm() {
    switch (step) {
      case 0:
        return (
          <MergedFirstForm
            setStep={setStep}
            step={step}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            details={details}
            setDetails={setDetails}
            email={email}
            emailVerified={emailVerified}
            setEmailVerified={setEmailVerified}
            phoneVerified={phoneVerified}
            setPhoneVerified={setPhoneVerified}
            basicInfo={basicInfo}
            setBasicInfo={setBasicInfo}
            companyInfo={companyInfo}
            fromLoginEmail={fromLoginEmail}
            setFromLoginEmail={setFromLoginEmail}
            fromLoginPhone={fromLoginPhone}
            userData={userData}
            setFromLoginPhone={setFromLoginPhone}
          />
        );
      case 1:
        return (
          <MergedSecondForm
            setStep={setStep}
            step={step}
            details={details}
            setDetails={setDetails}
            location={location}
            email={email}
            companyData={companyData}
            emailVerified={emailVerified}
            setEmailVerified={setEmailVerified}
            phoneVerified={phoneVerified}
            setPhoneVerified={setPhoneVerified}
            basicInfo={basicInfo}
            companyInfo={companyInfo}
            setCompanyInfo={setCompanyInfo}
            fromLoginEmail={fromLoginEmail}
            setFromLoginEmail={setFromLoginEmail}
            fromLoginPhone={fromLoginPhone}
            setFromLoginPhone={setFromLoginPhone}
            userData={userData}
          />
        );
      case 2:
        return (
          <MergedThirdForm
            setStep={setStep}
            step={step}
            postJobData={postJobData}
            setPostJobData={setPostJobData}
            jobRoleList={jobRoleList}
            specializationLoader={specializationLoader}
            location={location}
            findCompanyLocation={findCompanyLocation}
            postJobLoginFlow={true}
            postPlaError={postPlaError}
            setPostPlaError={setPostPlaError}
            userData={userData}
          />
        );

      default:
        return <Request />;
    }
  }

  return (
    <>
      {/* Navbar */}
      <AppBar position="static" sx={{ backgroundColor: "#fff", boxShadow: 1 }}>
        <Toolbar sx={{ justifyContent: "space-between", padding: "0 16px" }}>
          {/* Left: Logo */}
          <Box>
            <img
              src="https://s3.ap-south-1.amazonaws.com/getwork-store/prod/common/Black_Logo"
              alt="GetWork Logo"
              style={{ height: "40px" }}
            />
          </Box>

          {/* Center: Steps */}

          {/* Right: Profile Avatar */}
          {step === 2 && (
            <>
              {/* Avatar - Displayed only when step is 2 */}
              <AvatarMenu
                userDetails={userDetail}
                sx={{
                  backgroundColor: "#1976d2",
                  position: "absolute",
                  top: 10,
                  right: 20,
                  cursor: "pointer",
                }}
              >
                {userDetail?.first_name?.[0]} {/* Display initials */}
              </AvatarMenu>

              {/* Modal - Displayed only when step is 2 */}
            </>
          )}

          {/* Modal for User Info */}
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <Box sx={{ width: "100%", minHeight: "76vh", paddingBottom: "30px" }}>
        <Container maxWidth="md">
          <Box
            sx={{
              pl: 13,
              pr: 13,
              [theme.breakpoints.only("sm")]: {
                pl: 0,
                pr: 0,
              },
              [theme.breakpoints.only("xs")]: {
                pl: 0,
                pr: 0,
              },
            }}
          >
            <Box sx={{ marginTop: "30px" }}>
              <Stepper
                activeStep={step}
                alternativeLabel
                connector={<ColorlibConnector />}
              >
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel
                        StepIconComponent={ColorlibStepIcon}
                        {...labelProps}
                        sx={{
                          "& .MuiStepLabel-label": {
                            color: "#8A8A8A",
                            [theme.breakpoints.only("xs")]: {
                              fontSize: "12px",
                            },
                          },
                          "& .MuiStepLabel-root .Mui-completed": {
                            color: theme.palette.primary.main,
                          },
                          "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                            {
                              color: theme.palette.primary.main,
                            },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: theme.palette.primary.main,
                          },
                          "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                            {
                              color: theme.palette.primary.main,
                            },
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          </Box>
        </Container>
        {/* {!prefillData ? "Register with us" : "My Basic Info"} */}
        <React.Fragment>
          <Container
            maxWidth={step == 2 ? "md" : "sm"}
            sx={{
              mt: 2,
              [theme.breakpoints.only("xs")]: {
                paddingLeft: "5px",
                paddingRight: "5px",
              },
            }}
          >
            <Box
              sx={{
                mt: 4,
                [theme.breakpoints.down("sm")]: {},
              }}
            >
              {" "}
              {loading ? null : getForm()}{" "}
            </Box>
          </Container>
        </React.Fragment>
      </Box>
    </>
  );
}
