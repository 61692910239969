import React from "react";
import { CircularProgress, useTheme } from "@mui/material";
//import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   circularProgressColored: {
//     color: theme.palette.primary.main,
//   },
//   circularProgress: {
//     color: theme.palette.common.white,
//   },
// }));

const CustomButtonLoader = ({
  isLoaderWhite = true,
  size = 14,
  value = 30,
}) => {
  const theme = useTheme();
  //const classes = useStyles();
  const circularProgressColored = {
    color: theme.palette.primary.main,
  };
  const circularProgress = {
    color: theme.palette.common.white,
  };

  return (
    <CircularProgress
      className={isLoaderWhite ? circularProgress : circularProgressColored}
      size={size}
      value={value}
    />
  );
};

export default CustomButtonLoader;
