import React, { useEffect, useState } from "react";
import clsx from "clsx";
//import { makeStyles } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import {
  Typography,
  Chip,
  Paper,
  Checkbox,
  FormControlLabel,
  Badge,
  FormHelperText,
  FormControl,
  FormGroup,
  FormLabel,
  TextField,
  CircularProgress,
  alpha,
  Autocomplete,
  Box,
  useTheme,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
//import FilterSearch from "../Search/FilterSearch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//import ExperienceSlider from "../Slider/ExperienceSlider";
//import SalarySlider from "../Slider/SalarySlider";
import ShowMore from "../../Dialog/ShowMore";
import { CustomTextField } from "../InputText/InputField";
import TuneIcon from "@mui/icons-material/Tune";
import FilterShowMore from "./FilterShowMore";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
//import pSBC from "shade-blend-color";
//import ShowMore from "../../Dialog/ShowMore";
//import { Autocomplete } from "@material-ui/lab";

// const useStyles = makeStyles((theme) => ({
//   list: {
//     width: 300,
//   },
//   fullList: {
//     width: "auto",
//   },
//   root: {
//     display: "flex",
//     justifyContent: "flex-start",
//     flexWrap: "wrap",
//     listStyle: "none",
//     padding: 5,
//     margin: 0,
//     boxShadow: "none",
//   },
//   checkboxroot: {
//     padding: 5,

//   },
//   chip: {
//     margin: 5,
//     color: theme.palette.primary.main,
//     borderColor: theme.palette.primary.main,
//     backgroundColor: alpha(0.9, theme.palette.primary.main),
//   },
//   chipicon: {
//     color: theme.palette.primary.main,
//   },
//   floatingLabelFocusStyle: {
//     //color: "#7e7e7e",
//     //fontSize: "14px",
//     color: "#c5c4ba",
//     transform: "translate(15px, 14px) scale(1)",
//   },
// }));

export default function PostJobCollegeFilter({
  filterData,
  filterLoading,
  setSelectedFilters,
  selectedFilters,
  setClickFilterApplyButton,
  filterDataError,
  clearFilter,
  setClearFilter,
  handleClose,
  mobileScreenFilter = false,
  mobileFilterOpen = false,
}) {
  //const classes = useStyles();
  const theme = useTheme();
  const [filterCount, setFilterCount] = useState(0);
  const [tempFilterApplied, setTempFilterApplied] = useState(selectedFilters);
  const [showAll, setShowAll] = useState(false);
  const [state, setState] = React.useState({
    right: true /* false */,
  });

  useEffect(
    () => {
      if (selectedFilters) {
        setTempFilterApplied(selectedFilters);
      }
    },
    [
      /* state */
    ]
  );

  useEffect(() => {
    if (filterData && Object?.keys(filterData).length > 0 && !selectedFilters) {
      let obj = {};
      Object?.keys(filterData).forEach((item) => {
        obj[filterData[item]?.param_name] = [];
      });

      setTempFilterApplied(obj);
    }
  }, [filterData]);

  const selectCheckboxData = (paramName, currentObj, selectionType) => {
    let currentArray = [...tempFilterApplied[paramName]];
    if (
      tempFilterApplied[paramName]?.some((item) => item?.id === currentObj?.id)
    ) {
      currentArray = currentArray?.filter(
        (item) => item?.id !== currentObj?.id
      );
    } else {
      if (selectionType === "single") currentArray = [currentObj];
      else currentArray?.push(currentObj);
    }
    setTempFilterApplied({ ...tempFilterApplied, [paramName]: currentArray });
  };

  const checkApplyButtonDisabled = () => {
    let flag = true;
    Object.keys(tempFilterApplied).forEach((item) => {
      if (tempFilterApplied[item]?.length > 0) flag = false;
    });
    return flag;
  };

  const toggleDrawer = (anchor, open) => (event) => {
    // if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
    //   return;
    // }
    setState({ ...state, [anchor]: open });
  };

  const toggleDrawerOnClick = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  const [chipData, setChipData] = React.useState([
    { key: 0, label: "Matching" },
    { key: 1, label: "High Match" },
    { key: 2, label: "6 - 8 LPA" },
  ]);

  const handleDelete = (chipToDelete, paramName) => () => {
    let tempArray = [...tempFilterApplied[paramName]];
    tempArray = tempArray.filter((item) => item?.id !== chipToDelete?.id);
    setTempFilterApplied({ ...tempFilterApplied, [paramName]: tempArray });
  };

  const [newstate, setNewState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  const handleChange = (event) => {
    setNewState({ ...newstate, [event.target.name]: event.target.checked });
  };

  const { gilad, jason, antoine } = state;
  const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

  useEffect(() => {
    let count = 0;
    if (selectedFilters && Object?.keys(selectedFilters)?.length) {
      Object.keys(selectedFilters)?.map((item) => {
        count = count + selectedFilters[item]?.length;
      });
    } else count = 0;
    setFilterCount(count);
  }, [selectedFilters]);

  useEffect(() => {
    let count = 0;
    if (tempFilterApplied && Object.keys(tempFilterApplied)?.length) {
      Object.keys(tempFilterApplied).map((item) => {
        count = count + tempFilterApplied[item]?.length;
      });
    } else count = 0;
    setFilterCount(count);
  }, [tempFilterApplied]);

  useEffect(() => {
    if (clearFilter) {
      var obj = {};
      Object?.keys(tempFilterApplied)?.forEach((item) => {
        obj[item] = [];
      });
      setTempFilterApplied(obj);
      setClearFilter(false);
    }
  }, [clearFilter]);

  const list = (anchor) => (
    <Box
      /*   className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })} */
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 320,
        display: "contents",
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <>
        <Box
          sx={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            // width: "inherit",
            zIndex: 100,
            borderBottom: "1px solid #E3E6EA",
            [theme.breakpoints.only("xs")]: {
              top: 0,
              width: "95%",
            },
            /*  marginTop: "-24px", */
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              [theme.breakpoints.up("sm")]: {
                display: "none",
              },
            }}
          >
            <ArrowBackRoundedIcon sx={{ color: "#000" }} />
          </IconButton>
          <Typography variant="h6">Filters</Typography>
          {tempFilterApplied && filterData && (
            <Button
              variant="contained"
              color="primary"
              style={{
                height: "35px",
                paddingLeft: "20px",
                paddingRight: "20px",
                borderRadius: "8px",
                display: "flex",
                boxShadow: "none",
              }}
              // disabled={checkApplyButtonDisabled}
              onClick={() => {
                setSelectedFilters(tempFilterApplied);
                toggleDrawerOnClick(anchor, false);
                setClickFilterApplyButton(true);
                mobileScreenFilter && handleClose();
              }}
            >
              {`Apply${filterCount ? `(${filterCount})` : ""}`}
            </Button>
          )}
        </Box>

        {/* <Divider /> */}
        <Box
          sx={{
            marginTop: "5px",
            [theme.breakpoints.only("xs")]: {
              p: 1,
            },
          }}
        >
          <Paper
            component="ul"
            /* className={classes.root} */
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              listStyle: "none",
              padding: 0.5,
              margin: 0,
              boxShadow: "none",
            }}
          >
            {tempFilterApplied &&
              filterData &&
              Object.keys(tempFilterApplied).map(
                (data) =>
                  tempFilterApplied[data].length > 0 &&
                  tempFilterApplied[data].map((item) => (
                    <li key={item?.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        // style={{
                        //   margin: "5px",
                        // }}
                        sx={{
                          backgroundColor: alpha(
                            theme.palette.primary.main,
                            0.1
                          ),
                          color: theme.palette.primary.main,
                          borderColor: theme.palette.primary.main,
                          border: "1px solid",
                          margin: "5px",
                        }}
                        label={item?.name}
                        onDelete={handleDelete(item, data)}
                        size="small"
                        //className={classes.chip}
                        deleteIcon={
                          <CloseIcon /* className={classes.chipicon} */ />
                        }
                      />
                    </li>
                  ))
              )}
          </Paper>
        </Box>

        <Box
          sx={{
            padding: "10px",
            [theme.breakpoints.only("xs")]: {
              p: 2,
              pt: 0,
            },
          }}
        >
          {tempFilterApplied &&
            filterData &&
            Object.keys(filterData).map((item) => (
              <div style={{ marginBottom: 10 }}>
                {filterData[item].data?.length > 0 && (
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    {filterData[item]?.heading}
                  </Typography>
                )}
                {filterData[item]?.field_type === "checkbox" &&
                  filterData[item].data?.length && (
                    <div /* className={classes.checkboxroot} */>
                      <FormControl
                        component="fieldset"
                        //className={classes.formControl}
                      >
                        <FormGroup>
                          {filterData[item]?.data.map((displayData) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  size="small"
                                  name="gilad"
                                  checked={tempFilterApplied[
                                    filterData[item]?.param_name
                                  ].some(
                                    (item) => item?.id === displayData?.id
                                  )}
                                  onChange={(e) => {
                                    selectCheckboxData(
                                      filterData[item]?.param_name,
                                      displayData,
                                      "single"
                                    );
                                  }}
                                />
                              }
                              label={displayData?.name}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </div>
                  )}
                {filterData[item]?.field_type === "multiple_checkbox" &&
                  filterData[item].data?.length && (
                    <div /* className={classes.checkboxroot} */>
                      <FormControl
                        component="fieldset"
                        //className={classes.formControl}
                      >
                        <FormGroup>
                          {filterData[item]?.data.map((displayData) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  size="small"
                                  name="gilad"
                                  checked={tempFilterApplied[
                                    filterData[item]?.param_name
                                  ].some(
                                    (item) => item?.id === displayData?.id
                                  )}
                                  onChange={(e) => {
                                    selectCheckboxData(
                                      filterData[item]?.param_name,
                                      displayData,
                                      "multiple"
                                    );
                                  }}
                                />
                              }
                              label={displayData?.name}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </div>
                  )}
                {filterData[item]?.field_type === "autocomplete_checkbox" &&
                filterData[item].data?.length ? (
                  <>
                    <Autocomplete
                      id="select-demo"
                      options={filterData[item]?.data}
                      getOptionLabel={(option) => String(option?.name)}
                      // renderOption={(option) => (
                      //   <Typography style={{ fontSize: "14px" }}>
                      //     {option.name}
                      //   </Typography>
                      // )}
                      // value={filters[filterData[item].key]}
                      onChange={(e, newValue) => {
                        selectCheckboxData(
                          filterData[item]?.param_name,
                          newValue,
                          "multiple"
                        );
                      }}
                      style={{
                        background: "#fff",
                        borderRadius: "8px",
                        marginTop: 10,
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          size="small"
                          variant="outlined"
                          label={filterData[item]?.heading}
                          InputLabelProps={
                            {
                              //className: classes.floatingLabelFocusStyle,
                            }
                          }
                        />
                      )}
                    />
                    {/* <br /> */}
                    <FilterShowMore
                      filterData={filterData[item]}
                      selectCheckboxData={selectCheckboxData}
                      tempFilterApplied={tempFilterApplied}
                    />
                    {/* {filterData[item]?.data?.length > 4 && (
                      <ShowMore
                        data={filterData[item]?.data?.slice(
                          4,
                          filterData[item]?.data?.length
                        )}
                        heading={filterData[item]?.heading}
                        handleChange={selectCheckboxData}
                        isCheckboxSelected={
                          tempFilterApplied[filterData[item]?.param_name]
                        }
                        paramName={filterData[item]?.param_name}
                      />
                    )} */}
                  </>
                ) : null}
                {/* {filterData[item]?.field_type === "slider" && <ExperienceSlider />} */}
              </div>
            ))}
        </Box>
      </>
    </Box>
  );

  return (
    <div>
      {/* {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Badge
            badgeContent={filterCount}
            color="primary"
            style={{ width: "100%" }}
          >
            <Button
              color="primary"
              variant="contained"
              startIcon={<TuneIcon />}
              onClick={toggleDrawer(anchor, true)}
              //variant="text"
              //color="inherit"
              style={{
                display: "flex",
                fontWeight: "normal",
                paddingLeft: "35px",
                paddingRight: "35px",
                height: "40px",
                width: "155px",
                  borderRadius: "8px",
                boxShadow: "none",
              }}
            >
              Filter
            </Button>
          </Badge>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          > */}
      {filterLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translate(0, -50%)",
            left: "50%",
          }}
        >
          <CircularProgress size={24} />
        </Box>
      ) : filterDataError ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            alignItems: "center",
          }}
        >
          <p align="center" style={{ margin: 20 }}>
            {" "}
            Some Error Occurred
            <br />
            <span style={{ color: "red" }}>"{filterDataError}"</span>
          </p>
        </div>
      ) : (
        <> {list(/* anchor */)}</>
      )}
      {/* </SwipeableDrawer>
        </React.Fragment>
      ))} */}
    </div>
  );
}
