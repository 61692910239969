import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Autocomplete,
  useTheme,
  AppBar,
  CircularProgress,
  createFilterOptions,
} from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  CustomTextField,
  CustomAutoCompletePaper,
} from "../../../Common/InputText/InputField";
import { PRAVESH_API, is_url } from "../../../../Constants/constants";
import fetchApi from "../../../../Apis/fetchApi";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import VerificationDialog from "./VerificationDialog";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const filter = createFilterOptions();

function MergedSecondForm({
  step,
  setStep,
  location,
  details,
  setDetails,
  email,
  companyData,
  emailVerified,
  setEmailVerified,
  phoneVerified,
  setPhoneVerified,
  basicInfo,
  companyInfo,
  setCompanyInfo,
  fromLoginEmail,
  setFromLoginEmail,
  fromLoginPhone,
  setFromLoginPhone,
  userData,
}) {
  const theme = useTheme();
  const alert = useAlert();
  const companyId = useSelector(
    (state) => state?.userDetails?.userDetails?.user_details?.company
  );

  const [loading, setLoading] = useState(false);
  const [errorValues, setErrorValues] = useState({});
  const [session, setSession] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (prop) => (event) => {
    setDetails({
      ...details,
      companyDetails: {
        ...details?.companyDetails,
        [prop]: event.target.value,
      },
    });
    setErrorValues({ ...errorValues, [prop]: "" });
  };

  useEffect(() => {
    const companyValue = details?.companyDetails?.companyName;
    if (
      companyValue &&
      companyValue !== null &&
      companyData.length > 0 &&
      typeof companyValue !== "object"
    ) {
      const comp_name = companyData.filter(
        (res) => res.company_name === companyValue
      );
      const nums = location.filter(
        (res) => res?.city_id === comp_name[0]?.company_city
      );

      if (nums && comp_name) {
        setDetails({
          ...details,
          companyDetails: {
            ...details?.companyDetails,
            companyName: comp_name[0],
            location: nums[0],
          },
        });
      }
    }
  }, [details, companyData]);

  const helper = (data) => {
    if (data?.data?.str_rd_code === "mob_no_false_nav_enter_otp") {
      localStorage.setItem("loggedIn", "true");
      setCompanyInfo(true);
      setSession(true);
      //setStep(12);
    }
    if (data?.data?.code === 2 && data?.data?.rd_code === 60) {
      //Logged in true and render to direct post job page from company details

      setStep(2);
    } else if (data?.data?.code === 2 && data?.data?.rd_code == 50) {
      //if your company already exist in our db then page will move to approval request page
      window.localStorage.setItem("adminEmail", data.data.data.email);
      setStep(4);
    }
  };
  const locationvalue = useLocation();

  const { email_history } = locationvalue.state || {};
  const { phone_history } = locationvalue.state || {};

  const validateSecondForm = () => {
    let isError = false;
    let companyNameError = "";
    let websiteError = "";
    let locationError = "";
    let companySizeError = "";

    if (!details?.companyDetails?.companyName) {
      companyNameError = "Please enter company name";
      isError = true;
    }

    if (
      !details?.companyDetails?.website ||
      !is_url(details?.companyDetails?.website)
    ) {
      websiteError = "Please Enter Web url correctly (include http or https)";
      isError = true;
    }
    if (!details?.companyDetails?.location) {
      locationError = "Please select company location";
      isError = true;
    }

    if (!details?.companyDetails?.companySize) {
      companySizeError = "Please select company size";
      isError = true;
    }

    setErrorValues({
      companyName: companyNameError,
      website: websiteError,
      location: locationError,
      companySize: companySizeError,
    });

    return isError ? false : true;
  };

  const submitSecondForm = () => {
    if (validateSecondForm()) {
      const company_name_ =
        Object.keys(details?.companyDetails?.companyName).length === 2
          ? details?.companyDetails?.companyName?.inputValue
          : details?.companyDetails?.companyName?.company_name;

      const body = {
        company_id: details?.companyDetails?.companyName?.id ?? null,
        company_name: company_name_,
        company_size: details?.companyDetails?.companySize?.title,
        company_website: details?.companyDetails?.website,
        // city: details?.companyDetails?.location?.city_id,
        state: details?.companyDetails?.location?.state_id,
        designation: details?.companyDetails?.designation,
        email: details?.basic?.email ? details.basic?.email : email_history,
      };

      fetchApi({
        url: `${PRAVESH_API}company_saas/new/login/choose/company/`,
        method: "post",
        alert: alert,
        body: JSON.stringify(body),
        setLoading: setLoading,
        callBackFn: helper,
        loginFlow: true,
        alert: alert,
      });
    }
  };

  const customHelp = {
    helper: {
      fontFamily: "gilRoy-semibold",
      fontWeight: "500",
      color: "#909090",
      fontSize: "10px",
      marginLeft: 0,
      marginBottom: "-5px",
    },
  };

  return (
    <>
      <Box
        sx={{
          boxShadow: "0px 4px 11px 0px #0000001C",
          borderRadius: "8px",
          p: 2,
          pt: 1,
          [theme.breakpoints.only("xs")]: {
            boxShadow: "none",
            p: 0.5,
          },
        }}
      >
        <Grid container spacing={3.5} style={{ width: "100%", margin: 0 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="h3"
              color="inherit"
              sx={{
                mt: 0.5,
                mb: 1.5,
                fontFamily: "gilRoy-semibold",
                fontWeight: "500",
                textAlign: "center",
                [theme.breakpoints.only("xs")]: {
                  fontSize: "18px",
                },
              }}
            >
              Provide your company information
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ display: "grid" }}>
            <Autocomplete
              options={companyData?.length > 0 ? companyData : []}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              freeSolo
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                } else if (option.company_name) {
                  return option.company_name;
                }
              }}
              style={{
                background: "#fff",
                borderRadius: "8px",
              }}
              id="free-solo-with-text-demo"
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.title
                );

                if (params.inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue: params.inputValue,
                    company_name: `Create "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              renderOption={(props, option) => {
                let { key, ...propsNew } = props;
                return (
                  <Typography
                    style={{
                      fontSize: "14px",
                      padding: "5px 20px",
                      cursor: "pointer",
                    }}
                    /*  onClick={onClick} */
                    {...propsNew}
                    /* {...props} */
                  >
                    {option.company_name}
                  </Typography>
                );
              }}
              value={details?.companyDetails?.companyName}
              onChange={(e, i) => {
                const nums = location.filter(
                  (res) => res?.city_id === i?.company_city
                );
                if (typeof i === "string") {
                  setDetails({
                    ...details,
                    companyDetails: {
                      ...details?.companyDetails,
                      companyName: i ?? null,
                      companySize: {
                        title: i?.size ?? "",
                        value: i?.size ?? "",
                      },
                      website: i?.website ?? "",
                      location: nums[0],
                    },
                  });
                } else if (i && i.inputValue) {
                  // Create a new value from the user input
                  setDetails({
                    ...details,
                    companyDetails: {
                      ...details?.companyDetails,
                      companyName: i ?? null,
                      companySize: {
                        title: i?.size ?? "",
                        value: i?.size ?? "",
                      },
                      website: i?.website ?? "",
                      location: nums[0],
                    },
                  });
                } else if (i) {
                  setDetails({
                    ...details,
                    companyDetails: {
                      ...details?.companyDetails,
                      companyName: i ?? null,
                      companySize: {
                        title: i?.size ?? "",
                        value: i?.size ?? "",
                      },
                      website: i?.website ?? "",
                      location: nums[0],
                    },
                  });
                }
                setErrorValues({ ...errorValues, companyName: "" });
              }}
              PaperComponent={CustomAutoCompletePaper}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  variant="outlined"
                  label="Company Name *"
                  size="small"
                  error={errorValues?.companyName ? true : false}
                  helperText={
                    errorValues?.companyName ? errorValues?.companyName : ""
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ display: "grid" }}>
            <Autocomplete
              options={sizeData}
              getOptionLabel={(option) => option?.title}
              style={{
                background: "#fff",
                borderRadius: "8px",
              }}
              PaperComponent={CustomAutoCompletePaper}
              value={details?.companyDetails?.companySize}
              onChange={(e, i) => {
                setDetails({
                  ...details,
                  companyDetails: {
                    ...details?.companyDetails,
                    companySize: i,
                  },
                });
                setErrorValues({ ...errorValues, companySize: "" });
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  variant="outlined"
                  label="Company Size *"
                  size="small"
                  error={errorValues?.companySize ? true : false}
                  helperText={
                    errorValues?.companySize ? errorValues?.companySize : ""
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ display: "grid" }}>
            <Autocomplete
              id="tags-outlined"
              value={details?.companyDetails?.location}
              onChange={(e, i) => {
                setDetails({
                  ...details,
                  companyDetails: { ...details?.companyDetails, location: i },
                });
                setErrorValues({ ...errorValues, location: "" });
              }}
              options={location?.length > 0 ? location : []}
              getOptionLabel={(option) => `${option.city}, ${option.state}`}
              style={{
                background: "#fff",
                borderRadius: "8px",
              }}
              PaperComponent={CustomAutoCompletePaper}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  variant="outlined"
                  label="Company Location *"
                  size="small"
                  error={errorValues?.location ? true : false}
                  helperText={
                    errorValues?.location ? errorValues?.location : ""
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ display: "grid" }}>
            {" "}
            <CustomTextField
              label="Company Website *"
              size="small"
              focused={details?.companyDetails?.website}
              onChange={handleChange("website")}
              error={errorValues?.website ? true : false}
              helperText={
                errorValues?.website
                  ? errorValues?.website
                  : "If company website does not exist ,please enter company linkedin profile URL."
              }
              value={details?.companyDetails?.website}
              FormHelperTextProps={{ style: customHelp.helper }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ display: "grid" }}>
            {" "}
            <CustomTextField
              label="Your Designation *"
              size="small"
              focused={details?.companyDetails?.designation}
              value={details?.companyDetails?.designation}
              onChange={handleChange("designation")}
            />
          </Grid>
        </Grid>

        <AppBar
          color="inherit"
          sx={{
            boxShadow: "none",
            position: "relative",
            marginTop: "10px",
          }}
        >
          <Grid container spacing={3} sx={{ width: "100%", margin: 0 }}>
            <Grid item xs={12} sx={{ display: "grid" }}>
              <Button
                color="primary"
                variant="contained"
                sx={{
                  height: "40px",
                  width: "100%",
                  boxShadow: "none",
                  borderRadius: "8px",
                  display: "flex",
                  fontWeight: "400",
                  fontSize: "15px",
                }}
                onClick={() => {
                  basicInfo && emailVerified && companyInfo && phoneVerified
                    ? setStep(2)
                    : submitSecondForm();
                }}
              >
                {loading ? (
                  <CircularProgress style={{ color: "#fff" }} size={18} />
                ) : (
                  "Next"
                )}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                startIcon={<ArrowBackIosRoundedIcon />}
                variant="text"
                color="primary"
                sx={{
                  height: "40px",
                  boxShadow: "none",
                  fontWeight: "400",
                  fontSize: "15px",
                }}
                onClick={() => {
                  setStep(0);
                }}
              >
                Back to Basic information
              </Button>
            </Grid>
          </Grid>
        </AppBar>
      </Box>
      {(session || fromLoginPhone) && companyInfo && (
        <VerificationDialog
          setStep={setStep}
          details={details}
          setDetails={setDetails}
          phone={true}
          emailVerified={emailVerified}
          setEmailVerified={setEmailVerified}
          phoneVerified={phoneVerified}
          setPhoneVerified={setPhoneVerified}
          session={session}
          fromLoginEmail={fromLoginEmail}
          setFromLoginEmail={setFromLoginEmail}
          fromLoginPhone={fromLoginPhone}
          setFromLoginPhone={setFromLoginPhone}
          phone_history={phone_history}
          email_history={email_history}
          userData={userData}
        />
      )}
    </>
  );
}

export default MergedSecondForm;

const sizeData = [
  { title: "1-10", value: "1-10" },
  { title: "11-50", value: "11-50" },
  { title: "51-100", value: "51-100" },
  { title: "101-1000", value: "101-1000" },
  { title: "1000+", value: "1000+" },
];
