import React, { useEffect } from "react";
import { Button, Box, Typography, useTheme } from "@mui/material";
// import Post from "../../../Assets/Images/svg/post-job.svg";
import { ReactComponent as Post } from "../../../Assets/Images/svg/JobPostingSvg.svg";
import { useHistory } from "react-router-dom";
import JobPostingDialog from "../../Cards/Jobs/JobPostingDialog";

function Complete({
  setActiveStep,
  jobDataAfterPost = {},
  initialState = {},
  setPostJobData = () => {},
  setShowCompleted = () => {},
  setFormCheckBox = () => {},
  initialCheckState = {},
  setExpanded = () => {},
  disablePopUp = false,
}) {
  const history = useHistory();

  const theme = useTheme();

  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pb: 3,
      }}
    >
      {!disablePopUp && (
        <JobPostingDialog
          jobId={jobDataAfterPost.id}
          jobTitle={jobDataAfterPost.name}
        />
      )}
      {/* <img src={Post} /> */}
      <Post fill={theme.palette.primary.main} height="400px" />
      <div style={{ display: "flex", padding: "10px" }}>
        {disablePopUp ? (
        <Typography>
        {jobDataAfterPost?.name ? (
          <>
            Thank you for creating <strong>{jobDataAfterPost.name}</strong> on our platform! 🎉  
            We're excited to help you find the perfect candidates.
          </>
        ) : (
          "Thank you for posting your job on our platform! 🚀"
        )}
      </Typography>
      
       
        ) : (
          <>
            <Typography
              color="primary"
              sx={{ mr: 0.5, cursor: "pointer" }}
              onClick={() => {
                window.open(`/job-detail/?id=${jobDataAfterPost?.id}`);
              }}
            >{`${jobDataAfterPost?.name}`}</Typography>{" "}
            <Typography>{`has been posted Successfully!!!`}</Typography>
          </>
        )}
      </div>
      <Button
        variant="contained"
        color="primary"
        sx={{
          boxShadow: "none",
          borderRadius: "8px",
          height: "40px",
          pl: 7,
          pr: 7,
        }}
        onClick={() => {
          setPostJobData(initialState);
          setFormCheckBox(initialCheckState);
          setExpanded("panel1");
          history.push("/post-job");
          setShowCompleted(false);
        }}
      >
        Post New Job
      </Button>
      <Button
        variant="contained"
        color="primary"
        sx={{
          boxShadow: "none",
          borderRadius: "8px",
          height: "40px",
          pl: 7,
          pr: 7,
          width: "212px",
          marginTop: "16px",
        }}
        onClick={() => {
          setPostJobData(initialState);
          setFormCheckBox(initialCheckState);
          history.push(`/track-job/?job_id=${btoa(jobDataAfterPost?.id)}`);
          setShowCompleted(false);
        }}
      >
        Job Track
      </Button>
    </Box>
  );
}

export default Complete;
