import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React, { useState } from "react";

const FilterShowMore = ({
  filterData,
  selectCheckboxData,
  tempFilterApplied,
}) => {
  const [showAll, setShowAll] = useState(false);
  return (
    <>
      <div /* className={classes.checkboxroot} */>
        <FormControl
          component="fieldset"
          //className={classes.formControl}
        >
          <FormGroup>
            {filterData?.data.slice(0, 4).map((displayData) => (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    size="small"
                    name="gilad"
                    checked={tempFilterApplied[filterData?.param_name].some(
                      (item) => item?.id === displayData?.id
                    )}
                    onChange={(e) => {
                      selectCheckboxData(
                        filterData?.param_name,
                        displayData,
                        "multiple"
                      );
                    }}
                  />
                }
                label={displayData?.name}
              />
            ))}
            {showAll &&
              filterData?.data
                .slice(4, filterData?.data?.length)
                .map((displayData) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        size="small"
                        name="gilad"
                        checked={tempFilterApplied[filterData?.param_name].some(
                          (item) => item?.id === displayData?.id
                        )}
                        onChange={(e) => {
                          selectCheckboxData(
                            filterData?.param_name,
                            displayData,
                            "multiple"
                          );
                        }}
                      />
                    }
                    label={displayData?.name}
                  />
                ))}
          </FormGroup>
        </FormControl>
      </div>
      <Button
        onClick={() => {
          setShowAll(!showAll);
        }}
        color="primary"
        variant="text"
        sx={{ fontSize: "15px" }}
      >
        {!showAll ? `Show All (${filterData?.data?.length - 4})` : "Show Less"}
      </Button>
    </>
  );
};

export default FilterShowMore;
