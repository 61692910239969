import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Container,
  InputBase,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Chip,
  alpha,
  useTheme,
  styled,
  CircularProgress,
  CardActions,
  Card,
  Collapse,
  CardContent,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import {
  CustomActionTextField,
  CustomTextField,
} from "../../Common/InputText/InputField";
import CollegeDetails from "../../Cards/Post/CollegeDetails";
import Grid from "@mui/material/Unstable_Grid2";
import { useAlert } from "react-alert";
import PreviewJobDetail from "./PreviewJobDetail";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Chiplist = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function PreviewJob({
  postJobData,
  setPostJobData,
  handleClickOpen,
  open,

  activeStep,
  steps,
  showPreview,
  setShowPreview,
  data,
  flagCheck,
  createJob,
  validateSelectionProcedure,
  setDraftJobLoader,
  postJobLoader,
  draftJobLoader,
  postJobLoginFlow=false,
}) {
  const theme = useTheme();
  const alert = useAlert();
  const [expanded, setExpanded] = React.useState(false);
  const [degree, setDegree] = React.useState([]);
  const [specialization, setSpecialization] = useState([]);
  const [degreeCourseFilter, setDegreeCourseFilter] = useState({
    eduLevel: [],
  });
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  /* 
  const [secondexpanded, setSecondExpanded] = React.useState(false);

  const handleExpandSecondClick = () => {
    setSecondExpanded(!secondexpanded);
  }; */
  const handleClose = () => {
    setShowPreview(false);
  };

  function checkForDuplicates(array) {
    let valuesAlreadySeen = [];

    for (let i = 0; i < array.length; i++) {
      let value = array[i];
      if (valuesAlreadySeen?.indexOf(value) !== -1) {
        return true;
      }
      valuesAlreadySeen?.push(value);
    }
    return false;
  }

  return (
    <div>
      {/* <Button
        disabled={activeStep < steps.length - 1}
        variant="outlined"
        color="primary"
        sx={{
          boxShadow: "none",
          height: "40px",
          borderRadius: "30px",
        }}
        // variant="contained"
        // color="primary"
        // sx={{
        //   height: "34px",
        //   fontSize: "14px",
        //   borderRadius: "30px",
        //   boxShadow: "none",
        //   paddingLeft: "30px",
        //   paddingRight: "30px",
        // }}
        onClick={handleClickOpen}
      >
        {`Preview Job`}
      </Button> */}
      <Dialog
        fullScreen
        open={showPreview}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", boxShadow: "none" }}>
          <Container maxWidth="lg">
            <Toolbar
              sx={{
                minHeight: "56px !important",
                pl: "0px !important",
                pr: "0px !important",
              }}
            >
              <Typography sx={{ ml: 1, flex: 1 }} variant="h3" component="div">
                Preview
              </Typography>

              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </Container>
        </AppBar>
        <Container maxWidth="lg">
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid md={10}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid md={12}>
                      <PreviewJobDetail
                        data={data}
                        flagCheck={flagCheck}
                        createJob={createJob}
                        validateSelectionProcedure={validateSelectionProcedure}
                        setDraftJobLoader={setDraftJobLoader}
                        postJobLoader={postJobLoader}
                        draftJobLoader={draftJobLoader}
                        handleClose={handleClose}
                        postJobLoginFlow={postJobLoginFlow}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Dialog>
    </div>
  );

  /* (
    <Card
      sx={{
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px",
        mb: 2,
        maxHeight: 400,
        overflowY: "scroll",
      }}
    >
      <CardActions disableSpacing>
        <Box>
          <Grid container>
            <h3>Degree & Course</h3>
          </Grid>
        </Box>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {postJobData?.Colleges?.hiring_type == "OFF CAMPUS" &&
          postJobData?.OffCampus?.allData && (
            <CardContent sx={{ width: "100%" }}>
              <Box sx={{ pl: 0.5 }}>
                <Typography variant="body2">Courses:</Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="gilad"
                        onChange={(e) => {
                          //   var len = Object.keys(allData);
                          var len = Object.keys(
                            postJobData?.OffCampus?.allData
                          );

                          len?.forEach((item) => {
                            postJobData.OffCampus.allData[item][0].isValue =
                              e.target.checked;

                            postJobData?.OffCampus?.allData[item]?.forEach(
                              (n) => {
                                n.isActive = e.target.checked;

                                setPostJobData({
                                  ...postJobData,
                                  OffCampus: {
                                    ...postJobData?.OffCampus,
                                    allData: {
                                      ...postJobData?.OffCampus?.allData,
                                    },
                                  },
                                });

                                if (n?.isActive === true) {
                                  var temp2 = [];

                                  postJobData?.OffCampus?.degree?.map(
                                    (a, o) => {
                                      temp2.push(a.id);
                                    }
                                  );

                                  if (temp2.includes(n.degree.id) === false) {
                                    postJobData?.OffCampus?.degree?.push(
                                      n?.degree
                                    );
                                  }
                                } else
                                  while (
                                    postJobData?.OffCampus?.degree?.length > 0
                                  ) {
                                    postJobData?.OffCampus?.degree?.pop();
                                  }

                                if (n.isActive === true)
                                  postJobData?.OffCampus?.specialization.push(
                                    n?.specialization
                                  );
                                else
                                  while (
                                    postJobData?.OffCampus?.specialization
                                      ?.length > 0
                                  ) {
                                    postJobData?.OffCampus?.specialization?.pop();
                                  }

                                setDegree(postJobData?.OffCampus?.degree);
                                setSpecialization(
                                  postJobData?.OffCampus?.specialization
                                );
                              }
                            );
                          });
                        }}
                      />
                    }
                    label={
                      <Typography variant="body2" sx={{ color: "#6C757D" }}>
                        Select all courses
                      </Typography>
                    }
                  />
                </FormGroup>
                {Object.keys(postJobData?.OffCampus?.allData)?.map(
                  (item, index) => (
                    <Card
                      sx={{
                        border: "1px solid #B0B6BA",
                        borderRadius: "30px",
                        mb: 2,
                        mt: 1,
                        boxShadow: "none",
                      }}
                    >
                      <CardActions disableSpacing sx={{ height: "50px" }}>
                        <Box sx={{ pl: 2 }}>
                          {" "}
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                name="gilad"
                                onChange={(e) => {
                                  handleSelectAllSpecialization1(
                                    e,
                                    item,
                                    index
                                  );
                                  postJobData.OffCampus.allData[
                                    item
                                  ][0].isValue = e.target.checked;
                                }}
                                checked={
                                  postJobData.OffCampus.allData[item][0]
                                    .isValue === true
                                    ? true
                                    : false
                                }
                              />
                            }
                            label={
                              <Typography
                                variant="body2"
                                sx={{ color: "#6C757D" }}
                              >
                                {item}
                              </Typography>
                            }
                          />
                        </Box>
                        <ExpandMore
                          expand={secondexpanded}
                          onClick={handleExpandSecondClick}
                          aria-expanded={secondexpanded}
                          aria-label="show more"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      </CardActions>
                      <Collapse
                        in={secondexpanded}
                        timeout="auto"
                        unmountOnExit
                      >
                        <CardContent sx={{ pl: 7, pt: 0 }}>
                          <FormGroup>
                            {postJobData?.OffCampus?.allData[item].map(
                              (i, index) =>
                                i?.specialization ? (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        size="small"
                                        name="gilad"
                                        checked={i?.isActive}
                                        onChange={(e) => {
                                          handleSelectSingleSpecialization1(
                                            e,
                                            item,
                                            index
                                          );
                                        }}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant="body2"
                                        sx={{ color: "#6C757D" }}
                                      >
                                        {i?.specialization?.name}
                                      </Typography>
                                    }
                                  />
                                ) : null
                            )}
                          </FormGroup>
                        </CardContent>
                      </Collapse>
                    </Card>
                  )
                )}
              </Box>
            </CardContent>
          )}
      </Collapse>
    </Card>
  ); */
}

const CardComponent = ({
  handleSelectAllSpecialization1,
  handleSelectSingleSpecialization1,
  item,
  postJobData,
  index,
}) => {
  const [secondexpanded, setSecondExpanded] = React.useState(false);

  const handleExpandSecondClick = () => {
    setSecondExpanded(!secondexpanded);
  };

  return (
    <>
      <Card
        sx={{
          border: "1px solid #B0B6BA",
          borderRadius: "8px",
          mb: 2,
          mt: 1,
          boxShadow: "none",
        }}
      >
        <CardActions disableSpacing sx={{ height: "50px" }}>
          <Box sx={{ pl: 2 }}>
            {" "}
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  name="gilad"
                  onChange={(e) => {
                    handleSelectAllSpecialization1(e, item, index);
                    postJobData.OffCampus.allData[item][0].isValue =
                      e.target.checked;
                  }}
                  checked={
                    postJobData.OffCampus.allData[item][0].isValue === true
                      ? true
                      : false
                  }
                />
              }
              label={
                <Typography variant="body2" sx={{ color: "#6C757D" }}>
                  {item}
                </Typography>
              }
            />
          </Box>
          <ExpandMore
            expand={secondexpanded}
            onClick={handleExpandSecondClick}
            aria-expanded={secondexpanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={secondexpanded} timeout="auto" unmountOnExit>
          <CardContent sx={{ pl: 7, pt: 0 }}>
            <FormGroup>
              {postJobData?.OffCampus?.allData[item].map((i, index) =>
                i?.specialization ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="gilad"
                        checked={i?.isActive}
                        onChange={(e) => {
                          handleSelectSingleSpecialization1(e, item, index);
                        }}
                      />
                    }
                    label={
                      <Typography variant="body2" sx={{ color: "#6C757D" }}>
                        {i?.specialization?.name}
                      </Typography>
                    }
                  />
                ) : null
              )}
            </FormGroup>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};
